import { Component, OnInit, resolveForwardRef, ViewChild } from '@angular/core';
import { NgForm, Validators } from '@angular/forms';
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService, NgxSpinnerComponent } from 'ngx-spinner';
import { Router, ActivatedRoute } from "@angular/router";
import { CookieService } from 'ngx-cookie-service';
import { ManageAddressService } from './manage-address.service';
import { ManageAddressModel } from './manage-address.model';
import { CheckoutService } from '../checkout/checkout.service';
import { Loader, LoaderOptions } from 'google-maps';
declare var $: any;

@Component({
  selector: 'app-manage-address',
  templateUrl: './manage-address.component.html',
  styleUrls: ['./manage-address.component.css']
})
export class ManageAddressComponent implements OnInit {
  @ViewChild('ManageAddressForm') manageAddressForm: NgForm;

  cookieValue: any;
  addressList: any;
  data: ManageAddressModel;
  userAddressId: any;
  isUpdate: any;

  locationData: any;
  isOrderAvailable: any;
  orderTime: any;
  geodata: any;
  lati: any;
  lngi: any;

  @ViewChild('map') mapElement: any;
  map: google.maps.Map;

  options: LoaderOptions = {/* todo */ };
  loader = new Loader(this.cookieService.get("google_map_key"), this.options);

  constructor(private toastr: ToastrService, private service: ManageAddressService, private router: Router, private cookieService: CookieService, private spinner: NgxSpinnerService, private http: HttpClient,
    private checkoutService: CheckoutService, private rout: ActivatedRoute) { }

  ngOnInit() {
    this.data = new ManageAddressModel();
    this.getUserAddress();
    this.isUpdate = 0;

    $("#lblOption0").addClass("focus active");
    $("#option0").attr('checked', 'checked');

    if (this.cookieService.get("location")) {
      this.getLocationDetail(this.cookieService.get("location"));
    }
    this.getLocation()
  }

  getLocation() {
    navigator.geolocation.getCurrentPosition(resp => {
      var lng = resp.coords.longitude
      var lat = resp.coords.latitude;
      this.locateAddress(lng, lat)
    })
  }

  locateAddress(lng, lat) {
    if (this.data.address.length == 0 && this.data.city.length == 0 && this.data.pincode.length == 0) {
      this.loader.load().then(function (google) {
        var myLatLng = new google.maps.LatLng(lat, lng),
          myOptions = {
            zoom: 18,
            center: myLatLng,
          },
          map1 = new google.maps.Map(document.getElementById('map'), myOptions),
          marker = new google.maps.Marker({ position: myLatLng, map: map1, draggable: true, });

        map1.addListener("marker_change", () => {
          window.setTimeout(() => {
            map1.panTo(marker.getPosition());
            this.lati = marker.getPosition().lat();
            this.lngi = marker.getPosition().lng();
          }, 3000);
        });
        marker.addListener("dragend", () => {
          map1.setCenter(marker.getPosition());
          this.lati = marker.getPosition().lat();
          this.lngi = marker.getPosition().lng();
        });
        marker.setMap(map1);
      });
    }
    else {
      this.setWithAddress();
    }
  }

  setWithAddress() {
    var address = this.data.address + ", " + this.data.city + ", " + this.data.pincode
    var longitude, latitude;
    this.spinner.show();
    this.loader.load().then(function (google) {
      new google.maps.Geocoder().geocode({ 'address': address }, function (results, status) {
        if (status == 'OK') {
          var myLatLng = new google.maps.LatLng(results[0].geometry.location.lat(), results[0].geometry.location.lng()),
            myOptions = {
              zoom: 18,
              center: myLatLng,
            },
            map1 = new google.maps.Map(document.getElementById('map'), myOptions),
            marker = new google.maps.Marker({ position: myLatLng, map: map1, draggable: false, });

          marker.setMap(map1);
          longitude = marker.getPosition().lng().toString();
          latitude = marker.getPosition().lat().toString();

          if (longitude)
            $(".searched_longi").val(marker.getPosition().lng().toString());
          if (latitude)
            $(".searched_lati").val(marker.getPosition().lat().toString());

        } else {
          $(".searched_longi").val("");
          $(".searched_lati").val("");
          //this.toastr.error('No address found for your address because of - ' + status);
        }
      })
    });

    // window.setTimeout(() => {
    //   this.data.longitude = longitude;
    //   this.data.latitude = latitude;
       this.spinner.hide()
    // }, 700)
  }

  setlanlog(longitude, latitude) {
    this.data.longitude = longitude;
    this.data.latitude = latitude;
  }

  showAddAddress() {
    document.getElementById("divListAddress").style.display = "none";
    document.getElementById("divAddAddress").style.display = "block";
    document.getElementById("divBackToList").style.display = "block";
    this.isUpdate = 0;

    this.removeRadioButtonAttributes();

    $("#lblOption0").addClass("focus active");
    $("#option0").attr('checked', 'checked');
  }

  getUserAddress() {
    var obj = {
      "location_id": this.cookieService.get("location")
    }

    this.spinner.show();
    this.service.getUserAddress(obj).subscribe(response => {
      if (response.status == "ok") {
        if (response.payload != "") {
          this.addressList = response.payload;

          if (this.addressList.length > 0) {
            document.getElementById("divListAddress").style.display = "block";
            document.getElementById("divAddAddress").style.display = "none";
            document.getElementById("divBackToList").style.display = "block";
          } else {
            document.getElementById("divListAddress").style.display = "none";
            document.getElementById("divAddAddress").style.display = "block";
            document.getElementById("divBackToList").style.display = "none";
            this.isUpdate = 0;
          }
        } else {
          document.getElementById("divListAddress").style.display = "none";
          document.getElementById("divAddAddress").style.display = "block";
          document.getElementById("divBackToList").style.display = "none";
          this.isUpdate = 0;
        }
      } else {
        this.toastr.error(response.message);
      }
      this.spinner.hide();
    }, (err: HttpErrorResponse) => {
      this.toastr.error(err.toString());
      this.spinner.hide();
    });
  }

  saveAddress(manageAddress: ManageAddressModel) {
    manageAddress.firstname = (this.cookieService.get("firstName") ? this.cookieService.get("firstName") : "");
    manageAddress.lastname = (this.cookieService.get("lastName") ? this.cookieService.get("lastName") : "");
    manageAddress.phoneno = (this.cookieService.get("phoneNo") ? this.cookieService.get("phoneNo") : "");
    manageAddress.country = "";
    manageAddress.state = "";
    
    this.data.latitude =  $(".searched_lati").val();
    this.data.longitude = $(".searched_longi").val();
    
    manageAddress.latitude = this.data.latitude;
    manageAddress.longitude = this.data.longitude;
    manageAddress.location_id = this.cookieService.get("location");

    manageAddress.address_type = "0";
    var rbAddressType = document.forms[0].elements['address_type'];
    for (var i = 0; i < rbAddressType.length; i++) {
      if (rbAddressType[i].checked) {
        manageAddress.address_type = rbAddressType[i].value;
      }
    }

    if (this.manageAddressForm.valid) {
      if ((this.data.longitude == undefined || this.data.longitude.trim().length == 0) ||
        (this.data.latitude == undefined || this.data.latitude.trim().length == 0)) {
        this.toastr.error("Enter valid address");
        return
      }

      this.spinner.show();
      if (this.isUpdate == 0) {
        this.service.addUserAddress(manageAddress).subscribe(response => {
          if (response.status == "ok") {
            this.toastr.success(response.message);
            this.resetAddressForm();
          } else {
            this.toastr.error(response.message);
          }
          this.spinner.hide();
        }, (err: HttpErrorResponse) => {
          this.toastr.error(err.toString());
          this.spinner.hide();
        });
      } else {
        this.service.updateUserAddress(manageAddress).subscribe(response => {
          if (response.status == "ok") {
            this.toastr.success(response.message);
            this.resetAddressForm();
          } else {
            this.toastr.error(response.message);
          }
          this.spinner.hide();
        }, (err: HttpErrorResponse) => {
          this.toastr.error(err.toString());
          this.spinner.hide();
        });
      }
    }
  }

  resetAddressForm() {
    if (this.rout.snapshot.paramMap.has('id')) {
      $('#DeleteModal').modal('hide');
      let encryptedPlaceOrder = atob(this.rout.snapshot.paramMap.get('id'));
      if (encryptedPlaceOrder == "placeordersubscription")
        this.router.navigate(['/placeordersubscription']);
      else
        this.router.navigate(['/placeorder']);
    } else {
      this.getUserAddress();
      this.manageAddressForm.resetForm();

      document.getElementById("divListAddress").style.display = "block";
      document.getElementById("divAddAddress").style.display = "none";
      document.getElementById("divBackToList").style.display = "none";

      $('#DeleteModal').modal('hide');
    }
  }

  openDeleteAddress(addressId) {
    $('#DeleteModal').modal('show');
    this.userAddressId = addressId;
  }

  deleteAddress() {
    var obj = {
      "useraddress_id": this.userAddressId,
      "location_id": this.cookieService.get("location")
    }

    this.spinner.show();
    this.service.deleteUserAddress(obj).subscribe(response => {
      if (response.status == "ok") {
        this.toastr.success(response.message);

        this.resetAddressForm();

        this.removeRadioButtonAttributes();
        $("#lblOption0").addClass("focus active");
        $("#option0").attr('checked', 'checked');
      } else {
        this.toastr.error(response.message);
      }
      this.spinner.hide();
    }, (err: HttpErrorResponse) => {
      this.toastr.error(err.toString());
      this.spinner.hide();
    });
  }

  editUserAddress(addressId) {
    this.showAddAddress();
    this.removeRadioButtonAttributes();

    this.isUpdate = 1;

    var obj = {
      "useraddress_id": addressId,
      "location_id": this.cookieService.get("location")
    }

    this.spinner.show();
    this.service.editUserAddress(obj).subscribe(response => {
      if (response.status == "ok") {
        this.data = response.payload;
        $("#lblOption" + this.data.address_type).addClass("focus active");
        $("#option" + this.data.address_type).attr('checked', 'checked');
        this.setWithAddress();
      } else {
        this.toastr.error(response.message);
      }
      this.spinner.hide();
    }, (err: HttpErrorResponse) => {
      this.toastr.error(err.toString());
      this.spinner.hide();
    });
  }

  removeRadioButtonAttributes() {
    var rbAddressType = document.forms[0].elements['address_type'];
    for (var i = 0; i < rbAddressType.length; i++) {
      $("#lblOption" + i).removeClass("focus active");
      $("#option" + i).removeAttr('checked');
    }
  }

  getLocationDetail(locationId) {
    var obj = {
      "location_id": locationId
    };

    this.spinner.show();
    this.checkoutService.getLocationDetail(obj).subscribe(response => {
      if (response.status == "ok") {
        this.locationData = response.payload;
        this.isOrderAvailable = this.locationData['is_ordering'];
        this.orderTime = "between " + this.locationData['restaurant_opening_time'] + " to " + this.locationData['restaurant_closing_time'];
      } else {
        this.toastr.error(response.message);
      }
      this.spinner.hide();
    }, (err: HttpErrorResponse) => {
      this.spinner.hide();
    });
  }
}

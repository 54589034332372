import { Component, OnInit, Renderer2 } from '@angular/core';
import { NgForm, Validators } from '@angular/forms';
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Router, ActivatedRoute } from "@angular/router";
import { CookieService } from 'ngx-cookie-service';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService, NgxSpinnerComponent } from 'ngx-spinner';
import { userSubscriptionsService } from './usersubscriptions.service';
import { environment } from 'src/environments/environment';
import { CheckoutService } from '../checkout/checkout.service';
import { userSubscriptionOrdersService } from '../usersubscriptionorders/usersubscriptionorders.service';

declare var $: any;

@Component({
  selector: 'app-usersubscriptions',
  templateUrl: './usersubscriptions.component.html',
  styleUrls: ['./usersubscriptions.component.css']
})
export class UsersubscriptionsComponent implements OnInit {

  currencySymbol: any;
  orderList: any;
  orderDetail: any;
  orderItemList: any;
  orderItemTaxes: any;
  orderReview: any;
  totalOrders: any;

  subscriptionOrderList: Array<any> = [];
  locationData: any;
  isOrderAvailable: any;
  orderTime: string;
  tiffinID: any;
  lblItemName: any;
  itemDetails: Array<any> = [];
  itemDetailPrice: any;
  itemDetailName: any;
  itemDetailDescription: any;
  itemDetailImage: any;
  itemUnits: any;
  lblItemRate: any;
  itemDetailTypeImage: any;
  lblTotalAmount: any;
  selectedIndex: any;
  cookieValue: string;
  weeklyItemDetails: any;
  weekname: any;
  Weekly_item_detail: any;

  constructor(private toastr: ToastrService, private router: Router, private spinner: NgxSpinnerService, private service: userSubscriptionsService, private cookieService: CookieService,
    private checkoutService: CheckoutService, private renderer: Renderer2, private OrdersService: userSubscriptionOrdersService) { }

  ngOnInit(): void {
    this.currencySymbol = environment.currencySymbol;

    if (this.cookieService.get("user") == undefined || this.cookieService.get("user") == "") {
      this.router.navigate(['/home']);
      return;
    }
    if (this.cookieService.get("location")) {
      this.getLocationDetail(this.cookieService.get("location"));
    }
    this.getUserSubscriptionList(this.cookieService.get("location"));

  }

  getUserSubscriptionList(locationId) {
    var obj = {
      "location_id": locationId
    };

    this.spinner.show();
    this.service.getUserSubscriptionList(obj).subscribe(response => {
      if (response.status == "ok") {
        this.orderList = response.payload;
        var tiffinType = "", weeks = "";
        for (var i = 0; i < this.orderList.length; i++) {
          tiffinType = "";
          weeks = "";
          if (this.orderList[i].types != undefined && this.orderList[i].types.length > 0) {
            for (var j = 0; j < this.orderList[i].types.length; j++) {
              if (tiffinType != "")
                tiffinType += ", ";
              tiffinType += this.orderList[i].types[j].display_tiffin_type;
            }
          }
          if (this.orderList[i].weeks != undefined && this.orderList[i].weeks.length > 0) {
            for (var k = 0; k < this.orderList[i].weeks.length; k++) {
              if (weeks != "")
                weeks += ", ";
              weeks += this.orderList[i].weeks[k].display_week;
            }
          }
          this.orderList[i].tiffinType = tiffinType;
          this.orderList[i].tiffinDays = weeks;
        }
        this.totalOrders = this.orderList.length;
        if (this.totalOrders > 0) {
          $('#divNoOrders').hide();
        } else
          $('#divNoOrders').show();
      } else {
        $('#divNoOrders').show();
        this.toastr.error(response.message);
      }
      this.spinner.hide();
    }, (err: HttpErrorResponse) => {
        this.toastr.error(err.toString());
        this.spinner.hide();
    });
  }

  getTiffinSubscriptionOrderList(tiffinID) {
    var obj = {
      "location_id": this.cookieService.get("location"),
      "tiffin_user_id": tiffinID,
    };
    this.tiffinID = tiffinID;
    this.spinner.show();
    this.service.getTiffinSubscriptionOrderList(obj).subscribe(response => {
      this.spinner.hide();
      if (response.status == "ok") {
        this.subscriptionOrderList = response.payload == "" ? [] : response.payload;
        $("#OrderList").modal("show");
      } else {
        this.toastr.error(response.message);
      }
    }, (err: HttpErrorResponse) => {
        this.toastr.error(err.toString());
        this.spinner.hide();
    });
  }
  getTiffinPackageList(tiffinID) {
    var obj = {
      "location_id": this.cookieService.get("location"),
      "user_subscription_hash": tiffinID,
    };
    this.tiffinID = tiffinID;
    this.spinner.show();
    this.service.getUserSubscriptionList(obj).subscribe(response => {
      this.spinner.hide();
      if (response.status == "ok") {
      // console.log("response",response);
      var payload=response.payload[0];
      var tiffinType = "";
          if (payload.types != undefined && payload.types.length > 0) {
            for (var j = 0; j < payload.types.length; j++) {
              if (tiffinType != "")
                tiffinType += ", ";
              tiffinType += payload.types[j].display_tiffin_type;
            }
          }
          payload.tiffinType = tiffinType;
      
    this.lblItemName = payload.tiffin_package_name;
    // this.itemDetailIsJain = itemDetail.is_jain;
    $('#ItemAddToCart').modal('show');

    this.itemDetails = payload;

    this.itemDetailPrice = this.itemDetails['tiffin_price'];
    this.itemDetailName = this.itemDetails['tiffin_package_name'];
    this.itemDetailDescription = this.itemDetails['tiffin_package_description'];
    this.itemDetailImage = this.itemDetails['tiffin_package_image'];

    this.itemUnits = payload.units;
    // this.itemTypes = itemDetail.types;
    this.lblItemName = payload.tiffin_package_name;
    this.lblItemRate = payload.tiffin_price;
    this.itemDetailTypeImage = this.itemDetailImage;
    this.lblTotalAmount = this.itemDetailPrice;

    // this.selectedIndex = i;
      } else {
        this.toastr.error(response.message);
      }
    }, (err: HttpErrorResponse) => {
        this.toastr.error(err.toString());
        this.spinner.hide();
    });
  } 
  getItemDetails(itemDetail, i) {
    this.cookieValue = this.cookieService.get("user");
    if (this.cookieValue == undefined || this.cookieValue == "") {
      $("#LoginModal").modal("show");
      return
    }
    this.spinner.show();

    this.lblItemName = itemDetail.name;
    // this.itemDetailIsJain = itemDetail.is_jain;
    $('#ItemAddToCart').modal('show');

    this.itemDetails = itemDetail;

    this.itemDetailPrice = this.itemDetails['price'];
    this.itemDetailName = this.itemDetails['name'];
    this.itemDetailDescription = this.itemDetails['description'];
    this.itemDetailImage = this.itemDetails['tiffinpackageimageurl'];

    this.itemUnits = itemDetail.units;
    // this.itemTypes = itemDetail.types;
    this.lblItemName = itemDetail.name;
    this.lblItemRate = itemDetail.price;
    this.itemDetailTypeImage = this.itemDetailImage;
    this.lblTotalAmount = this.itemDetailPrice;

    this.selectedIndex = i;
    this.spinner.hide();
  }

  viewWeeklyItemDetail(itemDetails) {
    $('#weeklyItemDetail').modal('show');
    $('#ItemAddToCart').modal('hide');
    this.weeklyItemDetails = itemDetails;
    this.weekname = this.weeklyItemDetails["weekname"];
    this.Weekly_item_detail = this.weeklyItemDetails["itemdata"];
  }
  closeMainModal() {
    $('#OrderList').modal("hide");
    $('#ItemAddToCart').modal("hide");
    $('#reScheduleOrderModal').modal("hide");
  }
  closeAllModal() {
    $('#OrderList').modal("show");
    $('#ItemAddToCart').modal("hide");
    $('#reScheduleOrderModal').modal("hide");
    $('#reScheduleDate').val("")
  }

  reScheduleOrder(orderDetail) {
    this.orderDetail = orderDetail;
    $('#OrderList').modal("hide");
    $('#ItemAddToCart').modal("hide");
    $('#reScheduleOrderModal').modal("show");

    $('#reScheduleDate').attr('min', orderDetail["tiffin_grace_period_start_date"]);
    $('#reScheduleDate').attr('max', orderDetail["tiffin_grace_period_end_date"]);
  }

  reScheduleOrderSubmit() {
    if ($('#reScheduleDate').val() != undefined && $('#reScheduleDate').val() != "" && $('#reScheduleDate').val().length > 0) {
      if ($('#reScheduleDate').val() < this.orderDetail["tiffin_grace_period_start_date"]) {
        var startdate = this.orderDetail["tiffin_grace_period_start_date"];
        var splitdate = startdate.toString().split("-");
        this.toastr.error("Date should not be less then " + splitdate[2] + "/" + splitdate[1] + "/" + splitdate["0"])
        return;
      } else if ($('#reScheduleDate').val() > this.orderDetail["tiffin_grace_period_end_date"]) {
        var startdate = this.orderDetail["tiffin_grace_period_end_date"];
        var splitdate = startdate.toString().split("-");
        this.toastr.error("Date should not be greater then " + splitdate[2] + "/" + splitdate[1] + "/" + splitdate["0"])
        return;
      }
    } else {
      this.toastr.error("Select Reschedule  Date");
      return;
    }
    var obj = {
      "order_id": this.orderDetail["order_id"],
      "order_delivery_date": $('#reScheduleDate').val(),
      "location_id": this.cookieService.get("location")
    };

    this.spinner.show();
    this.OrdersService.reScheduleOrder(obj).subscribe(response => {
      if (response.status == "ok") {
        this.toastr.success(response.message);
        $('#OrderList').modal("show");
        $('#reScheduleOrderModal').modal("hide");
        $('#ItemAddToCart').modal("hide");
        this.getTiffinSubscriptionOrderList(this.tiffinID);
      } else {
        this.toastr.error(response.message);
      }
      this.spinner.hide();
    }, (err: HttpErrorResponse) => {
        this.toastr.error(err.toString());
        this.spinner.hide();
    });
  }

  getLocationDetail(locationId) {
    var obj = {
      "location_id": locationId
    };

    this.spinner.show();
    this.checkoutService.getLocationDetail(obj).subscribe(response => {
      if (response.status == "ok") {
        this.locationData = response.payload;
        this.isOrderAvailable = this.locationData['is_ordering'];
        this.orderTime = "between " + this.locationData['restaurant_opening_time'] + " to " + this.locationData['restaurant_closing_time'];
      } else {
        this.toastr.error(response.message);
      }
      this.spinner.hide();
    }, (err: HttpErrorResponse) => {
      this.spinner.hide();
    });
  }
  weeklyItemDetailClose() {
    $("#ItemAddToCart").modal('show');
    $('#weeklyItemDetail').modal('hide');
  }

  resetAddToCart() {
    this.spinner.show();
    // this.ModifierForm.resetForm();
    // this.ngOnInit();
    $("#ItemAddToCart").modal('hide');
    $('#weeklyItemDetail').modal('hide');
    // $('#startDate').val(this.tomorrow);

    this.lblTotalAmount = 0;
    this.spinner.hide();
  }
}

import { Component, OnInit, ViewChild, Renderer2, ElementRef } from '@angular/core';
import { NgForm, Validators } from '@angular/forms';
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Router, ActivatedRoute } from "@angular/router";
import { environment } from 'src/environments/environment';
import { NgxSpinnerService, NgxSpinnerComponent } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { CookieService } from 'ngx-cookie-service';
import { MenuCategoryService } from '../menu-category/menu-category.service';
import { HomeService } from '../home/home.service';
import { SharedService } from '../_services/sharedServices.service';
import { CheckoutService } from '../checkout/checkout.service';
import { SubscriptionService } from '../manage-subscriptions/manage-subscriptions.service';
import { AppComponent } from '../app.component';
import { TimeInterval } from 'rxjs/internal/operators/timeInterval';
import { Subscription } from 'rxjs';

declare var $: any;
declare function mymethod(): any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  @ViewChild('divMenuSlider') divMenuSlider: ElementRef;
  @ViewChild('divFormRow') divFormRow: ElementRef;
  @ViewChild('ModifierForm') ModifierForm: NgForm;
  @ViewChild('divJainDetail') divJainDetail: ElementRef;
  @ViewChild('divJainAddToCart') divJainAddToCart: ElementRef;
  @ViewChild('divReviews') divReviews: ElementRef;
  SubscriptionList: any;
  subscriptionshow: any;
  selectedIndex: any;

  constructor(private spinner: NgxSpinnerService, private cookieService: CookieService, private menuCategoryService: MenuCategoryService, private toastr: ToastrService, private router: Router,
    private service: HomeService, private renderer: Renderer2, private sharedService: SharedService, private checkoutService: CheckoutService,private subscriptionService: SubscriptionService, private appComponent: AppComponent) { }

  currencySymbol: any;
  cookieValue: any;
  homePagetTopCategories: Array<any> = [];
  categories: Array<any> = [];
  menuCategoryList: Array<any> = [];
  offerList: any;
  lblCategoryName: string;
  lblItemName: string;
  lblItemRating: any;
  lblTotalAmount: any = 0;

  itemTotal: any = 0;
  reviewList: Array<any> = [];
  itemModifiers: Array<any> = [];
  itemModifierTotal: any = 0;
  itemChildModifierTotal: any = 0;
  itemDetails = {};
  itemUnits: Array<any> = [];
  itemDetailTypeImage: any;
  itemRadioModifierTotal: any = 0;

  lblItemRate: any;
  lblItemRateWithTax: any;

  itemTypes: Array<any> = [];

  trendingItemList: Array<any> = [];
  newArrivalItemList: Array<any> = [];

  itemInformations: Array<any> = [];
  itemDetailPrice: any;
  itemDetailName: any;
  itemDetailDescription: any;
  itemDetailImage: any;
  itemDetailIsJain: any;

  temp_Type: any;
  temp_i: any;
  temp_j: any;

  locationData: any;
  isOrderAvailable: any;
  orderTime: any;

  itemAddCart: Array<any> = [];
  temp_categoryId: any;
  temp_categoryName: any;

  homeBannerImage: any;
  homeBannerLink: any;
  homeBannerCategoryId: any;
  homeBannerCategoryName: any;
  homeBannerItemId: any;
  homeBannerItemName: any;
  isPriceWithTax: boolean;

  ngOnInit(): void {
    this.currencySymbol = environment.currencySymbol;
    this.lblCategoryName = "";
    this.lblItemName = "";
    
    this.cookieValue = this.cookieService.get("location");
    if (this.cookieValue) {
      this.getLocationDetail(this.cookieValue);
      
    } else {
      // document.onreadystatechange = function () {
      //   if (document.readyState !== "complete") {
      //   } else {
      //     // $(".slickslider").not('.slick-initialized').slick();
      //     $('#MenuModal').modal('show');
      //   }
      // };
    }
    
  }
  getItemDetailsforsubscription(itemDetail, i) {
    this.appComponent.isSubscription=1;
    this.cookieValue = this.cookieService.get("user");
    localStorage.setItem("itemDetail", JSON.stringify(itemDetail));
    if (this.cookieValue == undefined || this.cookieValue == "") {
      $("#LoginModal").modal("show");
      return
    }
    
    this.router.navigate(['/managesubscriptionsdetail']).then(() => {
      window.location.reload();
    });
  
  }
  getLocationDetail(locationId) {
    var obj = {
      "location_id": locationId
    };

    // this.spinner.show();
    this.checkoutService.getLocationDetail(obj).subscribe(response => {
      if (response.status == "ok") {
        this.locationData = response.payload;
        this.isOrderAvailable = this.locationData['is_ordering'];
        this.orderTime = "between " + this.locationData['restaurant_opening_time'] + " to " + this.locationData['restaurant_closing_time'];
        this.cookieService.set("delivery_charge_applyon", response.payload.delivery_charge_applyon);
        this.cookieService.set("internet_charge_applyon", response.payload.internet_charge_applyon);
        this.cookieService.set("packing_charge_applyon", response.payload.packing_charge_applyon);
        this.cookieService.set("service_charge_applyon", response.payload.service_charge_applyon);
        environment.currencySymbol = response.payload.currency.sign;
        this.cookieService.set("currencySymbol", response.payload.currency.sign);
        if (typeof response.payload.display_price_inc_tax == undefined)
          response.payload.display_price_inc_tax = 0;
        this.cookieService.set("display_price_inc_tax", response.payload.display_price_inc_tax);
        if (response.payload.packingchargepostingtype == undefined)
          this.cookieService.set("packing_charge_posting_type", "0");
        else
          this.cookieService.set("packing_charge_posting_type", response.payload.packingchargepostingtype);

        this.isPriceWithTax = this.cookieService.get("display_price_inc_tax") == "1" ? true : false;

        if (response.payload.subscription_order_limit == undefined)
          this.cookieService.set("subscription", "0");
        else
          this.cookieService.set("subscription", response.payload.subscription);

        // no charge user cookie set
        if (response.payload.emp_login == undefined)
          this.cookieService.set("emp_login", "0");
        else
          this.cookieService.set("emp_login", response.payload.emp_login);

        localStorage.setItem('offer_slider_number','3');
        if(response.payload.offer_slider_number!=undefined){
          localStorage.setItem('offer_slider_number',response.payload.offer_slider_number);
        }
          
        this.getMenuCategory(this.cookieValue);
        this.getHomePageCategories(this.cookieValue);
        this.getOffers();
        this.getTrendingItem(this.cookieValue);
        this.getNewArrivalItem(this.cookieValue);
        this.getSubscriptionList(this.cookieValue);
        this.HomeBanner(this.cookieValue);
        this.subscriptionshow=this.cookieService.get("subscription");
      } else {
        this.toastr.error(response.message);
      }
      // this.spinner.hide();
    }, (err: HttpErrorResponse) => {
      this.spinner.hide();
    });
  }
  getSubscriptionList(locationId)
  {
    if (!this.cookieService.get("location")) {
      this.toastr.error("Please select location");
      window.location.href = "index.html";
      return;
    }

    var obj = {
      "location_id": this.cookieService.get("location")
    }

    // this.spinner.show();
    this.subscriptionService.gettiffinList(obj).subscribe(response => {
      if (response.status == "ok") {
        this.SubscriptionList = response.payload;
        if (this.SubscriptionList != undefined && this.SubscriptionList.length > 0) {
          var tiffineType = "";
          for (var i = 0; i < this.SubscriptionList.length; i++) {
            tiffineType = "";
            for (var j = 0; j < this.SubscriptionList[i]["item_type_data"].length; j++) {
              if (tiffineType != "")
                tiffineType += ", ";

              tiffineType += this.SubscriptionList[i]["item_type_data"][j].text_tiffin_type;
            }
            this.SubscriptionList[i]["tiffine_type"] = tiffineType;
          }
        }
        //console.log('subscription',this.SubscriptionList);
      } else {
        this.toastr.error(response.message);
      }
      // this.spinner.hide();
    }, (err: HttpErrorResponse) => {
      this.spinner.hide();
      this.toastr.error(err.toString());
    });
  }
  getMenuCategory(locationId) {
    var obj = {
      "location_id": locationId
    };

    // this.spinner.show();
    this.menuCategoryService.getMenuCategory(obj).subscribe(response => {
      if (response.status == "ok") {
        //console.log('response',response);
        var menu_data=response.payload;
        //console.log('menu_data',menu_data);
        // menu_data.forEach(function(value){
          for(let i=0;i < menu_data.length;i++)
          {
            //console.log('value.menu_id',menu_data[i].menu_id);
            this.getMenuList(menu_data[i].menu_id);
          }
         
        // });
        
      } else {
        this.toastr.error(response.message);
      }
      // this.spinner.hide();
    }, (err: HttpErrorResponse) => {
      this.spinner.hide();
    });
  }

  getHomePageCategories(locationId) {
    var obj = {
      "location_id": locationId
    };

    // this.spinner.show();
    this.menuCategoryService.getHomePageCategories(obj).subscribe(response => {
      if (response.status == "ok") {
        this.homePagetTopCategories = response.payload.home_page_category_data
      } else {
        this.toastr.error(response.message);
      }
      // this.spinner.hide();
    }, (err: HttpErrorResponse) => {
      this.spinner.hide();
    });
  }

  getMenuList(menuCategoryId) {
    let menuCategory = [];
    var obj = {
      "menu_id": menuCategoryId,
      "is_all_cat_enable": 1,
      "is_addon": 0,
      "location_id": this.cookieValue
    };

    this.spinner.show();
    this.menuCategoryService.getMenuList(obj).subscribe(response => {
      if (response.status == "ok") {
        //console.log(menuCategoryId,':',response);
        if (response.payload.menu.categories) {
          for (var idx in response.payload.menu.categories) {
            menuCategory = response.payload.menu.categories[idx];
            this.getCategories(menuCategory);
          }

          // if (response.payload.menu.categories.breakfast_category) {
          //   menuCategory = response.payload.menu.categories.breakfast_category;
          //   this.getCategories(menuCategory);
          // }
          // if (response.payload.menu.categories.dinner_category) {
          //   menuCategory = response.payload.menu.categories.dinner_category;
          //   this.getCategories(menuCategory);
          // }
          // if (response.payload.menu.categories.lunch_category) {
          //   menuCategory = response.payload.menu.categories.lunch_category;
          //   this.getCategories(menuCategory);
          // }

          if (this.categories) {
            this.menuCategoryList = [];
            for (let key1 in this.categories) {
              this.menuCategoryList.push(this.categories[key1]);
            }
            // let b = Object.values(this.menuCategoryList).map(newKeyValue => {
            //   newKeyValue.SelectIsJain = false;
            //   newKeyValue.cartQty = 0;
            //   newKeyValue.isDisplayCustomizable = 0;
            // })
            // console.log('this.menuCategoryList',this.menuCategoryList);
            this.setMenuWiseItemList();
          } else {
            this.toastr.error("Category Not Available");
          }
        }
      } else {
        this.toastr.error(response.message);
      }
      this.spinner.hide();
    }, (err: HttpErrorResponse) => {
      this.spinner.hide();
    });
  }

  getCategories(level1) {
    this.spinner.show();
    // for (let key1 in level1) {
    //   var level2 = level1[key1];
    var level2 = level1;
    //console.log('level2',level2);
    var tempCategory = {
      "category_id": "",
      "name": "",
      "items": "",
      "image": ""
    };

    if (level2.category_id) {
      tempCategory.category_id = level2.category_id;
    }
    if (level2.name) {
      tempCategory.name = level2.name;
    }
    if (level2.items) {
      tempCategory.items = level2.items;
    }
    if (level2.image) {
      tempCategory.image = level2.image;
    }
    if(tempCategory.category_id != "" && tempCategory.items != "")
    {
      this.categories[level2.category_id] = tempCategory;
    }
    
    // console.log('this.categories[level2.category_id]',this.categories[level2.category_id]);
    //   if (level2.childs) {
    //     this.getCategories(level2.childs);
    //   }
    // }
    this.spinner.hide();
  }

  setMenuWiseItemList() {
    this.spinner.show();
    for (var i = 0; i < this.menuCategoryList.length; i++) {
      if(typeof this.menuCategoryList[i]["items"] !='undefined' && this.menuCategoryList[i]["items"] !='' && this.menuCategoryList[i]["items"] != null && this.menuCategoryList[i]["items"].length > 0)
      {
        for (var j = 0; j < this.menuCategoryList[i]["items"].length; j++) {
          this.menuCategoryList[i]["items"][j]["category_id"] = this.menuCategoryList[i]["category_id"];
          this.menuCategoryList[i]["items"][j]["cartQty"] = 0;
          //console.log('this.menuCategoryList[i]["items"][j]',this.menuCategoryList[i]["items"][j]);
          //console.log('this.menuCategoryList[i]["items"][j]["units"]',this.menuCategoryList[i]["items"][j]["units"]);
          if(typeof this.menuCategoryList[i]["items"][j]["units"] !='undefined')
          {
            for (var q = 0; q < this.menuCategoryList[i]["items"][j]["units"].length; q++) {
              if (this.menuCategoryList[i]["items"][j]["units"][q]["modifiers"] != undefined
              && this.menuCategoryList[i]["items"][j]["units"][q]["modifiers"].length > 0) {
                this.menuCategoryList[i]["items"][j]["isDisplayCustomizable"] = 1;
                continue
              }
            }
          } 
        }
      }
    }
    // console.log('this.menuCategoryList1',this.menuCategoryList);
    this.updateCartQty();
    setTimeout(() => { mymethod(); }, 400);
    this.spinner.hide();
  }

  updateCartQty() {
    this.itemAddCart = JSON.parse(localStorage.getItem(this.cookieValue + "_items"));
    if (this.itemAddCart != undefined) {
      for (var j = 0; j < this.menuCategoryList.length; j++) {
        for (var k in this.menuCategoryList[j]["items"]) {
          this.menuCategoryList[j]["items"][k]["cartQty"] = 0;
          for (var i = 0; i < this.itemAddCart.length; i++) {
            if (this.menuCategoryList[j]["items"][k]["item_id"] == this.itemAddCart[i]["item_id"]
              && this.menuCategoryList[j]["category_id"] == this.itemAddCart[i]["category_id"]) {
              this.menuCategoryList[j]["items"][k]["cartQty"] = this.itemAddCart[i]["product_quantity"]
            }
          }
        }
      }
    } else {
      for (var j = 0; j < this.menuCategoryList.length; j++) {
        for (var k in this.menuCategoryList[j]["items"]) {
          this.menuCategoryList[j]["items"][k]["cartQty"] = 0;
        }
      }
    }
  }

  getTrendingItem(locationId) {
    var obj = {
      "location_id": locationId,
      "limit": "10",
      "gettype": "m"
    };
    // this.spinner.show();
    this.service.getTrendingItem(obj).subscribe(response => {
      if (response.status == "ok") {
        this.trendingItemList = response.payload;
        let b = Object.values(this.trendingItemList).map(newKeyValue => {
          newKeyValue.SelectIsJain = false;
          newKeyValue.isDisplayCustomizable = 0;
          newKeyValue.cartQty = 0;
        });

        for (var p = 0; p < this.trendingItemList.length; p++) {
          for (var q = 0; q < this.trendingItemList[p]["units"].length; q++) {
            if (this.trendingItemList[p]["units"][q]["modifiers"] != undefined && this.trendingItemList[p]["units"][q]["modifiers"].length > 0) {
              this.trendingItemList[p]["isDisplayCustomizable"] = 1;
              continue
            }
          }
        }
        this.updateCartQtyForTrendingItem();
      } else {
        this.toastr.error(response.message);
      }
      // this.spinner.hide();
    }, (err: HttpErrorResponse) => {
      this.spinner.hide();
    });
  }

  updateCartQtyForTrendingItem() {
    //To display cart item qty 
    this.itemAddCart = JSON.parse(localStorage.getItem(this.cookieValue + "_items"));
    if (this.itemAddCart != undefined) {
      for (var j in this.trendingItemList) {
        this.trendingItemList[j]["cartQty"] = 0;
        for (var i = 0; i < this.itemAddCart.length; i++) {
          if (this.trendingItemList[j]["item_id"] == this.itemAddCart[i]["item_id"]) {
            this.trendingItemList[j]["cartQty"] = this.itemAddCart[i]["product_quantity"]
          }
        }
      }
    }
    else {
      for (var j in this.trendingItemList) {
        this.trendingItemList[j]["cartQty"] = 0;
      }
    }
  }

  getNewArrivalItem(locationId) {
    var obj = {
      "location_id": locationId,
      "limit": "10",
    };
    // this.spinner.show();
    this.service.getNewArrivalItem(obj).subscribe(response => {
      if (response.status == "ok") {
        this.newArrivalItemList = response.payload;

        let b = Object.values(this.newArrivalItemList).map(newKeyValue => {
          newKeyValue.SelectIsJain = false;
          newKeyValue.isDisplayCustomizable = 0;
          newKeyValue.cartQty = 0;
        });

        for (var p = 0; p < this.newArrivalItemList.length; p++) {
          //console.log('this.newArrivalItemList[p]["units"]',this.newArrivalItemList[p]["units"]);
          if(typeof this.newArrivalItemList[p]["units"] != undefined && this.newArrivalItemList[p]["units"] != null && this.newArrivalItemList[p]["units"].length > 0)
          {
          for (var q = 0; q < this.newArrivalItemList[p]["units"].length; q++) {
            if (this.newArrivalItemList[p]["units"][q]["modifiers"] != undefined && this.newArrivalItemList[p]["units"][q]["modifiers"].length > 0) {
              this.newArrivalItemList[p]["isDisplayCustomizable"] = 1;
              continue
            }
          }
        }
        }
        //To display cart item qty 
        this.updateCartQtyForNewArrival();
      } else {
        this.toastr.error(response.message);
      }
      // this.spinner.hide();
    }, (err: HttpErrorResponse) => {
      this.spinner.hide();
    });
  }

  updateCartQtyForNewArrival() {
    this.itemAddCart = JSON.parse(localStorage.getItem(this.cookieValue + "_items"));
    if (this.itemAddCart != undefined) {
      for (var j in this.newArrivalItemList) {
        this.newArrivalItemList[j]["cartQty"] = 0;
        for (var i = 0; i < this.itemAddCart.length; i++) {
          // if (this.newArrivalItemList[j]["cartQty"] == undefined || this.newArrivalItemList[j]["cartQty"] <= 0)
          //   this.newArrivalItemList[j]["cartQty"] = 0;
          if (this.newArrivalItemList[j]["item_id"] == this.itemAddCart[i]["item_id"]) {
            this.newArrivalItemList[j]["cartQty"] = this.itemAddCart[i]["product_quantity"]
          }
        }
      }
    } else {
      for (var j in this.newArrivalItemList) {
        this.newArrivalItemList[j]["cartQty"] = 0;
      }
    }
  }

  getMenuItem(categoryId, categoryName) {
    this.spinner.show();
    let encryptedCategory = btoa(categoryId + "&#+&" + categoryName);
    this.router.navigate(['/menucategory/' + encryptedCategory]);
    this.spinner.hide();
  }

  getOffers() {
    var obj = {
      "location_id": this.cookieValue
    };

    // this.spinner.show();
    this.service.getOfferList(obj).subscribe(response => {
      if (response.status == "ok") {
        this.offerList = response.payload;
      } else {
        this.toastr.error(response.message);
      }
      // this.spinner.hide();
    }, (err: HttpErrorResponse) => {
      this.spinner.hide();
    });
  }

  getReviews(itemList) {
    this.appComponent.getReviews(itemList);
  }

  OnSelectJain(i, type, j) {
    var c = false
    if (type == "NewArrivals") {
      c = $('#jainItemNewArrivals_' + this.newArrivalItemList[i]["item_id"]).prop("checked");
      this.newArrivalItemList[i]["SelectIsJain"] = c;
    }
    else if (type == "Trending") {
      c = $('#jainItemTrending_' + this.trendingItemList[i]["item_id"]).prop("checked");
      this.trendingItemList[i]["SelectIsJain"] = c;
    }
    else if (type == "CategoryWise") {
      c = $('#jainItem_' + this.menuCategoryList[j]["items"][i]["item_id"]).prop("checked");
      this.menuCategoryList[j]["items"][i]["SelectIsJain"] = c;
    }
  }

  getItemDetails(itemDetail, type, i, j) {
    this.spinner.show();
    //var existingItems = [];
    // if (localStorage.getItem("items")) {
    //   existingItems = (JSON.parse(localStorage.getItem("items")));

    //   for (var idx in existingItems) {
    //     if (itemDetail.item_id == existingItems[idx].item_id) {
    //       this.toastr.info("Item Already Exist In Cart");
    //       this.spinner.hide();
    //       return;
    //     }
    //   }
    // }

    // this.lblItemName = itemDetail.name;
    $('#ItemAddToCart').modal('show');
    // $('#addons-options-modal').modal('hide');
    // $('#ItemUnitModal').modal('show');

    this.itemDetails = itemDetail;
    this.itemDetailPrice = this.itemDetails['price'];
    this.itemDetailName = this.itemDetails['name'];
    this.itemDetailDescription = this.itemDetails['description'];
    this.itemDetailImage = this.itemDetails['image_url'].main_image;

    this.itemUnits = itemDetail.units;
    this.itemTypes = itemDetail.types
    this.lblItemName = itemDetail.name;

    if (this.isPriceWithTax)
      this.lblItemRateWithTax = itemDetail.display_inc_tax_price;

    this.lblItemRate = itemDetail.price;

    this.itemDetailTypeImage = this.itemTypes[0].image;
    this.lblCategoryName = itemDetail.categoryname;
    // this.lblTotalAmount = itemDetail.price;
    this.itemRadioModifierTotal = 0

    var objItem = {
      "product_quantity": "0"
    }
    Object.assign(this.itemDetails, objItem);
    this.temp_i = i;
    this.temp_j = j;

    if (this.itemUnits != undefined && this.itemUnits.length > 0) {
      this.getModifiers(0, this.itemUnits[0].modifiers, type, i, j);
      for (var k = 0; k < this.itemUnits.length; k++) {
        if (k == 0)
          $("#units" + k).addClass("active");
        else
          $("#units" + k).removeClass("active");

      }
    }

    this.spinner.hide();
  }

  getModifiers(arrayIndex, unitModifiers, type, i, j) {
    this.spinner.show();
    this.itemModifiers = [];
    this.itemModifiers = unitModifiers;

    if (this.isPriceWithTax)
      this.lblItemRateWithTax = this.itemDetails['units'][arrayIndex].display_inc_tax_price;

    this.lblItemRate = this.itemDetails['units'][arrayIndex].price;

    this.itemDetails['default_unit_id'] = this.itemDetails['units'][arrayIndex].unit_id;
    this.itemDetails['default_unitname'] = this.itemDetails['units'][arrayIndex].unit_name;

    if (this.isPriceWithTax)
      this.itemDetails['priceWithTax'] = this.itemDetails['units'][arrayIndex].display_inc_tax_price;

    this.itemDetails['price'] = this.itemDetails['units'][arrayIndex].price;

    if ($('#itemQuantity').val() != undefined && parseInt($('#itemQuantity').val()) > 1)
      this.itemDetails['product_quantity'] = parseInt($('#itemQuantity').val())
    else
      this.itemDetails['product_quantity'] = "1";

    if (this.isPriceWithTax)
      this.itemTotal = parseFloat(this.itemDetails['product_quantity']) * parseFloat(this.itemDetails['units'][arrayIndex].display_inc_tax_price);
    else
      this.itemTotal = parseFloat(this.itemDetails['product_quantity']) * parseFloat(this.itemDetails['units'][arrayIndex].price);

    this.lblTotalAmount = (parseFloat(this.itemTotal) + parseFloat(this.itemModifierTotal) + parseFloat(this.itemChildModifierTotal)).toFixed(2);

    $("#ItemUnitModal").modal('hide');
    $("#ItemAddToCart").modal('show');

    this.divFormRow.nativeElement.innerHTML = null;
    this.itemModifierTotal = 0;
    this.itemChildModifierTotal = 0;
    this.totalPriceCalculation();

    if (this.itemModifiers) {
      this.setModifierUI(this.itemModifiers);
    }

    this.divJainAddToCart.nativeElement.innerHTML = null;
    if (this.itemDetails['is_jain'] == 1) {
      this.setAddToCartJainUI(this.itemDetails, arrayIndex, type, i, j);
    }
    this.spinner.hide();
  }

  setModifierUI(modifiers) {
    this.spinner.show();

    const ulMain: HTMLParagraphElement = this.renderer.createElement('ul');
    this.renderer.appendChild(this.divFormRow.nativeElement, ulMain);

    for (var i = 0; i < modifiers.length; i++) {
      const liMain: HTMLParagraphElement = this.renderer.createElement('li');
      this.renderer.appendChild(ulMain, liMain);

      if (modifiers[i].items) {
        var childModifiers = modifiers[i].items;

        const span: HTMLParagraphElement = this.renderer.createElement('span');
        span.innerHTML = "<b>" + modifiers[i].modifier_name + "</b>";
        this.renderer.appendChild(liMain, span);

        if (modifiers[i].selection == 0) {
          const divFormRow: HTMLParagraphElement = this.renderer.createElement('div');
          divFormRow.setAttribute("class", "mb-0 col-md-12 form-group mb-3 pt-2");
          this.renderer.appendChild(liMain, divFormRow);

          const divButton: HTMLParagraphElement = this.renderer.createElement('div');
          divButton.setAttribute("class", "btn-group btn-group-toggle w-100");
          divButton.setAttribute("data-toggle", "buttons");
          this.renderer.appendChild(divFormRow, divButton);

          for (var n = 0; n < childModifiers.length; n++) {
            const divLabel: HTMLParagraphElement = this.renderer.createElement('a');

            if (n == 0) {
              divLabel.setAttribute("class", "btn btn-outline-secondary btns-select active");
              this.setRadioModifiersValue((i + "_" + n).toString());
              this.itemModifiers[i].items[n].quantity = 1
            }
            else
              divLabel.setAttribute("class", "btn btn-outline-secondary btns-select");

            divLabel.setAttribute("style", "border-radius: 30px; margin-left: 10px;");
            divLabel.setAttribute("id", (i + "_" + n).toString());

            if (this.isPriceWithTax)
              divLabel.innerHTML = childModifiers[n].modifier_item_name + " <br> (" + this.currencySymbol + " " + parseFloat(childModifiers[n].display_inc_tax_price).toFixed(2) + ")";
            else
              divLabel.innerHTML = childModifiers[n].modifier_item_name + " <br> (" + this.currencySymbol + " " + parseFloat(childModifiers[n].price).toFixed(2) + ")";
            divLabel.addEventListener('click', (e) => {
              this.setRadioModifiersValue(divLabel.id);
            });
            this.renderer.appendChild(divButton, divLabel);

            const inputType: HTMLParagraphElement = this.renderer.createElement('input');
            inputType.setAttribute("type", "radio");
            inputType.setAttribute("name", "itemaddonid" + n.toString());
            inputType.setAttribute("data-val", "0.00");
            inputType.setAttribute("class", "form-check-input-addon");
            inputType.setAttribute("id", "itemaddonid" + n.toString());
            inputType.setAttribute("value", n.toString());
            this.renderer.appendChild(divLabel, inputType)
          }
        } else {
          const ul: HTMLParagraphElement = this.renderer.createElement('ul');
          this.renderer.appendChild(liMain, ul);

          for (var n = 0; n < childModifiers.length; n++) {
            const divFormRow: HTMLParagraphElement = this.renderer.createElement('div');
            divFormRow.setAttribute("class", "form-row");
            this.renderer.appendChild(ul, divFormRow);

            const div61: HTMLParagraphElement = this.renderer.createElement('div');
            div61.setAttribute("class", "col-md-6 form-group");
            this.renderer.appendChild(divFormRow, div61);

            const divInputGropu1: HTMLParagraphElement = this.renderer.createElement('div');
            divInputGropu1.setAttribute("class", "input-group");
            divInputGropu1.setAttribute("style", "height: 100%; line-height: 30px;");
            this.renderer.appendChild(div61, divInputGropu1);

            const span: HTMLParagraphElement = this.renderer.createElement('span');

            // span.innerHTML = childModifiers[n].modifier_item_name + " (" + this.currencySymbol + " " + parseFloat(childModifiers[n].price).toFixed(2) + ")";
            if (parseFloat(childModifiers[n].price) > 0) {
              if (this.isPriceWithTax)
                span.innerHTML = "<b>" + childModifiers[n].modifier_item_name + "</b> (" + this.currencySymbol + " " + parseFloat(childModifiers[n].display_inc_tax_price).toFixed(2) + ")";
              else
                span.innerHTML = "<b>" + childModifiers[n].modifier_item_name + "</b> (" + this.currencySymbol + " " + parseFloat(childModifiers[n].price).toFixed(2) + ")";
            } else {
              span.innerHTML = "<b>" + childModifiers[n].modifier_item_name + "</b> (Free)";
            }
            this.renderer.appendChild(divInputGropu1, span);

            const div62: HTMLParagraphElement = this.renderer.createElement('div');
            div62.setAttribute("class", "col-md-6 form-group");
            this.renderer.appendChild(divFormRow, div62);

            const divSpanMod: HTMLParagraphElement = this.renderer.createElement('span');
            divSpanMod.setAttribute("class", "count-number float-right");
            this.renderer.appendChild(div62, divSpanMod);

            const divInputGropu2: HTMLParagraphElement = this.renderer.createElement('div');
            divInputGropu2.setAttribute("class", "input-group");
            divInputGropu2.setAttribute("style", "text-align: right; display: block;");
            this.renderer.appendChild(divSpanMod, divInputGropu2);

            const buttonMinus: HTMLParagraphElement = this.renderer.createElement('bttoun');
            buttonMinus.setAttribute("type", "button");
            buttonMinus.setAttribute("class", "btn-sm left dec btn btnCart");
            buttonMinus.setAttribute("id", "decrease");
            buttonMinus.setAttribute("value", "Decrease Value");
            buttonMinus.addEventListener('click', (e) => {
              this.countChildModifierChange(0, inputNumber.id);
            });

            const iconMinus: HTMLParagraphElement = this.renderer.createElement('i');
            iconMinus.setAttribute("class", "feather-minus btnIcon");

            const inputNumber: HTMLParagraphElement = this.renderer.createElement('input');
            inputNumber.setAttribute("disabled", "");
            inputNumber.setAttribute("min", childModifiers[n].min_no);
            inputNumber.setAttribute("max", childModifiers[n].max_no);
            inputNumber.setAttribute("name", "quantity");
            inputNumber.setAttribute("value", "0");
            inputNumber.setAttribute("class", "count-number-input");
            inputNumber.setAttribute("id", (i + "_" + n).toString());
            inputNumber.setAttribute("type", "text");

            const buttonPlus: HTMLParagraphElement = this.renderer.createElement('bttoun');
            buttonPlus.setAttribute("type", "button");
            buttonPlus.setAttribute("class", "btn-sm right inc btn btnCart");
            buttonPlus.setAttribute("id", "increase");
            buttonPlus.setAttribute("value", "Increase Value");
            buttonPlus.addEventListener('click', (e) => {
              this.countChildModifierChange(1, inputNumber.id);
            });

            const iconPlus: HTMLParagraphElement = this.renderer.createElement('i');
            iconPlus.setAttribute("class", "feather-plus btnIcon");

            this.renderer.appendChild(divInputGropu2, buttonMinus);
            this.renderer.appendChild(buttonMinus, iconMinus);
            this.renderer.appendChild(divInputGropu2, inputNumber);
            this.renderer.appendChild(divInputGropu2, buttonPlus);
            this.renderer.appendChild(buttonPlus, iconPlus);
          }
        }
      } else {
        var currentModifierAmount = modifiers[i].price;

        const divFormRow: HTMLParagraphElement = this.renderer.createElement('div');
        divFormRow.setAttribute("class", "form-row");
        this.renderer.appendChild(liMain, divFormRow);

        const div61: HTMLParagraphElement = this.renderer.createElement('div');
        div61.setAttribute("class", "col-md-6 form-group");
        this.renderer.appendChild(divFormRow, div61);

        const divInputGropu1: HTMLParagraphElement = this.renderer.createElement('div');
        divInputGropu1.setAttribute("class", "input-group");
        divInputGropu1.setAttribute("style", "height: 100%; line-height: 30px;");
        this.renderer.appendChild(div61, divInputGropu1);

        const span: HTMLParagraphElement = this.renderer.createElement('span');

        if (parseFloat(modifiers[i].price) > 0) {
          if (this.isPriceWithTax)
            span.innerHTML = "<b>" + modifiers[i].modifier_name + "</b> (" + this.currencySymbol + " " + parseFloat(modifiers[i].display_inc_tax_price).toFixed(2) + ")";
          else
            span.innerHTML = "<b>" + modifiers[i].modifier_name + "</b> (" + this.currencySymbol + " " + parseFloat(modifiers[i].price).toFixed(2) + ")";
        } else {
          span.innerHTML = "<b>" + modifiers[i].modifier_name + "</b> (Free)";
        }
        this.renderer.appendChild(divInputGropu1, span);

        const div62: HTMLParagraphElement = this.renderer.createElement('div');
        div62.setAttribute("class", "col-md-6 form-group");
        this.renderer.appendChild(divFormRow, div62);

        const divSpanModSub: HTMLParagraphElement = this.renderer.createElement('span');
        divSpanModSub.setAttribute("class", "count-number float-right");
        this.renderer.appendChild(div62, divSpanModSub);

        const divInputGropu2: HTMLParagraphElement = this.renderer.createElement('div');
        divInputGropu2.setAttribute("class", "input-group");
        divInputGropu2.setAttribute("style", "text-align: right; display: block;");
        this.renderer.appendChild(divSpanModSub, divInputGropu2);

        const buttonMinus: HTMLParagraphElement = this.renderer.createElement('bttoun');
        buttonMinus.setAttribute("type", "button");
        buttonMinus.setAttribute("class", "btn-sm left dec btn btnCart");
        buttonMinus.setAttribute("id", "decrease");
        buttonMinus.setAttribute("value", "Decrease Value");
        buttonMinus.addEventListener('click', (e) => {
          this.countModifierChange(0, inputNumber.id);
        });

        const iconMinus: HTMLParagraphElement = this.renderer.createElement('i');
        iconMinus.setAttribute("class", "feather-minus btnIcon");

        const inputNumber: HTMLParagraphElement = this.renderer.createElement('input');
        inputNumber.setAttribute("disabled", "");
        inputNumber.setAttribute("min", modifiers[i].min_item);
        inputNumber.setAttribute("max", modifiers[i].max_item);
        inputNumber.setAttribute("value", "0");
        inputNumber.setAttribute("class", "count-number-input");
        inputNumber.setAttribute("id", i.toString());
        inputNumber.setAttribute("type", "text");

        const buttonPlus: HTMLParagraphElement = this.renderer.createElement('bttoun');
        buttonPlus.setAttribute("type", "button");
        buttonPlus.setAttribute("class", "btn-sm right inc btn btnCart");
        buttonPlus.setAttribute("id", "increase");
        buttonPlus.setAttribute("value", "Increase Value");
        buttonPlus.addEventListener('click', (e) => {
          this.countModifierChange(1, inputNumber.id);
        });

        const iconPlus: HTMLParagraphElement = this.renderer.createElement('i');
        iconPlus.setAttribute("class", "feather-plus btnIcon");

        this.renderer.appendChild(divInputGropu2, buttonMinus);
        this.renderer.appendChild(buttonMinus, iconMinus);
        this.renderer.appendChild(divInputGropu2, inputNumber);
        this.renderer.appendChild(divInputGropu2, buttonPlus);
        this.renderer.appendChild(buttonPlus, iconPlus);
      }
    }
    this.spinner.hide();
  }

  countItemChange(status) {
    this.spinner.show();
    var value = parseInt($('#itemQuantity').val());
    if (value > 0) {
      if (status == 0) {
        if (value == 1) {
          this.toastr.error("You have to select atleast 1 Qty.");
          this.spinner.hide();
          return;
        }

        value = value - 1;
        $('#itemQuantity').val(value);

        this.itemDetails['product_quantity'] = value;
        if (this.isPriceWithTax)
          this.itemTotal = parseFloat(this.itemDetails['product_quantity']) * parseFloat(this.itemDetails['priceWithTax']);
        else
          this.itemTotal = parseFloat(this.itemDetails['product_quantity']) * parseFloat(this.itemDetails['price']);

      } else {
        value = value + 1;
        $('#itemQuantity').val(value);

        this.itemDetails['product_quantity'] = value;

        if (this.isPriceWithTax)
          this.itemTotal = parseFloat(this.itemDetails['product_quantity']) * parseFloat(this.itemDetails['priceWithTax']);
        else
          this.itemTotal = parseFloat(this.itemDetails['product_quantity']) * parseFloat(this.itemDetails['price']);
      }

      this.totalPriceCalculation();
    }
    this.spinner.hide();
  }

  countModifierChange(status, id) {
    this.spinner.show();
    var value = parseInt($('#' + id).val());

    var objModifier = {
      "quantity": "0"
    }
    Object.assign(this.itemModifiers[id], objModifier);

    if (status == 0) {
      if (value > 0) {
        value = value - 1;
        $('#' + id).val(value);

        this.itemModifiers[id].quantity = value;
        if (this.isPriceWithTax)
          this.itemModifierTotal = parseFloat(this.itemModifiers[id].quantity) * parseFloat(this.itemModifiers[id].display_inc_tax_price);
        else
          this.itemModifierTotal = parseFloat(this.itemModifiers[id].quantity) * parseFloat(this.itemModifiers[id].price);
      }
    } else {
      var isMaxLimitReach = 0;

      if (value == this.itemModifiers[id].max_item) {
        this.itemModifiers[id].quantity = value;
        this.toastr.warning("Maximum Quantity For " + this.itemModifiers[id].modifier_name + " Is " + this.itemModifiers[id].max_item);
        isMaxLimitReach = 1;
      }

      if (isMaxLimitReach == 0) {
        if (value >= 0) {
          value = value + 1;
          $('#' + id).val(value);

          this.itemModifiers[id].quantity = value;
          if (this.isPriceWithTax)
            this.itemModifierTotal = parseFloat(this.itemModifiers[id].quantity) * parseFloat(this.itemModifiers[id].display_inc_tax_price);
          else
            this.itemModifierTotal = parseFloat(this.itemModifiers[id].quantity) * parseFloat(this.itemModifiers[id].price);
        }
      }
    }

    var total = 0;
    for (var i = 0; i < this.itemModifiers.length; i++) {
      if (this.itemModifiers[i].quantity) {
        var Qty = (this.itemModifiers[i].quantity ? this.itemModifiers[i].quantity : 0);
        if (this.isPriceWithTax)
          var price = this.itemModifiers[i].display_inc_tax_price;
        else
          var price = this.itemModifiers[i].price;

        var total = total + (parseFloat(Qty) * parseFloat(price));
      }
    }

    this.itemModifierTotal = total;
    this.totalPriceCalculation();
    this.spinner.hide();
  }

  countChildModifierChange(status, id) {
    this.spinner.show();
    var parentId = id.split("_")[0];
    var childId = id.split("_")[1];

    var value = parseInt($('#' + id).val());

    var objChildModifier = {
      "quantity": "0"
    }
    Object.assign(this.itemModifiers[parentId].items[childId], objChildModifier);

    if (status == 0) {
      if (value > 0) {
        value = value - 1;
        $('#' + id).val(value);

        this.itemModifiers[parentId].items[childId].quantity = value;
        if (this.isPriceWithTax)
          this.itemChildModifierTotal = (parseFloat(this.itemModifiers[parentId].items[childId].quantity) * parseFloat(this.itemModifiers[parentId].items[childId].display_inc_tax_price));
        else
          this.itemChildModifierTotal = (parseFloat(this.itemModifiers[parentId].items[childId].quantity) * parseFloat(this.itemModifiers[parentId].items[childId].price));
      }
    } else {
      var isMaxLimitReach = 0;

      if (value == this.itemModifiers[parentId].items[childId].max_item) {
        this.itemModifiers[parentId].items[childId].quantity = value;
        this.toastr.warning("Maximum Quantity For " + this.itemModifiers[parentId].items[childId].modifier_item_name + " Is " + this.itemModifiers[parentId].items[childId].max_item);
        isMaxLimitReach = 1;
      }

      if (isMaxLimitReach == 0) {
        if (value >= 0) {
          value = value + 1;
          $('#' + id).val(value);

          this.itemModifiers[parentId].items[childId].quantity = value;
          if (this.isPriceWithTax)
            this.itemChildModifierTotal = (parseFloat(this.itemModifiers[parentId].items[childId].quantity) * parseFloat(this.itemModifiers[parentId].items[childId].display_inc_tax_price));
          else
            this.itemChildModifierTotal = (parseFloat(this.itemModifiers[parentId].items[childId].quantity) * parseFloat(this.itemModifiers[parentId].items[childId].price));
        }
      }
    }
    var total = 0;
    for (var n = 0; n < this.itemModifiers.length; n++) {
      if (this.itemModifiers[n].items) {
        for (var i = 0; i < this.itemModifiers[n].items.length; i++) {
          var Qty = (this.itemModifiers[n].items[i].quantity ? this.itemModifiers[n].items[i].quantity : 0);
          if (this.isPriceWithTax)
            var price = this.itemModifiers[n].items[i].display_inc_tax_price;
          else
            var price = this.itemModifiers[n].items[i].price;
          var total = total + (parseFloat(Qty) * parseFloat(price));
        }
      }
    }

    this.itemChildModifierTotal = total;
    this.itemRadioModifierTotal = 0;
    this.totalPriceCalculation();
    this.spinner.hide();
  }

  setRadioModifiersValue(id) {
    this.spinner.show();
    var parentId = id.split("_")[0];
    var childId = id.split("_")[1];

    var already = 0;
    if (this.itemModifiers[parentId].items[childId].quantity == 1) {
      already++;
    }

    var objChildModifier = {
      "quantity": "0"
    }
    Object.assign(this.itemModifiers[parentId].items[childId], objChildModifier);

    var amount = 0;
    for (var n = 0; n < this.itemModifiers[parentId].items.length; n++) {
      if (n == childId && already == 0) {
        if (this.isPriceWithTax)
          amount += parseFloat(this.itemModifiers[parentId].items[n]["display_inc_tax_price"])
        else
          amount += parseFloat(this.itemModifiers[parentId].items[n]["price"])
      } else {
        if (this.itemModifiers[parentId].items[n].quantity == 1) {
          if (this.isPriceWithTax)
            amount -= parseFloat(this.itemModifiers[parentId].items[n]["display_inc_tax_price"])
          else
            amount -= parseFloat(this.itemModifiers[parentId].items[n]["price"])
          this.itemModifiers[parentId].items[n].quantity = 0;
        }
      }
    }
    // for (var n = 0; n < this.itemModifiers.length; n++) {
    //   if (this.itemModifiers[n].items) {
    //     for (var i = 0; i < this.itemModifiers[n].items.length; i++) {
    //       this.itemModifiers[n].items[i].quantity = 0;
    //     }
    //   }
    // }
    this.itemModifiers[parentId].items[childId].quantity = 1;
    this.itemRadioModifierTotal += amount;
    // this.itemRadioModifierTotal = (parseFloat(this.itemModifiers[parentId].items[childId].quantity) * parseFloat(this.itemModifiers[parentId].items[childId].price));

    this.totalPriceCalculation();
    this.spinner.hide();
  }

  totalPriceCalculation() {
    this.lblTotalAmount = (parseFloat(this.itemTotal) + ((parseFloat(this.itemModifierTotal) + parseFloat(this.itemChildModifierTotal) + parseFloat(this.itemRadioModifierTotal)) * parseFloat(this.itemDetails['product_quantity']))).toFixed(2);
  }

  resetAddToCart() {
    this.spinner.show();
    this.divFormRow.nativeElement.innerHTML = null;
    this.ModifierForm.resetForm();
    // this.ngOnInit();
    $("#ItemAddToCart").modal('hide');
    $("#ItemAddToCart1").modal('hide');
    $('#ItemUnitModal').modal('hide');
    $('#itemQuantity').val(1);

    this.lblTotalAmount = 0;
    this.itemTotal = 0;
    this.itemModifierTotal = 0;
    this.itemChildModifierTotal = 0;
    //this.ngOnInit();
    if (this.itemModifiers != undefined) {
      for (var n = 0; n < this.itemModifiers.length; n++) {
        if (this.itemModifiers[n].items) {
          for (var i = 0; i < this.itemModifiers[n].items.length; i++) {
            //let index =  this.itemModifiers[n].items[i].findIndex(x => x == "quantity");
            if (this.itemModifiers[n].items[i].hasOwnProperty("quantity") > 0)
              this.itemModifiers[n].items[i].quantity = 0;
          }
        }
      }
    }
    this.updateCartQty();
    this.updateCartQtyForNewArrival();
    this.updateCartQtyForTrendingItem();
    this.spinner.hide();
  }

  addToCart() {
    this.spinner.show();
    var formValues = this.ModifierForm.controls;

    if (this.ModifierForm.valid) {
      var itemModiferDetail = "";

      if (this.itemUnits) {
        for (var m = 0; m < this.itemUnits.length; m++) {
          if (this.itemUnits[m].modifiers) {
            for (var n = 0; n < this.itemUnits[m].modifiers.length; n++) {
              if (this.itemUnits[m].modifiers[n].items) {
                for (var p = 0; p < this.itemUnits[m].modifiers[n].items.length; p++) {
                  if (parseFloat(this.itemUnits[m].modifiers[n].items[p].quantity)) {
                    if (itemModiferDetail.length > 0) {
                      itemModiferDetail = itemModiferDetail + ", "
                    }

                    itemModiferDetail = itemModiferDetail + this.itemUnits[m].modifiers[n].items[p].modifier_item_name + "(" + this.itemUnits[m].modifiers[n].items[p].quantity + " X " + parseFloat(this.itemUnits[m].modifiers[n].items[p].price) + ")";
                  }
                }
              } else {
                if (parseFloat(this.itemUnits[m].modifiers[n].quantity)) {
                  if (itemModiferDetail.length > 0) {
                    itemModiferDetail = itemModiferDetail + ", "
                  }

                  itemModiferDetail = itemModiferDetail + this.itemUnits[m].modifiers[n].modifier_name + "(" + this.itemUnits[m].modifiers[n].quantity + " X " + parseFloat(this.itemUnits[m].modifiers[n].price) + ")";
                }
              }
            }
          }
        }
      }

      var objNote = {
        "addNote": formValues.addNote.value,
        "itemModifierDetails": itemModiferDetail,
        "subTotal": this.lblTotalAmount
      }
      Object.assign(this.itemDetails, objNote);

      var itemAddCart = [];
      if (localStorage.getItem(this.cookieValue + "_items")) {
        itemAddCart = (JSON.parse(localStorage.getItem(this.cookieValue + "_items")));
      }

      itemAddCart.push(this.itemDetails);

      localStorage.setItem(this.cookieValue + "_items", JSON.stringify(itemAddCart));
      this.sharedService.sendClickEvent();
      this.resetAddToCart();

      this.toastr.success("Item Added Successfully");
    } else {
      this.toastr.error("Invalid Form");
    }
    this.spinner.hide();
  }

  openItemDetail(itemInfo, type, i, j) {
    this.spinner.show();
    this.itemInformations = itemInfo;
    this.itemDetailPrice = itemInfo['price'];
    this.itemDetailName = itemInfo['name'];
    this.itemDetailDescription = itemInfo['description'];
    this.itemDetailImage = itemInfo['image_url'].main_image;
    this.itemDetailIsJain = itemInfo['is_jain'];
    this.lblCategoryName = itemInfo['categoryname'];
    this.itemDetailTypeImage = itemInfo.types[0].image;

    // $("#addons-options-modal").modal("show");
    $('#ItemAddToCart').modal('show');
    this.temp_Type = type;
    this.temp_i = i;
    this.temp_j = j;
    this.divJainDetail.nativeElement.innerHTML = null;
    if (this.itemDetailIsJain == 1) {
      const divWhite: HTMLParagraphElement = this.renderer.createElement('div');
      divWhite.setAttribute("class", "bg-white rounded shadow mb-3 py-2 col-12 mt-2 pl-0 pr-0");
      this.renderer.appendChild(this.divJainDetail.nativeElement, divWhite);

      const divFormGroup: HTMLParagraphElement = this.renderer.createElement('div');
      divFormGroup.setAttribute("class", "col-md-12 form-group mb-0");
      this.renderer.appendChild(divWhite, divFormGroup);

      const divCheckbox: HTMLParagraphElement = this.renderer.createElement('div');
      divCheckbox.setAttribute("class", "custom-control custom-checkbox test");
      this.renderer.appendChild(divFormGroup, divCheckbox);

      const input: HTMLParagraphElement = this.renderer.createElement('input');
      input.setAttribute("type", "checkbox");
      input.setAttribute("ngModel", "");
      input.setAttribute("id", itemInfo['item_id']);
      input.setAttribute("name", itemInfo['item_id']);
      input.setAttribute("class", "custom-control-input");
      input.addEventListener('click', (e) => {
        this.setJainStatusDetail(e.srcElement, type, i, j);
      });

      var c = false;
      if (type == "NewArrivals") {
        c = $("#jainItemNewArrivals_" + itemInfo["item_id"]).prop("checked");
      }
      else if (type == "Trending") {
        c = $("#jainItemTrending_" + itemInfo["item_id"]).prop("checked");
      }
      else if (type == "CategoryWise") {
        c = $("#jainItem_" + itemInfo["item_id"]).prop("checked");
      }
      //var c = $("#jainItem_" + itemInfo["item_id"]).prop("checked");
      if (c == true) {
        input.setAttribute("checked", c.toString());
      }
      this.renderer.appendChild(divCheckbox, input);

      const label: HTMLParagraphElement = this.renderer.createElement('label');
      label.setAttribute("for", itemInfo['item_id']);
      label.setAttribute("class", "custom-control-label pt-1");
      this.renderer.appendChild(divCheckbox, label);

      const strong: HTMLParagraphElement = this.renderer.createElement('strong');
      strong.innerHTML = "Jain";
      this.renderer.appendChild(label, strong);
    }
    this.spinner.hide();
  }

  setJainStatusDetail(chkValue, type, i, j) {
    if (chkValue.checked) {
      if (type == "NewArrivals") {
        this.newArrivalItemList[i]["SelectIsJain"] = true;
        $("#jainItemNewArrivals_" + this.newArrivalItemList[i]["item_id"]).prop("checked", true);
      }
      else if (type == "Trending") {
        this.trendingItemList[i]["SelectIsJain"] = true;
        $("#jainItemTrending_" + this.trendingItemList[i]["item_id"]).prop("checked", true);
      }
      else if (type == "CategoryWise") {
        this.menuCategoryList[j]["items"][i]["items"]["SelectIsJain"] = true;
        $("#jainItem_" + this.menuCategoryList[j]["items"][i]["item_id"]).prop("checked", true);
      }
    } else {
      if (type == "NewArrivals") {
        this.newArrivalItemList[i]["SelectIsJain"] = false;
        $("#jainItemNewArrivals_" + this.newArrivalItemList[i]["item_id"]).prop("checked", false);
      }
      else if (type == "Trending") {
        this.trendingItemList[i]["SelectIsJain"] = false;
        $("#jainItemTrending_" + this.trendingItemList[i]["item_id"]).prop("checked", false);
      }
      else if (type == "CategoryWise") {
        this.menuCategoryList[j]["items"][i]["SelectIsJain"] = false;
        $("#jainItem_" + this.menuCategoryList[j]["items"][i]["item_id"]).prop("checked", false);
      }
    }
  }

  setAddToCartJainUI(chkItemDetails, index, type, i, j) {
    const divRow: HTMLParagraphElement = this.renderer.createElement('div');
    divRow.setAttribute("class", "form-row");
    this.renderer.appendChild(this.divJainAddToCart.nativeElement, divRow);

    const divFormGroup: HTMLParagraphElement = this.renderer.createElement('div');
    divFormGroup.setAttribute("class", "col-md-4 form-group mb-0");
    this.renderer.appendChild(divRow, divFormGroup);

    const divCheckbox: HTMLParagraphElement = this.renderer.createElement('div');
    divCheckbox.setAttribute("class", "custom-control custom-checkbox test");
    this.renderer.appendChild(divFormGroup, divCheckbox);

    const input: HTMLParagraphElement = this.renderer.createElement('input');
    input.setAttribute("type", "checkbox");
    input.setAttribute("ngModel", "");
    input.setAttribute("id", "item_" + chkItemDetails['item_id']);
    input.setAttribute("name", "item_" + chkItemDetails['item_id']);
    input.setAttribute("class", "custom-control-input");
    input.addEventListener('click', (e) => {
      this.setJainStatusAddToCart(e.srcElement, type, i, j);
    });

    //var c = $("#jainItem_" + chkItemDetails["item_id"]).prop("checked");
    var c = false;
    if (type == "NewArrivals") {
      c = $("#jainItemNewArrivals_" + this.newArrivalItemList[i]["item_id"]).prop("checked");
    }
    else if (type == "Trending") {
      c = $("#jainItemTrending_" + this.trendingItemList[i]["item_id"]).prop("checked");
    }
    else if (type == "CategoryWise") {
      c = $("#jainItem_" + this.menuCategoryList[j]["items"][i]["item_id"]).prop("checked");
    }
    if (c == true) {
      input.setAttribute("checked", c.toString());
    }
    this.renderer.appendChild(divCheckbox, input);

    const label: HTMLParagraphElement = this.renderer.createElement('label');
    label.setAttribute("for", "item_" + chkItemDetails['item_id']);
    label.setAttribute("class", "custom-control-label pt-1");
    this.renderer.appendChild(divCheckbox, label);

    const strong: HTMLParagraphElement = this.renderer.createElement('strong');
    strong.innerHTML = "Jain";
    this.renderer.appendChild(label, strong);
  }

  setJainStatusAddToCart(chkValue, type, i, j) {
    if (chkValue.checked) {
      if (type == "NewArrivals") {
        $("#jainItemNewArrivals_" + this.newArrivalItemList[i]["item_id"]).prop("checked", true);
        this.newArrivalItemList[i]["SelectIsJain"] = true;
      }
      else if (type == "Trending") {
        $("#jainItemTrending_" + this.trendingItemList[i]["item_id"]).prop("checked", true);
        this.trendingItemList[i]["SelectIsJain"] = true;
      }
      else if (type == "CategoryWise") {
        $("#jainItem_" + this.menuCategoryList[j]["items"][i]["item_id"]).prop("checked", true);
        this.menuCategoryList[j]["items"][i]["SelectIsJain"] = true;
      }
    } else {
      if (type == "NewArrivals") {
        $("#jainItemNewArrivals_" + this.newArrivalItemList[i]["item_id"]).prop("checked", false);
        this.newArrivalItemList[i]["SelectIsJain"] = false;
      }
      else if (type == "Trending") {
        $("#jainItemTrending_" + this.trendingItemList[i]["item_id"]).prop("checked", false);
        this.trendingItemList[i]["SelectIsJain"] = false;
      }
      else if (type == "CategoryWise") {
        $("#jainItem_" + this.menuCategoryList[j]["items"][i]["item_id"]).prop("checked", false);
        this.menuCategoryList[j]["items"][i]["SelectIsJain"] = false;
      }
    }
  }

  addMinusItem(status, itemId, i, j, type) {
    this.spinner.show();
    this.itemAddCart = JSON.parse(localStorage.getItem(this.cookieValue + "_items"));
    for (var idx in this.itemAddCart) {
      if (this.itemAddCart[idx].item_id == itemId) {
        if (type == "CategoryWise") {
          var value = parseInt($('#CatWise' + i).val());
          if (status == 0 && value > 0) {
            this.itemAddCart[idx].product_quantity = parseFloat(this.itemAddCart[idx].product_quantity) - 1;
            this.menuCategoryList[j]["items"][i]["cartQty"] = parseFloat(this.itemAddCart[idx].product_quantity);
          }

          if (status == 1) {
            this.itemAddCart[idx].product_quantity = parseFloat(this.itemAddCart[idx].product_quantity) + 1;
            this.menuCategoryList[j]["items"][i]["cartQty"] = parseFloat(this.itemAddCart[idx].product_quantity);
          }
        }
        else if (type == "Trending") {
          var value = parseInt($('#Trending' + i).val());
          if (status == 0 && value > 0) {
            this.itemAddCart[idx].product_quantity = parseFloat(this.itemAddCart[idx].product_quantity) - 1;
            this.trendingItemList[i]["cartQty"] = parseFloat(this.itemAddCart[idx].product_quantity);
          }

          if (status == 1) {
            this.itemAddCart[idx].product_quantity = parseFloat(this.itemAddCart[idx].product_quantity) + 1;
            this.trendingItemList[i]["cartQty"] = parseFloat(this.itemAddCart[idx].product_quantity);
          }
        }
        else if (type == "NewArrivals") {
          var value = parseInt($('#NewArrivals' + i).val());
          if (status == 0 && value > 0) {
            this.itemAddCart[idx].product_quantity = parseFloat(this.itemAddCart[idx].product_quantity) - 1;
            this.newArrivalItemList[i]["cartQty"] = parseFloat(this.itemAddCart[idx].product_quantity);
          }

          if (status == 1) {
            this.itemAddCart[idx].product_quantity = parseFloat(this.itemAddCart[idx].product_quantity) + 1;
            this.newArrivalItemList[i]["cartQty"] = parseFloat(this.itemAddCart[idx].product_quantity);
          }
        }

        // if (this.itemAddCart[idx].item_id == itemId && c == this.itemAddCart[idx].is_jain) {
        //   f = parseInt(idx);
        // }
        if (value == 1 && status == 0) {
          this.itemAddCart.splice(parseInt(idx), 1);
        }
        break;
      }
    }
    // if (f == 0) {
    //   tempArray[0]["isjain"] = c;
    //   tempArray[0]["product_quantity"] = 1;
    //   this.itemAddCart.push(tempArray[0]);
    // }
    if (this.itemAddCart.length > 0) {
      localStorage.setItem(this.cookieValue + "_items", JSON.stringify(this.itemAddCart));
    } else {
      localStorage.removeItem(this.cookieValue + "_items");
      localStorage.removeItem(this.cookieValue + "_placeOrder");
      localStorage.removeItem(this.cookieValue + "_orderDetail");
      localStorage.removeItem(this.cookieValue + "_discount");
    }
    this.sharedService.sendClickEvent();
    this.updateCartQty();
    this.updateCartQtyForNewArrival();
    this.updateCartQtyForTrendingItem();
    this.spinner.hide();
  }

  HomeBanner(locationId) {
    var obj = {
      "location_id": locationId
    };

    // this.spinner.show();
    this.menuCategoryService.getHomeBanner(obj).subscribe(response => {
      if (response.status == "ok") {
        this.homeBannerImage = response.payload.bannerimage;
        this.homeBannerCategoryId = response.payload.categoryunkid;
        this.homeBannerCategoryName = response.payload.categoryname;
        this.homeBannerItemId = response.payload.itemunkid;
        this.homeBannerItemName = response.payload.itemname;
        if(this.homeBannerImage !='' && this.homeBannerImage !=null)
        {
          $('#HomeBannerModal').modal('show');
        }
        
      } else {
        this.toastr.error(response.message);
      }
      // this.spinner.hide();
    }, (err: HttpErrorResponse) => {
      this.spinner.hide();
    });
  }

  getHomeBannerMenuItem(categoryId, categoryName, itemId) {
    if (categoryId != undefined && categoryId.length > 0) {
      this.spinner.show();
      let encryptedCategory = btoa(categoryId + "&#+&" + categoryName + "&#+&" + itemId);
      this.router.navigate(['/menucategory/' + encryptedCategory]);
      this.spinner.hide();
    }
  }
  getViewDetailsforweeks(itemDetail,i)
  {
     this.spinner.show();

    this.lblItemName = itemDetail.name;
    // this.itemDetailIsJain = itemDetail.is_jain;
    $('#ItemAddToCart1').modal('show');

    this.itemDetails = itemDetail;

    this.itemDetailPrice = this.itemDetails['price'];
    this.itemDetailName = this.itemDetails['name'];
    this.itemDetailDescription = this.itemDetails['description'];
    this.itemDetailImage = this.itemDetails['tiffinpackageimageurl'];

    this.itemUnits = itemDetail.units;
    // this.itemTypes = itemDetail.types;
    this.lblItemName = itemDetail.name;
    this.lblItemRate = itemDetail.price;
    this.itemDetailTypeImage = this.itemDetailImage;
    this.lblTotalAmount = this.itemDetailPrice;

    this.selectedIndex = i;
    this.spinner.hide();
  }
  
}
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ShareFeedBackService {

  constructor(private http: HttpClient) { }
  
  getFeedbackDetail(data) {
    return this.http.post<any>(`${environment.apiBaseUrl}/api/app/front/api/feedbackdetail/show?${environment.queryStringData}`, data);
  }

  saveFeedbackDetail(data) {
    return this.http.post<any>(`${environment.apiBaseUrl}/api/app/front/api/feedbackdetail/save?${environment.queryStringData}`, data);
  }
}
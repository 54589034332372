import { Component, OnInit, ViewChild, Renderer2, ElementRef } from '@angular/core';
import { NgForm, Validators } from '@angular/forms';
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Router, ActivatedRoute } from "@angular/router";
import { SharedService } from '../_services/sharedServices.service';
import { CookieService } from 'ngx-cookie-service';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService, NgxSpinnerComponent } from 'ngx-spinner';
import { CheckoutService } from './checkout.service';
import { environment } from 'src/environments/environment';
import { MenuCategoryService } from '../menu-category/menu-category.service';

declare var $: any;
declare function mymethod(): any;

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.css']
})
export class CheckoutComponent implements OnInit {
  @ViewChild('divSubTotal', { static: true }) divSubTotal: ElementRef;
  @ViewChild('divPlaceOrder', { static: true }) divPlaceOrder: ElementRef;
  @ViewChild('divJainDetail') divJainDetail: ElementRef;
  @ViewChild('divJainAddToCart') divJainAddToCart: ElementRef;
  @ViewChild('divFormRow') divFormRow: ElementRef;
  @ViewChild('ModifierForm') ModifierForm: NgForm;

  cookieValue: any;
  locationId: any;
  userLoginStatus: any;
  currencySymbol: any;

  itemAddCart: Array<any> = [];
  cartTotalAmount: any;
  grandTotalAmount: any;
  taxList: Array<any> = [];
  finalTaxArrray: Array<any> = [];
  promoCodeList: any;
  promoCodeTitle: any;
  discountAmount: any;
  discount: any;
  couponCount: any;

  locationData: any;
  isOrderAvailable: any;
  orderTime: any;

  menuCategoryList: Array<any> = [];
  menuList: Array<any> = [];
  lblCategoryName: string;

  itemInformations: Array<any> = [];
  itemDetailPrice: any;
  itemDetailName: any;
  itemDetailDescription: any;
  itemDetailImage: any;
  itemDetailIsJain: any;
  itemDetailIndex: any;
  itemDetailTypeImage: any;
  itemRadioModifierTotal: any = 0;

  temp_Type: any;
  temp_i: any;
  temp_j: any;

  lblItemName: string;
  itemDetails = {};
  itemUnits: Array<any> = [];
  itemTypes: Array<any> = [];
  lblItemRate: any;
  itemModifiers: Array<any> = [];
  itemTotal: any = 0;
  lblTotalAmount: any = 0;
  itemModifierTotal: any = 0;
  itemChildModifierTotal: any = 0;

  temp_categoryId: any;
  temp_categoryName: any;
  isPriceWithTax: boolean;
  lblItemRateWithTax: any;
  emp_login: string | number;

  constructor(private sharedService: SharedService, private renderer: Renderer2, private service: CheckoutService, private cookieService: CookieService, private toastr: ToastrService,
    private router: Router, private spinner: NgxSpinnerService, private menuCategoryService: MenuCategoryService, private rout: ActivatedRoute) { }

  ngOnInit(): void {
    this.currencySymbol = environment.currencySymbol;
    this.divSubTotal.nativeElement.innerHTML = null;
    this.userLoginStatus = false;
    this.couponCount = 0;

    this.cookieValue = this.cookieService.get("location");

    if (this.cookieValue) {
      this.locationId = this.cookieValue;
      this.isPriceWithTax = this.cookieService.get("display_price_inc_tax") == "1" ? true : false;

      this.getLocationDetail(this.locationId);
    } else {
      this.router.navigate(['/menucategory']);
    }
  }

  getLocationDetail(locationId) {
    var obj = {
      "location_id": locationId
    };

    this.spinner.show();
    this.menuList = [];

    this.service.getLocationDetail(obj).subscribe(response => {
      if (response.status == "ok") {
        this.locationData = response.payload;
        this.isOrderAvailable = this.locationData.is_ordering;
        this.orderTime = "between " + this.locationData.restaurant_opening_time + " to " + this.locationData.restaurant_closing_time;
        this.cookieService.set("delivery_charge_applyon", response.payload.delivery_charge_applyon);
        this.cookieService.set("internet_charge_applyon", response.payload.internet_charge_applyon);
        this.cookieService.set("packing_charge_applyon", response.payload.packing_charge_applyon);
        this.cookieService.set("service_charge_applyon", response.payload.service_charge_applyon);
        if (response.payload.packingchargepostingtype)
          this.cookieService.set("packing_charge_posting_type", "0");
        else
          this.cookieService.set("packing_charge_posting_type", response.payload.packingchargepostingtype);

        if (this.locationData.packingcharge) {
          this.cookieService.set("packingcharge", this.locationData.packingcharge);
        }

        if (this.locationData.packingchargetype) {
          this.cookieService.set("packingchargetype", this.locationData.packingchargetype);
        }

        if (this.locationData.packinglabel) {
          this.cookieService.set("packinglabel", this.locationData.packinglabel);
        }

        // if (response.payload.servicecharge) {
        //   this.cookieService.set("servicecharge", response.payload.servicecharge);
        // } else {
        //   this.cookieService.set("servicecharge", "0");
        // }
        // if (response.payload.servicechargetype) {
        //   this.cookieService.set("servicechargetype", response.payload.servicechargetype);
        // } else {
        //   this.cookieService.set("servicechargetype", "0");
        // }

        // if (response.payload.servicechargelabel) {
        //   this.cookieService.set("servicechargelabel", response.payload.servicechargelabel);
        // } else {
        //   this.cookieService.set("servicechargelabel", "0");
        // }
        // if (response.payload.servicechargepostingtype == undefined)
        //   this.cookieService.set("service_charge_posting_type", "0");
        // else
        //   this.cookieService.set("service_charge_posting_type", response.payload.servicechargepostingtype);

        // if (response.payload.internetcharge) {
        //   this.cookieService.set("internetcharge", response.payload.internetcharge);
        // } else {
        //   this.cookieService.set("internetcharge", "0");
        // }
        // if (response.payload.internetchargetype) {
        //   this.cookieService.set("internetchargetype", response.payload.internetchargetype);
        // } else {
        //   this.cookieService.set("internetchargetype", "0");
        // }

        // if (response.payload.internetchargelabel) {
        //   this.cookieService.set("internetchargelabel", response.payload.internetchargelabel);
        // } else {
        //   this.cookieService.set("internetchargelabel", "0");
        // }
        // if (response.payload.internetchargepostingtype == undefined)
        //   this.cookieService.set("internetchargepostingtype", "0");
        // else
        //   this.cookieService.set("internetchargepostingtype", response.payload.internetchargepostingtype);

        this.cookieValue = this.cookieService.get("user");
        if (this.cookieValue) {
          this.userLoginStatus = true;

          document.getElementById("divCouponEnable").style.display = "block";
          document.getElementById("divCouponDisable").style.display = "none";
        } else {
          document.getElementById("divCouponEnable").style.display = "none";
          document.getElementById("divCouponDisable").style.display = "block";
        }

        if (localStorage.getItem(this.locationId + "_discount")) {
          this.discount = JSON.parse(localStorage.getItem(this.locationId + "_discount"));

          this.discountAmount = this.discount.amount;
          this.promoCodeTitle = this.discount.title;

          document.getElementById("divCouponRemove").style.display = "block";
          document.getElementById("divCouponEnable").style.display = "none";
        } else {
          this.discountAmount = 0;
          document.getElementById("divCouponRemove").style.display = "none";
        }

        this.cartTotalAmount = 0;
        this.grandTotalAmount = 0;

        if (localStorage.getItem(this.locationId + "_items")) {
          this.getCartItem(JSON.parse(localStorage.getItem(this.locationId + "_items")));
        } else {
          this.router.navigate(['/menucategory']);
        }

        this.getMenuCategory(locationId);
      } else {
        this.toastr.error(response.message);
      }
      this.spinner.hide();
    }, (err: HttpErrorResponse) => {
      this.spinner.hide();
    });
  }

  getCartItem(cartItem) {
    this.spinner.show();
    this.itemAddCart = [];
    this.itemAddCart = cartItem;

    var totalCart = 0;
    for (var i = 0; i < this.itemAddCart.length; i++) {
      var perItemTotal = 0;

      perItemTotal = parseFloat(this.itemAddCart[i].product_quantity) * parseFloat(this.itemAddCart[i].price);
      totalCart += parseFloat(this.itemAddCart[i].product_quantity) * parseFloat(this.itemAddCart[i].price);

      for (var m = 0; m < this.itemAddCart[i].units.length; m++) {
        if (this.itemAddCart[i].units[m].modifiers) {
          for (var n = 0; n < this.itemAddCart[i].units[m].modifiers.length; n++) {
            if (this.itemAddCart[i].units[m].modifiers[n].items) {
              for (var p = 0; p < this.itemAddCart[i].units[m].modifiers[n].items.length; p++) {
                if (parseFloat(this.itemAddCart[i].units[m].modifiers[n].items[p].quantity)) {
                  totalCart += (parseFloat(this.itemAddCart[i].units[m].modifiers[n].items[p].quantity) * parseFloat(this.itemAddCart[i].units[m].modifiers[n].items[p].price)) * parseFloat(this.itemAddCart[i].product_quantity);
                  perItemTotal += (parseFloat(this.itemAddCart[i].units[m].modifiers[n].items[p].quantity) * parseFloat(this.itemAddCart[i].units[m].modifiers[n].items[p].price)) * parseFloat(this.itemAddCart[i].product_quantity);
                }
              }
            } else {
              if (parseFloat(this.itemAddCart[i].units[m].modifiers[n].quantity)) {
                totalCart += (parseFloat(this.itemAddCart[i].units[m].modifiers[n].quantity) * parseFloat(this.itemAddCart[i].units[m].modifiers[n].price)) * parseFloat(this.itemAddCart[i].product_quantity);
                perItemTotal += (parseFloat(this.itemAddCart[i].units[m].modifiers[n].quantity) * parseFloat(this.itemAddCart[i].units[m].modifiers[n].price)) * parseFloat(this.itemAddCart[i].product_quantity);
              }
            }
          }
        }
      }

      this.itemAddCart[i].subTotal = perItemTotal.toFixed(2);
    }

    this.cartTotalAmount = totalCart.toFixed(2);
    this.grandTotalAmount = totalCart.toFixed(2);

    this.setSubTotalUI();
    if (this.isOrderAvailable == 1) {
      this.setPlaceOrderUI();
    }
    this.spinner.hide();
  }

  addMinusItem(status, itemId, i) {
    this.spinner.show();
    this.divSubTotal.nativeElement.innerHTML = null;
    // for (var idx in this.itemAddCart) {
    //   if (this.itemAddCart[idx].item_id == itemId) {
    var value = parseInt($('#' + i).val());
    if (status == 0 && value > 1) {
      this.itemAddCart[i].product_quantity = parseFloat(this.itemAddCart[i].product_quantity) - 1;
    }

    if (status == 1) {
      this.itemAddCart[i].product_quantity = parseFloat(this.itemAddCart[i].product_quantity) + 1;
    }

    if (value == 1 && status == 0) {
      this.itemAddCart.splice(parseInt(i), 1);
    }
    //break;
    //   }
    // }

    if (this.itemAddCart.length > 0) {
      localStorage.setItem(this.locationId + "_items", JSON.stringify(this.itemAddCart));
    } else {
      localStorage.removeItem(this.locationId + "_items");
      localStorage.removeItem(this.locationId + "_placeOrder");
      localStorage.removeItem(this.locationId + "_orderDetail");
      localStorage.removeItem(this.locationId + "_discount");
      localStorage.removeItem(this.locationId + "_taxData");
    }

    this.sharedService.sendClickEvent();
    
    if (localStorage.getItem(this.locationId + "_items") != undefined && localStorage.getItem(this.locationId + "_items") != null
      && localStorage.getItem(this.locationId + "_items").length != 0) {
      this.removeCoupon();
    }
    // this.ngOnInit();
    window.location.reload(true);
    this.spinner.hide();
  }

  setSubTotalUI() {
    this.spinner.show();
    this.divSubTotal.nativeElement.innerHTML = null;
    this.grandTotalAmount = this.cartTotalAmount;

    const pSubTotal: HTMLParagraphElement = this.renderer.createElement('p');
    pSubTotal.setAttribute("class", "mb-1");
    pSubTotal.innerHTML = "Sub Total ";
    this.renderer.appendChild(this.divSubTotal.nativeElement, pSubTotal);

    const spanSubTotal: HTMLParagraphElement = this.renderer.createElement('span');
    spanSubTotal.setAttribute("class", "float-right text-dark");
    spanSubTotal.innerHTML = this.currencySymbol + " " + parseFloat(this.cartTotalAmount).toFixed(2);
    this.renderer.appendChild(pSubTotal, spanSubTotal);

    this.setTaxesUI();
    this.spinner.hide();
  }

  setTaxesUI() {
    this.spinner.show();
    var masterTax = [];
    var taxArray = [];
    var objTax = {
      "itemId": "0",
      "taxId": "0",
      "taxDisplayName": "",
      "taxAmount": "0"
    };

    for (var idx in this.itemAddCart) {
      //  // For Main Item
      if (this.itemAddCart[idx].taxes) {
        //  // For Before Tax
        for (var n in this.itemAddCart[idx].taxes) {

          objTax = {
            "itemId": "0",
            "taxId": "0",
            "taxDisplayName": "",
            "taxAmount": "0"
          };

          if (this.itemAddCart[idx].taxes[n].after_tax == "") {
            if (this.itemAddCart[idx].taxes[n].is_gst == 1) {
              var dividedGST = (parseFloat(this.itemAddCart[idx].taxes[n].amount) / 2).toFixed(2);

              if (this.itemAddCart[idx].taxes[n].pytype == "%") {
                var GSTAmount = ((parseFloat(this.itemAddCart[idx].price) * parseFloat(dividedGST)) / 100).toFixed(2);

                objTax.itemId = this.itemAddCart[idx].item_id;
                objTax.taxId = this.itemAddCart[idx].taxes[n].taxunkid;
                objTax.taxDisplayName = this.itemAddCart[idx].taxes[n].tax + " (SGST)";
                objTax.taxAmount = (parseFloat(this.itemAddCart[idx].product_quantity) * parseFloat(GSTAmount)).toFixed(2);
                taxArray.push(objTax);

                objTax = {
                  "itemId": "0",
                  "taxId": "0",
                  "taxDisplayName": "",
                  "taxAmount": "0"
                };
                objTax.itemId = this.itemAddCart[idx].item_id;
                objTax.taxId = this.itemAddCart[idx].taxes[n].taxunkid;
                objTax.taxDisplayName = this.itemAddCart[idx].taxes[n].tax + " (CGST)";
                objTax.taxAmount = (parseFloat(this.itemAddCart[idx].product_quantity) * parseFloat(GSTAmount)).toFixed(2);
                taxArray.push(objTax);

                masterTax[this.itemAddCart[idx].taxes[n].taxunkid] = (parseFloat(this.itemAddCart[idx].price) + (parseFloat(GSTAmount) * 2)) * parseFloat(this.itemAddCart[idx].product_quantity);
              } else {
                objTax.itemId = this.itemAddCart[idx].item_id;
                objTax.taxId = this.itemAddCart[idx].taxes[n].taxunkid;
                objTax.taxDisplayName = this.itemAddCart[idx].taxes[n].tax + " (SGST)";
                objTax.taxAmount = (parseFloat(this.itemAddCart[idx].product_quantity) * parseFloat(dividedGST)).toFixed(2);
                taxArray.push(objTax);

                objTax = {
                  "itemId": "0",
                  "taxId": "0",
                  "taxDisplayName": "",
                  "taxAmount": "0"
                };
                objTax.itemId = this.itemAddCart[idx].item_id;
                objTax.taxId = this.itemAddCart[idx].taxes[n].taxunkid;
                objTax.taxDisplayName = this.itemAddCart[idx].taxes[n].tax + " (CGST)";
                objTax.taxAmount = (parseFloat(this.itemAddCart[idx].product_quantity) * parseFloat(dividedGST)).toFixed(2);
                taxArray.push(objTax);

                masterTax[this.itemAddCart[idx].taxes[n].taxunkid] = (parseFloat(this.itemAddCart[idx].price) + (parseFloat(dividedGST) * 2)) * parseFloat(this.itemAddCart[idx].product_quantity);
              }
            } else {
              if (this.itemAddCart[idx].taxes[n].pytype == "%") {
                var taxAmount = ((parseFloat(this.itemAddCart[idx].price) * parseFloat(this.itemAddCart[idx].taxes[n].amount)) / 100).toFixed(2);

                objTax.itemId = this.itemAddCart[idx].item_id;
                objTax.taxId = this.itemAddCart[idx].taxes[n].taxunkid;
                objTax.taxDisplayName = this.itemAddCart[idx].taxes[n].tax;
                objTax.taxAmount = (parseFloat(this.itemAddCart[idx].product_quantity) * parseFloat(taxAmount)).toFixed(2);
                taxArray.push(objTax);

                masterTax[this.itemAddCart[idx].taxes[n].taxunkid] = (parseFloat(this.itemAddCart[idx].price) + parseFloat(taxAmount)) * parseFloat(this.itemAddCart[idx].product_quantity);
              } else {
                objTax.itemId = this.itemAddCart[idx].item_id;
                objTax.taxId = this.itemAddCart[idx].taxes[n].taxunkid;
                objTax.taxDisplayName = this.itemAddCart[idx].taxes[n].tax;
                objTax.taxAmount = (parseFloat(this.itemAddCart[idx].product_quantity) * parseFloat(this.itemAddCart[idx].taxes[n].amount)).toFixed(2);
                taxArray.push(objTax);

                masterTax[this.itemAddCart[idx].taxes[n].taxunkid] = (parseFloat(this.itemAddCart[idx].price) + parseFloat(this.itemAddCart[idx].taxes[n].amount)) * parseFloat(this.itemAddCart[idx].product_quantity);
              }
            }
          }
        }

        //  // For After Tax
        for (var n in this.itemAddCart[idx].taxes) {
          var afterTaxAmount = 0;
          if (masterTax[this.itemAddCart[idx].taxes[n].after_tax]) {
            afterTaxAmount = masterTax[this.itemAddCart[idx].taxes[n].after_tax];
          } else {
            afterTaxAmount = (this.itemAddCart[idx].price * parseFloat(this.itemAddCart[idx].product_quantity));
          }

          objTax = {
            "itemId": "0",
            "taxId": "0",
            "taxDisplayName": "",
            "taxAmount": "0"
          };

          if (this.itemAddCart[idx].taxes[n].after_tax != "") {
            if (this.itemAddCart[idx].taxes[n].is_gst == 1) {
              var dividedGST = (parseFloat(this.itemAddCart[idx].taxes[n].amount) / 2).toFixed(2);

              if (this.itemAddCart[idx].taxes[n].pytype == "%") {
                var GSTAmount = ((afterTaxAmount * parseFloat(dividedGST)) / 100).toFixed(2);

                objTax.itemId = this.itemAddCart[idx].item_id;
                objTax.taxId = this.itemAddCart[idx].taxes[n].taxunkid;
                objTax.taxDisplayName = this.itemAddCart[idx].taxes[n].tax + " (SGST)";
                objTax.taxAmount = parseFloat(GSTAmount).toFixed(2);
                taxArray.push(objTax);

                objTax = {
                  "itemId": "0",
                  "taxId": "0",
                  "taxDisplayName": "",
                  "taxAmount": "0"
                };
                objTax.itemId = this.itemAddCart[idx].item_id;
                objTax.taxId = this.itemAddCart[idx].taxes[n].taxunkid;
                objTax.taxDisplayName = this.itemAddCart[idx].taxes[n].tax + " (CGST)";
                objTax.taxAmount = parseFloat(GSTAmount).toFixed(2);
                taxArray.push(objTax);

                masterTax[this.itemAddCart[idx].taxes[n].taxunkid] = afterTaxAmount + (parseFloat(GSTAmount) * 2);
              } else {
                objTax.itemId = this.itemAddCart[idx].item_id;
                objTax.taxId = this.itemAddCart[idx].taxes[n].taxunkid;
                objTax.taxDisplayName = this.itemAddCart[idx].taxes[n].tax + " (SGST)";
                objTax.taxAmount = parseFloat(dividedGST).toFixed(2);
                taxArray.push(objTax);

                objTax = {
                  "itemId": "0",
                  "taxId": "0",
                  "taxDisplayName": "",
                  "taxAmount": "0"
                };
                objTax.itemId = this.itemAddCart[idx].item_id;
                objTax.taxId = this.itemAddCart[idx].taxes[n].taxunkid;
                objTax.taxDisplayName = this.itemAddCart[idx].taxes[n].tax + " (CGST)";
                objTax.taxAmount = parseFloat(dividedGST).toFixed(2);
                taxArray.push(objTax);

                masterTax[this.itemAddCart[idx].taxes[n].taxunkid] = afterTaxAmount + (parseFloat(dividedGST) * 2);
              }
            } else {
              if (this.itemAddCart[idx].taxes[n].pytype == "%") {
                var taxAmount = ((afterTaxAmount * parseFloat(this.itemAddCart[idx].taxes[n].amount)) / 100).toFixed(2);

                objTax.itemId = this.itemAddCart[idx].item_id;
                objTax.taxId = this.itemAddCart[idx].taxes[n].taxunkid;
                objTax.taxDisplayName = this.itemAddCart[idx].taxes[n].tax;
                objTax.taxAmount = parseFloat(taxAmount).toFixed(2);
                taxArray.push(objTax);

                masterTax[this.itemAddCart[idx].taxes[n].taxunkid] = afterTaxAmount + parseFloat(taxAmount);
              } else {
                objTax.itemId = this.itemAddCart[idx].item_id;
                objTax.taxId = this.itemAddCart[idx].taxes[n].taxunkid;
                objTax.taxDisplayName = this.itemAddCart[idx].taxes[n].tax;
                objTax.taxAmount = parseFloat(this.itemAddCart[idx].taxes[n].amount).toFixed(2);
                taxArray.push(objTax);

                masterTax[this.itemAddCart[idx].taxes[n].taxunkid] = afterTaxAmount + parseFloat(this.itemAddCart[idx].taxes[n].amount);
              }
            }
          }
        }
      }

      //  // For Modifier
      if (this.itemAddCart[idx].units) {
        for (var p in this.itemAddCart[idx].units) {
          if (this.itemAddCart[idx].units[p].unit_id == this.itemAddCart[idx].default_unit_id) {
            if (this.itemAddCart[idx].units[p].modifiers) {
              for (var m in this.itemAddCart[idx].units[p].modifiers) {
                // For Parent Modifier
                if (this.itemAddCart[idx].units[p].modifiers[m].quantity) {
                  if (this.itemAddCart[idx].units[p].modifiers[m].taxes) {
                    //  // For Parent Modifier Before Tax
                    for (var n in this.itemAddCart[idx].units[p].modifiers[m].taxes) {
                      objTax = {
                        "itemId": "0",
                        "taxId": "0",
                        "taxDisplayName": "",
                        "taxAmount": "0"
                      };

                      if (this.itemAddCart[idx].units[p].modifiers[m].taxes[n].after_tax == "") {
                        if (this.itemAddCart[idx].units[p].modifiers[m].taxes[n].is_gst == 1) {
                          var dividedGST = ((parseFloat(this.itemAddCart[idx].units[p].modifiers[m].taxes[n].amount) * (parseFloat(this.itemAddCart[idx].units[p].modifiers[m].quantity))) / 2).toFixed(2);

                          if (this.itemAddCart[idx].units[p].modifiers[m].taxes[n].pytype == "%") {
                            var GSTAmount = ((parseFloat(this.itemAddCart[idx].units[p].modifiers[m].price) * parseFloat(dividedGST)) / 100).toFixed(2);

                            objTax.itemId = this.itemAddCart[idx].units[p].modifiers[m].modifier_id;
                            objTax.taxId = this.itemAddCart[idx].units[p].modifiers[m].taxes[n].taxunkid;
                            objTax.taxDisplayName = this.itemAddCart[idx].units[p].modifiers[m].taxes[n].tax + " (SGST)";
                            objTax.taxAmount = (parseFloat(this.itemAddCart[idx].product_quantity) * parseFloat(GSTAmount)).toFixed(2);
                            taxArray.push(objTax);

                            objTax = {
                              "itemId": "0",
                              "taxId": "0",
                              "taxDisplayName": "",
                              "taxAmount": "0"
                            };
                            objTax.itemId = this.itemAddCart[idx].units[p].modifiers[m].modifier_id;
                            objTax.taxId = this.itemAddCart[idx].units[p].modifiers[m].taxes[n].taxunkid;
                            objTax.taxDisplayName = this.itemAddCart[idx].units[p].modifiers[m].taxes[n].tax + " (CGST)";
                            objTax.taxAmount = (parseFloat(this.itemAddCart[idx].product_quantity) * parseFloat(GSTAmount)).toFixed(2);
                            taxArray.push(objTax);

                            masterTax[this.itemAddCart[idx].units[p].modifiers[m].taxes[n].taxunkid] = (parseFloat(this.itemAddCart[idx].units[p].modifiers[m].price) * parseFloat(this.itemAddCart[idx].units[p].modifiers[m].quantity) + (parseFloat(GSTAmount) * 2)) * parseFloat(this.itemAddCart[idx].product_quantity);
                          } else {
                            objTax.itemId = this.itemAddCart[idx].units[p].modifiers[m].modifier_id;
                            objTax.taxId = this.itemAddCart[idx].units[p].modifiers[m].taxes[n].taxunkid;
                            objTax.taxDisplayName = this.itemAddCart[idx].units[p].modifiers[m].taxes[n].tax + " (SGST)";
                            objTax.taxAmount = (parseFloat(this.itemAddCart[idx].product_quantity) * parseFloat(dividedGST)).toFixed(2);
                            taxArray.push(objTax);

                            objTax = {
                              "itemId": "0",
                              "taxId": "0",
                              "taxDisplayName": "",
                              "taxAmount": "0"
                            };
                            objTax.itemId = this.itemAddCart[idx].units[p].modifiers[m].modifier_id;
                            objTax.taxId = this.itemAddCart[idx].units[p].modifiers[m].taxes[n].taxunkid;
                            objTax.taxDisplayName = this.itemAddCart[idx].units[p].modifiers[m].taxes[n].tax + " (CGST)";
                            objTax.taxAmount = (parseFloat(this.itemAddCart[idx].product_quantity) * parseFloat(dividedGST)).toFixed(2);
                            taxArray.push(objTax);

                            masterTax[this.itemAddCart[idx].units[p].modifiers[m].taxes[n].taxunkid] = (parseFloat(this.itemAddCart[idx].units[p].modifiers[m].price) * parseFloat(this.itemAddCart[idx].units[p].modifiers[m].quantity) + (parseFloat(dividedGST) * 2)) * parseFloat(this.itemAddCart[idx].product_quantity);
                          }
                        } else {
                          if (this.itemAddCart[idx].units[p].modifiers[m].taxes[n].pytype == "%") {
                            var taxAmount = ((parseFloat(this.itemAddCart[idx].units[p].modifiers[m].price) * parseFloat(this.itemAddCart[idx].units[p].modifiers[m].taxes[n].amount)) * parseFloat(this.itemAddCart[idx].units[p].modifiers[m].quantity) / 100).toFixed(2);

                            objTax.itemId = this.itemAddCart[idx].units[p].modifiers[m].modifier_id;
                            objTax.taxId = this.itemAddCart[idx].units[p].modifiers[m].taxes[n].taxunkid;
                            objTax.taxDisplayName = this.itemAddCart[idx].units[p].modifiers[m].taxes[n].tax;
                            objTax.taxAmount = (parseFloat(this.itemAddCart[idx].product_quantity) * parseFloat(taxAmount)).toFixed(2);
                            taxArray.push(objTax);

                            masterTax[this.itemAddCart[idx].units[p].modifiers[m].taxes[n].taxunkid] = ((parseFloat(this.itemAddCart[idx].units[p].modifiers[m].price) * parseFloat(this.itemAddCart[idx].units[p].modifiers[m].quantity)) + parseFloat(taxAmount) * parseFloat(this.itemAddCart[idx].product_quantity));
                          } else {
                            objTax.itemId = this.itemAddCart[idx].units[p].modifiers[m].modifier_id;
                            objTax.taxId = this.itemAddCart[idx].units[p].modifiers[m].taxes[n].taxunkid;
                            objTax.taxDisplayName = this.itemAddCart[idx].units[p].modifiers[m].taxes[n].tax;
                            objTax.taxAmount = (parseFloat(this.itemAddCart[idx].product_quantity) * parseFloat(this.itemAddCart[idx].units[p].modifiers[m].taxes[n].amount)).toFixed(2);
                            taxArray.push(objTax);

                            masterTax[this.itemAddCart[idx].units[p].modifiers[m].taxes[n].taxunkid] = ((parseFloat(this.itemAddCart[idx].units[p].modifiers[m].price) * parseFloat(this.itemAddCart[idx].units[p].modifiers[m].quantity)) + parseFloat(this.itemAddCart[idx].units[p].modifiers[m].taxes[n].amount) * parseFloat(this.itemAddCart[idx].product_quantity));
                          }
                        }
                      }
                    }

                    //  // For Parent Modifier After Tax
                    for (var n in this.itemAddCart[idx].units[p].modifiers[m].taxes) {
                      var afterTaxAmount = 0;
                      if (masterTax[this.itemAddCart[idx].units[p].modifiers[m].taxes[n].after_tax]) {
                        afterTaxAmount = (masterTax[this.itemAddCart[idx].units[p].modifiers[m].taxes[n].after_tax]);
                      } else {
                        afterTaxAmount = (this.itemAddCart[idx].units[p].modifiers[m].price) * parseFloat(this.itemAddCart[idx].product_quantity);
                      }

                      objTax = {
                        "itemId": "0",
                        "taxId": "0",
                        "taxDisplayName": "",
                        "taxAmount": "0"
                      };

                      if (this.itemAddCart[idx].units[p].modifiers[m].taxes[n].after_tax != "") {
                        if (this.itemAddCart[idx].units[p].modifiers[m].taxes[n].is_gst == 1) {
                          var dividedGST = (parseFloat(this.itemAddCart[idx].units[p].modifiers[m].taxes[n].amount) / 2).toFixed(2);

                          if (this.itemAddCart[idx].units[p].modifiers[m].taxes[n].pytype == "%") {
                            var GSTAmount = ((afterTaxAmount * parseFloat(dividedGST)) / 100).toFixed(2);

                            objTax.itemId = this.itemAddCart[idx].units[p].modifiers[m].modifier_id;
                            objTax.taxId = this.itemAddCart[idx].units[p].modifiers[m].taxes[n].taxunkid;
                            objTax.taxDisplayName = this.itemAddCart[idx].units[p].modifiers[m].taxes[n].tax + " (SGST)";
                            objTax.taxAmount = parseFloat(GSTAmount).toFixed(2);
                            taxArray.push(objTax);

                            objTax = {
                              "itemId": "0",
                              "taxId": "0",
                              "taxDisplayName": "",
                              "taxAmount": "0"
                            };
                            objTax.itemId = this.itemAddCart[idx].units[p].modifiers[m].modifier_id;
                            objTax.taxId = this.itemAddCart[idx].taxes[n].units[p].modifiers[m].taxunkid;
                            objTax.taxDisplayName = this.itemAddCart[idx].units[p].modifiers[m].taxes[n].tax + " (CGST)";
                            objTax.taxAmount = parseFloat(GSTAmount).toFixed(2);
                            taxArray.push(objTax);

                            masterTax[this.itemAddCart[idx].units[p].modifiers[m].taxes[n].taxunkid] = afterTaxAmount + (parseFloat(GSTAmount) * 2);
                          } else {
                            objTax.itemId = this.itemAddCart[idx].units[p].modifiers[m].modifier_id;
                            objTax.taxId = this.itemAddCart[idx].units[p].modifiers[m].taxes[n].taxunkid;
                            objTax.taxDisplayName = this.itemAddCart[idx].units[p].modifiers[m].taxes[n].tax + " (SGST)";
                            objTax.taxAmount = parseFloat(dividedGST).toFixed(2);
                            taxArray.push(objTax);

                            objTax = {
                              "itemId": "0",
                              "taxId": "0",
                              "taxDisplayName": "",
                              "taxAmount": "0"
                            };
                            objTax.itemId = this.itemAddCart[idx].units[p].modifiers[m].modifier_id;
                            objTax.taxId = this.itemAddCart[idx].units[p].modifiers[m].taxes[n].taxunkid;
                            objTax.taxDisplayName = this.itemAddCart[idx].units[p].modifiers[m].taxes[n].tax + " (CGST)";
                            objTax.taxAmount = parseFloat(dividedGST).toFixed(2);
                            taxArray.push(objTax);

                            masterTax[this.itemAddCart[idx].units[p].modifiers[m].taxes[n].taxunkid] = afterTaxAmount + (parseFloat(dividedGST) * 2);
                          }
                        } else {
                          if (this.itemAddCart[idx].units[p].modifiers[m].taxes[n].pytype == "%") {
                            var taxAmount = (afterTaxAmount * parseFloat(this.itemAddCart[idx].units[p].modifiers[m].taxes[n].amount) / 100).toFixed(2);


                            objTax.itemId = this.itemAddCart[idx].units[p].modifiers[m].modifier_id;
                            objTax.taxId = this.itemAddCart[idx].units[p].modifiers[m].taxes[n].taxunkid;
                            objTax.taxDisplayName = this.itemAddCart[idx].units[p].modifiers[m].taxes[n].tax;
                            objTax.taxAmount = parseFloat(taxAmount).toFixed(2);
                            taxArray.push(objTax);

                            masterTax[this.itemAddCart[idx].units[p].modifiers[m].taxes[n].taxunkid] = afterTaxAmount + parseFloat(taxAmount);
                          } else {
                            objTax.itemId = this.itemAddCart[idx].units[p].modifiers[m].modifier_id;
                            objTax.taxId = this.itemAddCart[idx].units[p].modifiers[m].taxes[n].taxunkid;
                            objTax.taxDisplayName = this.itemAddCart[idx].units[p].modifiers[m].taxes[n].tax;
                            objTax.taxAmount = parseFloat(this.itemAddCart[idx].units[p].modifiers[m].taxes[n].amount).toFixed(2);
                            taxArray.push(objTax);

                            masterTax[this.itemAddCart[idx].units[p].modifiers[m].taxes[n].taxunkid] = afterTaxAmount + parseFloat(this.itemAddCart[idx].units[p].modifiers[m].taxes[n].amount);
                          }
                        }
                      }
                    }
                  }
                }

                // For Child Modifier
                if (this.itemAddCart[idx].units[p].modifiers[m].items) {
                  for (var r in this.itemAddCart[idx].units[p].modifiers[m].items) {
                    if (this.itemAddCart[idx].units[p].modifiers[m].items[r].quantity) {
                      //  // For Child Modifier Before Tax
                      for (var n in this.itemAddCart[idx].units[p].modifiers[m].items[r].taxes) {
                        objTax = {
                          "itemId": "0",
                          "taxId": "0",
                          "taxDisplayName": "",
                          "taxAmount": "0"
                        };

                        if (this.itemAddCart[idx].units[p].modifiers[m].items[r].taxes[n].after_tax == "") {
                          if (this.itemAddCart[idx].units[p].modifiers[m].items[r].taxes[n].is_gst == 1) {
                            var dividedGST = ((parseFloat(this.itemAddCart[idx].units[p].modifiers[m].items[r].taxes[n].amount) * (parseFloat(this.itemAddCart[idx].units[p].modifiers[m].items[r].quantity))) / 2).toFixed(2);

                            if (this.itemAddCart[idx].units[p].modifiers[m].items[r].taxes[n].pytype == "%") {
                              var GSTAmount = ((parseFloat(this.itemAddCart[idx].units[p].modifiers[m].items[r].price) * parseFloat(dividedGST)) / 100).toFixed(2);

                              objTax.itemId = this.itemAddCart[idx].units[p].modifiers[m].items[r].modifieritem_id;
                              objTax.taxId = this.itemAddCart[idx].units[p].modifiers[m].items[r].taxes[n].taxunkid;
                              objTax.taxDisplayName = this.itemAddCart[idx].units[p].modifiers[m].items[r].taxes[n].tax + " (SGST)";
                              objTax.taxAmount = (parseFloat(this.itemAddCart[idx].product_quantity) * parseFloat(GSTAmount)).toFixed(2);
                              taxArray.push(objTax);

                              objTax = {
                                "itemId": "0",
                                "taxId": "0",
                                "taxDisplayName": "",
                                "taxAmount": "0"
                              };
                              objTax.itemId = this.itemAddCart[idx].units[p].modifiers[m].items[r].modifieritem_id;
                              objTax.taxId = this.itemAddCart[idx].units[p].modifiers[m].items[r].taxes[n].taxunkid;
                              objTax.taxDisplayName = this.itemAddCart[idx].units[p].modifiers[m].items[r].taxes[n].tax + " (CGST)";
                              objTax.taxAmount = (parseFloat(this.itemAddCart[idx].product_quantity) * parseFloat(GSTAmount)).toFixed(2);
                              taxArray.push(objTax);

                              masterTax[this.itemAddCart[idx].units[p].modifiers[m].items[r].taxes[n].taxunkid] = (parseFloat(this.itemAddCart[idx].units[p].modifiers[m].items[r].price) * parseFloat(this.itemAddCart[idx].units[p].modifiers[m].items[r].quantity) + (parseFloat(GSTAmount) * 2)) * parseFloat(this.itemAddCart[idx].product_quantity);
                            } else {
                              objTax.itemId = this.itemAddCart[idx].units[p].modifiers[m].items[r].modifieritem_id;
                              objTax.taxId = this.itemAddCart[idx].units[p].modifiers[m].items[r].taxes[n].taxunkid;
                              objTax.taxDisplayName = this.itemAddCart[idx].units[p].modifiers[m].items[r].taxes[n].tax + " (SGST)";
                              objTax.taxAmount = (parseFloat(this.itemAddCart[idx].product_quantity) * parseFloat(dividedGST)).toFixed(2);
                              taxArray.push(objTax);

                              objTax = {
                                "itemId": "0",
                                "taxId": "0",
                                "taxDisplayName": "",
                                "taxAmount": "0"
                              };
                              objTax.itemId = this.itemAddCart[idx].units[p].modifiers[m].items[r].modifieritem_id;
                              objTax.taxId = this.itemAddCart[idx].units[p].modifiers[m].items[r].taxes[n].taxunkid;
                              objTax.taxDisplayName = this.itemAddCart[idx].units[p].modifiers[m].items[r].taxes[n].tax + " (CGST)";
                              objTax.taxAmount = (parseFloat(this.itemAddCart[idx].product_quantity) * parseFloat(dividedGST)).toFixed(2);
                              taxArray.push(objTax);

                              masterTax[this.itemAddCart[idx].units[p].modifiers[m].items[r].taxes[n].taxunkid] = (parseFloat(this.itemAddCart[idx].units[p].modifiers[m].items[r].price) * parseFloat(this.itemAddCart[idx].units[p].modifiers[m].items[r].quantity) + (parseFloat(dividedGST) * 2)) * parseFloat(this.itemAddCart[idx].product_quantity);
                            }
                          } else {
                            if (this.itemAddCart[idx].units[p].modifiers[m].taxes[n].pytype == "%") {
                              var taxAmount = ((parseFloat(this.itemAddCart[idx].units[p].modifiers[m].items[r].price) * parseFloat(this.itemAddCart[idx].units[p].modifiers[m].items[r].taxes[n].amount)) * parseFloat(this.itemAddCart[idx].units[p].modifiers[m].items[r].quantity) / 100).toFixed(2);

                              objTax.itemId = this.itemAddCart[idx].units[p].modifiers[m].items[r].modifieritem_id;
                              objTax.taxId = this.itemAddCart[idx].units[p].modifiers[m].items[r].taxes[n].taxunkid;
                              objTax.taxDisplayName = this.itemAddCart[idx].units[p].modifiers[m].items[r].taxes[n].tax;
                              objTax.taxAmount = (parseFloat(this.itemAddCart[idx].product_quantity) * parseFloat(taxAmount)).toFixed(2);
                              taxArray.push(objTax);

                              masterTax[this.itemAddCart[idx].units[p].modifiers[m].items[r].taxes[n].taxunkid] = (parseFloat(this.itemAddCart[idx].units[p].modifiers[m].items[r].price) * parseFloat(this.itemAddCart[idx].units[p].modifiers[m].items[r].quantity) + parseFloat(taxAmount)) * parseFloat(this.itemAddCart[idx].product_quantity);
                            } else {
                              objTax.itemId = this.itemAddCart[idx].units[p].modifiers[m].items[r].modifieritem_id;
                              objTax.taxId = this.itemAddCart[idx].units[p].modifiers[m].items[r].taxes[n].taxunkid;
                              objTax.taxDisplayName = this.itemAddCart[idx].units[p].modifiers[m].items[r].taxes[n].tax;
                              objTax.taxAmount = (parseFloat(this.itemAddCart[idx].product_quantity) * parseFloat(this.itemAddCart[idx].units[p].modifiers[m].items[r].taxes[n].amount)).toFixed(2);
                              taxArray.push(objTax);

                              masterTax[this.itemAddCart[idx].units[p].modifiers[m].items[r].taxes[n].taxunkid] = (parseFloat(this.itemAddCart[idx].units[p].modifiers[m].items[r].price) * parseFloat(this.itemAddCart[idx].units[p].modifiers[m].items[r].quantity) + parseFloat(this.itemAddCart[idx].units[p].modifiers[m].items[r].taxes[n].amount)) * parseFloat(this.itemAddCart[idx].product_quantity);
                            }
                          }
                        }
                      }

                      //  // For Child Modifier After Tax
                      for (var n in this.itemAddCart[idx].units[p].modifiers[m].items[r].taxes) {
                        var afterTaxAmount = 0;
                        if (masterTax[this.itemAddCart[idx].units[p].modifiers[m].items[r].taxes[n].after_tax]) {
                          afterTaxAmount = masterTax[this.itemAddCart[idx].units[p].modifiers[m].items[r].taxes[n].after_tax];
                        } else {
                          afterTaxAmount = (this.itemAddCart[idx].units[p].modifiers[m].items[r].price) * parseFloat(this.itemAddCart[idx].product_quantity);
                        }

                        objTax = {
                          "itemId": "0",
                          "taxId": "0",
                          "taxDisplayName": "",
                          "taxAmount": "0"
                        };

                        if (this.itemAddCart[idx].units[p].modifiers[m].items[r].taxes[n].after_tax != "") {
                          if (this.itemAddCart[idx].units[p].modifiers[m].items[r].taxes[n].is_gst == 1) {
                            var dividedGST = (parseFloat(this.itemAddCart[idx].units[p].modifiers[m].items[r].taxes[n].amount) / 2).toFixed(2);

                            if (this.itemAddCart[idx].units[p].modifiers[m].items[r].taxes[n].pytype == "%") {
                              var GSTAmount = ((afterTaxAmount * parseFloat(dividedGST)) / 100).toFixed(2);

                              objTax.itemId = this.itemAddCart[idx].units[p].modifiers[m].items[r].modifieritem_id;
                              objTax.taxId = this.itemAddCart[idx].units[p].modifiers[m].items[r].taxes[n].taxunkid;
                              objTax.taxDisplayName = this.itemAddCart[idx].units[p].modifiers[m].items[r].taxes[n].tax + " (SGST)";
                              objTax.taxAmount = parseFloat(GSTAmount).toFixed(2);
                              taxArray.push(objTax);

                              objTax = {
                                "itemId": "0",
                                "taxId": "0",
                                "taxDisplayName": "",
                                "taxAmount": "0"
                              };
                              objTax.itemId = this.itemAddCart[idx].units[p].modifiers[m].items[r].modifieritem_id;
                              objTax.taxId = this.itemAddCart[idx].taxes[n].units[p].modifiers[m].items[r].taxunkid;
                              objTax.taxDisplayName = this.itemAddCart[idx].units[p].modifiers[m].items[r].taxes[n].tax + " (CGST)";
                              objTax.taxAmount = parseFloat(GSTAmount).toFixed(2);
                              taxArray.push(objTax);

                              masterTax[this.itemAddCart[idx].units[p].modifiers[m].items[r].taxes[n].taxunkid] = afterTaxAmount + (parseFloat(GSTAmount) * 2);
                            } else {
                              objTax.itemId = this.itemAddCart[idx].units[p].modifiers[m].items[r].modifieritem_id;
                              objTax.taxId = this.itemAddCart[idx].units[p].modifiers[m].items[r].taxes[n].taxunkid;
                              objTax.taxDisplayName = this.itemAddCart[idx].units[p].modifiers[m].items[r].taxes[n].tax + " (SGST)";
                              objTax.taxAmount = parseFloat(dividedGST).toFixed(2);
                              taxArray.push(objTax);

                              objTax = {
                                "itemId": "0",
                                "taxId": "0",
                                "taxDisplayName": "",
                                "taxAmount": "0"
                              };
                              objTax.itemId = this.itemAddCart[idx].units[p].modifiers[m].items[r].modifieritem_id;
                              objTax.taxId = this.itemAddCart[idx].units[p].modifiers[m].items[r].taxes[n].taxunkid;
                              objTax.taxDisplayName = this.itemAddCart[idx].units[p].modifiers[m].items[r].taxes[n].tax + " (CGST)";
                              objTax.taxAmount = parseFloat(dividedGST).toFixed(2);
                              taxArray.push(objTax);

                              masterTax[this.itemAddCart[idx].units[p].modifiers[m].items[r].taxes[n].taxunkid] = afterTaxAmount + (parseFloat(dividedGST) * 2);
                            }
                          } else {
                            if (this.itemAddCart[idx].units[p].modifiers[m].items[r].taxes[n].pytype == "%") {
                              var taxAmount = ((afterTaxAmount * parseFloat(this.itemAddCart[idx].units[p].modifiers[m].items[r].taxes[n].amount)) / 100).toFixed(2);

                              objTax.itemId = this.itemAddCart[idx].units[p].modifiers[m].items[r].modifieritem_id;
                              objTax.taxId = this.itemAddCart[idx].units[p].modifiers[m].items[r].taxes[n].taxunkid;
                              objTax.taxDisplayName = this.itemAddCart[idx].units[p].modifiers[m].items[r].taxes[n].tax;
                              objTax.taxAmount = parseFloat(taxAmount).toFixed(2);
                              taxArray.push(objTax);

                              masterTax[this.itemAddCart[idx].units[p].modifiers[m].items[r].taxes[n].taxunkid] = afterTaxAmount + parseFloat(taxAmount);
                            } else {
                              objTax.itemId = this.itemAddCart[idx].units[p].modifiers[m].items[r].modifieritem_id;
                              objTax.taxId = this.itemAddCart[idx].units[p].modifiers[m].items[r].taxes[n].taxunkid;
                              objTax.taxDisplayName = this.itemAddCart[idx].units[p].modifiers[m].items[r].taxes[n].tax;
                              objTax.taxAmount = parseFloat(this.itemAddCart[idx].units[p].modifiers[m].items[r].taxes[n].amount).toFixed(2);
                              taxArray.push(objTax);

                              masterTax[this.itemAddCart[idx].units[p].modifiers[m].items[r].taxes[n].taxunkid] = afterTaxAmount + parseFloat(this.itemAddCart[idx].units[p].modifiers[m].items[r].taxes[n].amount);
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    var tmpTaxArrray = {};
    var tmpTaxArrray = {};
    taxArray.forEach(function (ta) {
      if (tmpTaxArrray.hasOwnProperty(ta.taxDisplayName)) {
        tmpTaxArrray[ta.taxDisplayName] = parseFloat(tmpTaxArrray[ta.taxDisplayName]) + parseFloat(ta.taxAmount);
      } else {
        tmpTaxArrray[ta.taxDisplayName] = ta.taxAmount;
      }
    });

    this.finalTaxArrray = [];
    for (var prop in tmpTaxArrray) {
      this.finalTaxArrray.push({ taxDisplayName: prop, taxAmount: tmpTaxArrray[prop] });
    }

    for (var idx in this.finalTaxArrray) {
      const p: HTMLParagraphElement = this.renderer.createElement('p');
      p.setAttribute("class", "mb-1");
      p.innerHTML = this.finalTaxArrray[idx].taxDisplayName;
      this.renderer.appendChild(this.divSubTotal.nativeElement, p);

      const span: HTMLParagraphElement = this.renderer.createElement('span');
      span.setAttribute("class", "float-right text-dark");
      span.innerHTML = this.currencySymbol + " " + parseFloat(this.finalTaxArrray[idx].taxAmount).toFixed(2);
      this.renderer.appendChild(p, span);

      this.grandTotalAmount = parseFloat(this.grandTotalAmount) + (parseFloat(this.finalTaxArrray[idx].taxAmount));
    }

    if (this.discountAmount > 0) {
      const pDiscount: HTMLParagraphElement = this.renderer.createElement('p');
      pDiscount.setAttribute("class", "mb-1");
      pDiscount.innerHTML = "Discount (-) ";
      this.renderer.appendChild(this.divSubTotal.nativeElement, pDiscount);

      const spanDiscount: HTMLParagraphElement = this.renderer.createElement('span');
      spanDiscount.setAttribute("class", "float-right text-dark");
      spanDiscount.innerHTML = this.currencySymbol + " " + parseFloat(this.discountAmount).toFixed(2);
      this.renderer.appendChild(pDiscount, spanDiscount);

      this.grandTotalAmount = parseFloat(this.grandTotalAmount) - (parseFloat(this.discountAmount));
    }

    if (environment.roundoff != undefined && environment.roundoff != null) {
      const pRoundOff: HTMLParagraphElement = this.renderer.createElement('p');
      pRoundOff.setAttribute("class", "mb-1");
      pRoundOff.innerHTML = "Rounding off ";
      this.renderer.appendChild(this.divSubTotal.nativeElement, pRoundOff);

      const spanDiscount: HTMLParagraphElement = this.renderer.createElement('span');
      spanDiscount.setAttribute("class", "float-right text-dark");

      if (environment.roundoff == "0.1") {
        console.log('this.grandTotalAmount',Number(this.grandTotalAmount).toFixed(1));
        var am = parseFloat(Number(this.grandTotalAmount).toFixed(1)) - parseFloat(this.grandTotalAmount);
        spanDiscount.innerHTML = this.currencySymbol + " " + am.toFixed(2);

        this.grandTotalAmount = parseFloat(this.grandTotalAmount) + parseFloat(am.toFixed(2));
        this.grandTotalAmount = parseFloat(this.grandTotalAmount).toFixed(1);
      }
      else if (environment.roundoff == "1") {
        var am = parseFloat(this.grandTotalAmount) - parseFloat(this.grandTotalAmount);
        // var am = parseFloat(this.grandTotalAmount.toFixed(0)) - parseFloat(this.grandTotalAmount);
        spanDiscount.innerHTML = this.currencySymbol + " " + am.toFixed(2);

        this.grandTotalAmount = parseFloat(this.grandTotalAmount) + parseFloat(am.toFixed(2));
        this.grandTotalAmount = parseFloat(this.grandTotalAmount).toFixed(0);
      }
      else if (environment.roundoff == "10") {
        var am = Math.round(this.grandTotalAmount / 10) * 10 - parseFloat(this.grandTotalAmount);
        spanDiscount.innerHTML = this.currencySymbol + " " + am.toFixed(2);

        this.grandTotalAmount = parseFloat(this.grandTotalAmount) + parseFloat(am.toFixed(2));
        this.grandTotalAmount = parseFloat(this.grandTotalAmount).toFixed(0);
      }
      else {
        var amt = parseFloat(this.grandTotalAmount.toFixed(2)) - parseFloat(this.grandTotalAmount)
        spanDiscount.innerHTML = this.currencySymbol + " 0.00";
        this.grandTotalAmount = parseFloat(this.grandTotalAmount) + amt;
        this.grandTotalAmount = parseFloat(this.grandTotalAmount).toFixed(2);

      }
      this.renderer.appendChild(pRoundOff, spanDiscount);
    }

    this.setGrandTotalUI();
    this.spinner.hide();
  }

  setGrandTotalUI() {
    this.spinner.show();
    const hr: HTMLParagraphElement = this.renderer.createElement('hr');
    this.renderer.appendChild(this.divSubTotal.nativeElement, hr);

    const h6GrandTotal: HTMLParagraphElement = this.renderer.createElement('h6');
    h6GrandTotal.setAttribute("class", "font-weight-bold mb-0 pb-2");
    h6GrandTotal.innerHTML = "Grand Total ";
    this.renderer.appendChild(this.divSubTotal.nativeElement, h6GrandTotal);

    const spanGrandTotal: HTMLParagraphElement = this.renderer.createElement('span');
    spanGrandTotal.setAttribute("class", "float-right");
    spanGrandTotal.innerHTML = this.currencySymbol + " " + this.grandTotalAmount;
    this.renderer.appendChild(h6GrandTotal, spanGrandTotal);
    this.spinner.hide();
  }

  setPlaceOrderUI() {
    this.spinner.show();
    this.divPlaceOrder.nativeElement.innerHTML = null;

    if (this.userLoginStatus) {
      const a: HTMLParagraphElement = this.renderer.createElement('a');
      a.setAttribute("class", "btn btn-success btn-block btn-lg btns-ss-two fixed-bottom");
      a.setAttribute("style", "border-radius: 50px 50px 0 0;");
      a.setAttribute("href", "javascript:void(0)");
      a.innerHTML = "Place Order ";
      a.setAttribute("id", "btnPlaceOrder");


      a.addEventListener('click', (e) => {
        this.placeOrder(1);
      });

      this.renderer.appendChild(this.divPlaceOrder.nativeElement, a);

      const i: HTMLParagraphElement = this.renderer.createElement('i');
      i.setAttribute("class", "icofont-long-arrow-right");
      this.renderer.appendChild(a, i);
    } else {
     
      this.emp_login = this.cookieService.get("emp_login")?this.cookieService.get("emp_login"):0;
      if(this.emp_login == 0)
      {
        const divMain: HTMLParagraphElement = this.renderer.createElement('div');
        divMain.setAttribute("class", "btns-ss-two fixed-bottom");
        divMain.setAttribute("style", "box-shadow: 0 0 0 rgba(0,0,0,0);");
        this.renderer.appendChild(this.divPlaceOrder.nativeElement, divMain);
  
        const divLogin: HTMLParagraphElement = this.renderer.createElement('div');
        divLogin.setAttribute("class", "col-6 m-0 p-0");
        this.renderer.appendChild(divMain, divLogin);
  
        const button1: HTMLParagraphElement = this.renderer.createElement('button');
        button1.setAttribute("type", "button");
        button1.setAttribute("class", "btn btn-primary btn-lg btn-block");
        button1.setAttribute("data-toggle", "modal");
        button1.setAttribute("style", "border-radius: 50px 50px 0 0;");
        button1.setAttribute("data-target", "#LoginModal");
        button1.innerHTML = "Login";
        this.renderer.appendChild(divLogin, button1);

        const divSignUp: HTMLParagraphElement = this.renderer.createElement('div');
        divSignUp.setAttribute("class", "col-6 m-0 p-0");
        this.renderer.appendChild(divMain, divSignUp);
  
        const button2: HTMLParagraphElement = this.renderer.createElement('button');
        button2.setAttribute("type", "button");
        button2.setAttribute("class", "btn btn-primary btn-lg btn-block");
        button2.setAttribute("style", "border-radius: 50px 50px 0 0;");
        button2.setAttribute("data-toggle", "modal");
        button2.setAttribute("data-target", "#SignupPopup");
        button2.innerHTML = "Register";
        this.renderer.appendChild(divSignUp, button2);
      }
      else{
        const divMain: HTMLParagraphElement = this.renderer.createElement('div');
        divMain.setAttribute("class", "btns-ss-two fixed-bottom");
        divMain.setAttribute("style", "box-shadow: 0 0 0 rgba(0,0,0,0);");
        this.renderer.appendChild(this.divPlaceOrder.nativeElement, divMain);
  
        const divLogin: HTMLParagraphElement = this.renderer.createElement('div');
        divLogin.setAttribute("class", "col-12 m-0 p-0");
        this.renderer.appendChild(divMain, divLogin);
  
        const button1: HTMLParagraphElement = this.renderer.createElement('button');
        button1.setAttribute("type", "button");
        button1.setAttribute("class", "btn btn-primary btn-lg btn-block");
        button1.setAttribute("data-toggle", "modal");
        button1.setAttribute("style", "border-radius: 50px 50px 0 0;");
        button1.setAttribute("data-target", "#LoginModal");
        button1.innerHTML = "Login";
        this.renderer.appendChild(divLogin, button1);
      }
     
    }
    this.spinner.hide();
  }

  placeOrder(orderType) {
    $("#btnPlaceOrder").attr("disabled", true);

    this.spinner.show();
    var objPlaceOrder = {};
    var totalCartItemsCount = 0;

    for (var i = 0; i < this.itemAddCart.length; i++) {
      totalCartItemsCount += parseInt(this.itemAddCart[i].product_quantity);
    }

    var objItems = {
      "cart_total": parseFloat(this.grandTotalAmount).toFixed(2),
      "cart_items": totalCartItemsCount,
      "sub_total": parseFloat(this.discount ? (this.cartTotalAmount - parseFloat(this.discount.amount)) : this.cartTotalAmount).toFixed(2),
      "discount": (this.discount ? this.discount.amount : 0),
      "promocode": (this.discount ? this.discount.promocode : ""),
      "promocode_id": (this.discount ? this.discount.promocode_id : ""),
      "items": this.itemAddCart
    }

    var objCart = {
      "note": $("#SpecialInstructions").val(),
      "location_id": this.locationId,
      "order_serve_date": "",
      "payment_method": "",
      "cart": objItems
    }

    Object.assign(objPlaceOrder, objCart);

    if (orderType == 1) {
      localStorage.setItem(this.locationId + "_placeOrder", JSON.stringify(objPlaceOrder));
      localStorage.setItem(this.locationId + "_taxData", JSON.stringify(this.finalTaxArrray));
    }

    // Start --> Create object for Validate & Place order

    // Remain To Set Dicount & Promo

    var objItemModifiers = [];
    var objOrderItem = [];

    for (var i = 0; i < this.itemAddCart.length; i++) {
      objItemModifiers = [];
      for (var m = 0; m < this.itemAddCart[i].units.length; m++) {
        if (this.itemAddCart[i].units[m].modifiers) {
          for (var n = 0; n < this.itemAddCart[i].units[m].modifiers.length; n++) {

            var objModifierItem = []

            if (this.itemAddCart[i].units[m].modifiers[n].items) {
              var addModifier = false;

              for (var p = 0; p < this.itemAddCart[i].units[m].modifiers[n].items.length; p++) {
                if (this.itemAddCart[i].units[m].modifiers[n].items[p].quantity) {
                  var objItem = {
                    "item_id": this.itemAddCart[i].item_id,
                    "unit_id": this.itemAddCart[i].default_unit_id,
                    "modifier_id": this.itemAddCart[i].units[m].modifiers[n].modifier_id,
                    "modifieritem_id": this.itemAddCart[i].units[m].modifiers[n].items[p].modifieritem_id,
                    "name": this.itemAddCart[i].units[m].modifiers[n].items[p].modifier_item_name,
                    "is_free": this.itemAddCart[i].units[m].modifiers[n].items[p].is_free,
                    "max_no": this.itemAddCart[i].units[m].modifiers[n].items[p].max_no,
                    "can_select_multiple": this.itemAddCart[i].units[m].modifiers[n].items[p].can_select_multiple,
                    "price": this.itemAddCart[i].units[m].modifiers[n].items[p].price,
                    "min_item": this.itemAddCart[i].units[m].modifiers[n].items[p].min_item,
                    "max_item": this.itemAddCart[i].units[m].modifiers[n].items[p].max_item,
                    "master_type": "9",
                    "quantity": this.itemAddCart[i].units[m].modifiers[n].items[p].quantity
                  }
                  objModifierItem.push(objItem);
                  addModifier = true;
                }
              }

              if (addModifier == true) {
                var objModifier = {
                  "item_id": this.itemAddCart[i].item_id,
                  "unit_id": this.itemAddCart[i].default_unit_id,
                  "modifier_id": this.itemAddCart[i].units[m].modifiers[n].modifier_id,
                  "name": this.itemAddCart[i].units[m].modifiers[n].modifier_name,
                  "is_free": this.itemAddCart[i].units[m].modifiers[n].is_free,
                  "max": this.itemAddCart[i].units[m].modifiers[n].is_free,
                  "select_multiple": this.itemAddCart[i].units[m].modifiers[n].select_multiple,
                  "quantity": (this.itemAddCart[i].units[m].modifiers[n].quantity ? this.itemAddCart[i].units[m].modifiers[n].quantity : "0"),
                  "price": this.itemAddCart[i].units[m].modifiers[n].price,
                  "min_item": this.itemAddCart[i].units[m].modifiers[n].min_item,
                  "max_item": this.itemAddCart[i].units[m].modifiers[n].max_item,
                  "is_require": this.itemAddCart[i].units[m].modifiers[n].is_require,
                  "master_type": "10",
                  "items": objModifierItem
                }
                objItemModifiers.push(objModifier);
              }
            } else {
              if (this.itemAddCart[i].units[m].modifiers[n].quantity) {
                var objModifier = {
                  "item_id": this.itemAddCart[i].item_id,
                  "unit_id": this.itemAddCart[i].default_unit_id,
                  "modifier_id": this.itemAddCart[i].units[m].modifiers[n].modifier_id,
                  "name": this.itemAddCart[i].units[m].modifiers[n].modifier_name,
                  "is_free": this.itemAddCart[i].units[m].modifiers[n].is_free,
                  "max": this.itemAddCart[i].units[m].modifiers[n].is_free,
                  "select_multiple": this.itemAddCart[i].units[m].modifiers[n].select_multiple,
                  "quantity": (this.itemAddCart[i].units[m].modifiers[n].quantity ? this.itemAddCart[i].units[m].modifiers[n].quantity : "0"),
                  "price": this.itemAddCart[i].units[m].modifiers[n].price,
                  "min_item": this.itemAddCart[i].units[m].modifiers[n].min_item,
                  "max_item": this.itemAddCart[i].units[m].modifiers[n].max_item,
                  "is_require": this.itemAddCart[i].units[m].modifiers[n].is_require,
                  "master_type": "10",
                  "items": objModifierItem
                }
                objItemModifiers.push(objModifier);
              }
            }
          }
        }
      }
      // console.log(this.itemAddCart[i]);
      var obj = {
        "item_id": this.itemAddCart[i].item_id,
        "itemtype_id": this.itemAddCart[i].types[0].itemtype_id,
        "spicy_type": this.itemAddCart[i].spicy_type,
        "product_name": this.itemAddCart[i].name,
        "order_note": (this.itemAddCart[i].addNote ? this.itemAddCart[i].addNote : ""),
        "product_is_advance": "0",
        "priority": "0",
        "is_jain": (this.itemAddCart[i].SelectIsJain == true ? 1 : 0),
        "category_id":  this.itemAddCart[i].category_id,
        "product_price": this.itemAddCart[i].price,
        "product_quantity": this.itemAddCart[i].product_quantity,
        "unit_id": this.itemAddCart[i].default_unit_id,
        "unit_name": this.itemAddCart[i].default_unitname,
        "master_type": "8",
        "modifiers": objItemModifiers
      }

      objOrderItem.push(obj);
    }

    // var objOrderCart = {
    //   "cart_total": this.grandTotalAmount,
    //   "cart_items": this.itemAddCart.length,
    //   "discount": "0",
    //   "sub_total": this.cartTotalAmount,
    //   "items": objOrderItem
    // }
    var objOrderCart = {
      "cart_total": parseFloat(this.discount ? (this.cartTotalAmount - this.discount.amount) : this.cartTotalAmount).toFixed(2),
      "cart_items": this.itemAddCart.length,
      "sub_total": parseFloat(this.grandTotalAmount).toFixed(2),
      "discount": (this.discount ? this.discount.amount : 0),
      "promocode": (this.discount ? this.discount.promocode : ""),
      "promocode_id": (this.discount ? this.discount.promocode_id : ""),
      "items": objOrderItem
    }

    var objOrderMain = {
      "location_id": this.locationId,
      "cart": objOrderCart,
    }

    localStorage.setItem(this.locationId + "_orderDetail", JSON.stringify(objOrderMain));
    // End --> Create object for Validate & Place order

    if (orderType == 1) {
      this.router.navigate(['/placeorder']);
    }

    this.spinner.hide();
  }

  showCoupons() {
    var obj = {
      "location_id": this.locationId
    }

    this.spinner.show();
    this.service.getPromoCodeList(obj).subscribe(response => {
      if (response.status == "ok") {
        this.promoCodeList = response.payload;
        this.couponCount = this.promoCodeList.length;
        $('#CouponCodesShow').modal('show');
      } else {
        this.toastr.error(response.message);
      }
      this.spinner.hide();
    }, (err: HttpErrorResponse) => {
      this.spinner.hide();
    });
  }

  applyCoupon(promoCodeId, promoCode, promoId) {
    this.spinner.show();

    this.promoCodeTitle = promoCode;
    $('#CouponCodesShow').modal('hide');

    this.placeOrder(0);
    var order = JSON.parse(localStorage.getItem(this.locationId + "_orderDetail"));
    var obj = {
      "promocode": promoCodeId,
      "location_id": this.locationId
    };

    order.cart.sub_total = order.cart.cart_total;
    Object.assign(order, obj);

    this.service.applyPromoCode(order).subscribe(response => {
      if (response.status == "ok") {
        this.discountAmount = response.payload.discount_total;

        var objDiscount = {
          "amount": this.discountAmount,
          "title": promoCode,
          "promocode": promoCodeId,
          "promocode_id": promoId
        };

        this.discount = objDiscount;
        localStorage.setItem(this.locationId + "_discount", JSON.stringify(objDiscount));

        document.getElementById("divCouponRemove").style.display = "block";
        document.getElementById("divCouponEnable").style.display = "none";
        document.getElementById("divCouponDisable").style.display = "none";

        this.setSubTotalUI();
      } else {
        this.toastr.error(response.message);
      }
      this.spinner.hide();
    }, (err: HttpErrorResponse) => {
      // console.log('erre',err);
      this.toastr.error(err.toString());
      this.spinner.hide();
    });
  }

  removeCoupon() {
    this.spinner.show();

    this.promoCodeTitle = "";

    this.discountAmount = "0";

    localStorage.removeItem(this.locationId + "_discount");

    document.getElementById("divCouponRemove").style.display = "none";
    document.getElementById("divCouponEnable").style.display = "block";
    document.getElementById("divCouponDisable").style.display = "none";

    this.discount = null;

    this.setSubTotalUI();

    this.spinner.hide();
  }

  getMenuCategory(locationId) {
    var obj = {
      "location_id": locationId
    };

    this.spinner.show();
    this.menuCategoryService.getMenuCategory(obj).subscribe(response => {
      if (response.status == "ok") {
        this.getMenuList(response.payload[0].menu_id);
      } else {
        this.toastr.error(response.message);
      }
      this.spinner.hide();
    }, (err: HttpErrorResponse) => {
      this.spinner.hide();
    });
  }

  getMenuList(menuCategoryId) {
    let menuCategoryItem = [];
    this.menuList = [];
    var obj = {
      "menu_id": menuCategoryId,
      "is_all_cat_enable": 1,
      "is_addon": 1,
      "location_id": this.locationId
    };

    this.spinner.show();
    this.menuCategoryService.getMenuList(obj).subscribe(response => {
      if (response.status == "ok") {
        if (response.payload.menu.categories) {
          for (var idx in response.payload.menu.categories) {
            menuCategoryItem = response.payload.menu.categories[idx].items;

            this.menuCategoryList.push(response.payload.menu.categories[idx]);

            for (var index in menuCategoryItem) {
              this.menuList.push(menuCategoryItem[index]);
            }
          }

          if (document.getElementById("divAddOns")) {
            document.getElementById("divAddOns").style.display = "block";
            setTimeout(() => { mymethod(); }, 200);
          }
        } else {
          if (document.getElementById("divAddOns")) {
            document.getElementById("divAddOns").style.display = "none";
          }
          this.toastr.error("Add-Ons Not Available");
        }
      } else {
        this.toastr.error(response.message);
      }
      this.spinner.hide();
    }, (err: HttpErrorResponse) => {
      this.spinner.hide();
    });
  }

  openItemDetail(itemInfo, i) {
    this.spinner.show();

    this.itemInformations = itemInfo;
    this.itemDetailPrice = itemInfo['price'];
    this.itemDetailName = itemInfo['name'];
    this.itemDetailDescription = itemInfo['description'];
    this.itemDetailImage = itemInfo['image_url'].main_image;
    this.itemDetailIsJain = itemInfo['is_jain'];
    this.itemDetailIndex = i;
    this.itemDetailTypeImage = itemInfo.types[0].image;

    $("#addons-options-modal").modal("show");

    this.divJainDetail.nativeElement.innerHTML = null;
    if (this.itemDetailIsJain == 1) {
      const divWhite: HTMLParagraphElement = this.renderer.createElement('div');
      divWhite.setAttribute("class", "bg-white rounded shadow mb-3 py-2 col-12 mt-2 pl-0 pr-0");
      this.renderer.appendChild(this.divJainDetail.nativeElement, divWhite);

      const divFormGroup: HTMLParagraphElement = this.renderer.createElement('div');
      divFormGroup.setAttribute("class", "col-md-12 form-group mb-0");
      this.renderer.appendChild(divWhite, divFormGroup);

      const divCheckbox: HTMLParagraphElement = this.renderer.createElement('div');
      divCheckbox.setAttribute("class", "custom-control custom-checkbox test");
      this.renderer.appendChild(divFormGroup, divCheckbox);

      const input: HTMLParagraphElement = this.renderer.createElement('input');
      input.setAttribute("type", "checkbox");
      input.setAttribute("ngModel", "");
      input.setAttribute("id", itemInfo['item_id']);
      input.setAttribute("name", itemInfo['item_id']);
      input.setAttribute("class", "custom-control-input");
      input.addEventListener('click', (e) => {
        this.setJainStatusDetail(e.srcElement, i);
      });

      var c = $("#jainItem_" + itemInfo["item_id"]).prop("checked");
      if (c == true) {
        input.setAttribute("checked", c);
      }
      this.renderer.appendChild(divCheckbox, input);

      const label: HTMLParagraphElement = this.renderer.createElement('label');
      label.setAttribute("for", itemInfo['item_id']);
      label.setAttribute("class", "custom-control-label pt-1");
      this.renderer.appendChild(divCheckbox, label);

      const strong: HTMLParagraphElement = this.renderer.createElement('strong');
      strong.innerHTML = "Jain";
      this.renderer.appendChild(label, strong);
    }

    this.spinner.hide();
  }

  setJainStatusDetail(chkValue, i) {
    if (chkValue.checked) {
      this.menuList[i]["SelectIsJain"] = true;
      $("#jainItem_" + this.menuList[i]["item_id"]).prop("checked", true);
    } else {
      this.menuList[i]["SelectIsJain"] = false;
      $("#jainItem_" + this.menuList[i]["item_id"]).prop("checked", false);
    }
  }

  getItemDetails(itemDetail, i) {
    this.spinner.show();

    this.lblItemName = itemDetail.name;
    this.itemDetailIsJain = itemDetail.is_jain;
    $('#ItemAddToCart').modal('show');
    // $('#addons-options-modal').modal('hide');
    // $('#ItemUnitModal').modal('show');
    this.itemDetails = itemDetail;

    this.itemDetailPrice = this.itemDetails['price'];
    this.itemDetailName = this.itemDetails['name'];
    this.itemDetailDescription = this.itemDetails['description'];
    this.itemDetailImage = this.itemDetails['image_url'].main_image;

    if (this.isPriceWithTax)
      this.lblItemRateWithTax = itemDetail.display_inc_tax_price;

    this.itemUnits = itemDetail.units;
    this.itemTypes = itemDetail.types
    this.lblItemName = itemDetail.name;
    this.lblItemRate = itemDetail.price;
    this.itemDetailTypeImage = this.itemTypes[0].image;
    this.lblCategoryName = itemDetail.categoryname;
    // this.lblTotalAmount = itemDetail.price;
    this.itemRadioModifierTotal = 0

    var objItem = {
      "product_quantity": "0"
    }
    Object.assign(this.itemDetails, objItem);
    this.temp_i = i;

    if (this.itemUnits != undefined && this.itemUnits.length > 0) {
      this.getModifiers(0, this.itemUnits[0].modifiers, i);
    }

    this.spinner.hide();
  }

  getModifiers(arrayIndex, unitModifiers, i) {
    this.spinner.show();
    this.itemModifiers = [];
    this.itemModifiers = unitModifiers;

    if (this.isPriceWithTax)
      this.lblItemRateWithTax = this.itemDetails['units'][arrayIndex].display_inc_tax_price;

    this.lblItemRate = this.itemDetails['units'][arrayIndex].price;
    this.itemDetails['default_unit_id'] = this.itemDetails['units'][arrayIndex].unit_id;
    this.itemDetails['default_unitname'] = this.itemDetails['units'][arrayIndex].unit_name;
    // this.itemDetails['price'] = this.itemDetails['units'][arrayIndex].price; 
    if (this.isPriceWithTax)
      this.itemDetails['priceWithTax'] = this.itemDetails['units'][arrayIndex].display_inc_tax_price;

    this.itemDetails['price'] = this.itemDetails['units'][arrayIndex].price;

    this.itemDetails['product_quantity'] = "1";
    if (this.isPriceWithTax)
      this.itemTotal = parseFloat(this.itemDetails['product_quantity']) * parseFloat(this.itemDetails['units'][arrayIndex].display_inc_tax_price);
    else
      this.itemTotal = parseFloat(this.itemDetails['product_quantity']) * parseFloat(this.itemDetails['units'][arrayIndex].price);

    this.lblTotalAmount = (parseFloat(this.itemTotal) + parseFloat(this.itemModifierTotal) + parseFloat(this.itemChildModifierTotal)).toFixed(2);

    // $("#ItemUnitModal").modal('hide');
    $("#ItemAddToCart").modal('show');

    this.divFormRow.nativeElement.innerHTML = null;
    this.itemModifierTotal = 0;
    this.itemChildModifierTotal = 0;
    this.totalPriceCalculation();

    if (this.itemModifiers) {
      this.setModifierUI(this.itemModifiers);
    }

    this.divJainAddToCart.nativeElement.innerHTML = null;
    if (this.itemDetails['is_jain'] == 1) {
      this.setAddToCartJainUI(this.itemDetails, arrayIndex, i);
    }
    this.spinner.hide();
  }

  setAddToCartJainUI(chkItemDetails, i, index) {
    const divRow: HTMLParagraphElement = this.renderer.createElement('div');
    divRow.setAttribute("class", "form-row");
    this.renderer.appendChild(this.divJainAddToCart.nativeElement, divRow);

    const divFormGroup: HTMLParagraphElement = this.renderer.createElement('div');
    divFormGroup.setAttribute("class", "col-md-4 form-group mb-0");
    this.renderer.appendChild(divRow, divFormGroup);

    const divCheckbox: HTMLParagraphElement = this.renderer.createElement('div');
    divCheckbox.setAttribute("class", "custom-control custom-checkbox test");
    this.renderer.appendChild(divFormGroup, divCheckbox);

    const input: HTMLParagraphElement = this.renderer.createElement('input');
    input.setAttribute("type", "checkbox");
    input.setAttribute("ngModel", "");
    input.setAttribute("id", "item_" + chkItemDetails['item_id']);
    input.setAttribute("name", "item_" + chkItemDetails['item_id']);
    input.setAttribute("class", "custom-control-input");
    input.addEventListener('click', (e) => {
      this.setJainStatusAddToCart(e.srcElement, index);
    });

    var c = $("#jainItem_" + chkItemDetails["item_id"]).prop("checked");

    if (c == true) {
      input.setAttribute("checked", c);
    }
    this.renderer.appendChild(divCheckbox, input);

    const label: HTMLParagraphElement = this.renderer.createElement('label');
    label.setAttribute("for", "item_" + chkItemDetails['item_id']);
    label.setAttribute("class", "custom-control-label pt-1");
    this.renderer.appendChild(divCheckbox, label);

    const strong: HTMLParagraphElement = this.renderer.createElement('strong');
    strong.innerHTML = "Jain";
    this.renderer.appendChild(label, strong);
  }

  setJainStatusAddToCart(chkValue, i) {
    if (chkValue.checked) {
      $("#jainItem_" + this.menuList[i]["item_id"]).prop("checked", true);
      this.menuList[i]["SelectIsJain"] = true;
    } else {
      this.menuList[i].SelectIsJain = false;
      $("#jainItem_" + this.menuList[i]["item_id"]).prop("checked", false);
    }
  }

  setModifierUI(modifiers) {
    this.spinner.show();
    const ulMain: HTMLParagraphElement = this.renderer.createElement('ul');
    this.renderer.appendChild(this.divFormRow.nativeElement, ulMain);

    for (var i = 0; i < modifiers.length; i++) {
      const liMain: HTMLParagraphElement = this.renderer.createElement('li');
      this.renderer.appendChild(ulMain, liMain);

      if (modifiers[i].items) {
        var childModifiers = modifiers[i].items;

        const span: HTMLParagraphElement = this.renderer.createElement('span');
        span.innerHTML = "<b>" + modifiers[i].modifier_name + "</b>";
        this.renderer.appendChild(liMain, span);

        const ul: HTMLParagraphElement = this.renderer.createElement('ul');
        this.renderer.appendChild(liMain, ul);

        if (modifiers[i].selection == 0) {
          const divFormRow: HTMLParagraphElement = this.renderer.createElement('div');
          divFormRow.setAttribute("class", "mb-0 col-md-12 form-group mb-3 pt-2");
          this.renderer.appendChild(liMain, divFormRow);

          const divButton: HTMLParagraphElement = this.renderer.createElement('div');
          divButton.setAttribute("class", "btn-group btn-group-toggle w-100");
          divButton.setAttribute("data-toggle", "buttons");
          this.renderer.appendChild(divFormRow, divButton);

          for (var n = 0; n < childModifiers.length; n++) {
            const divLabel: HTMLParagraphElement = this.renderer.createElement('a');
            divLabel.setAttribute("class", "btn btn-outline-secondary btns-select");
            divLabel.setAttribute("style", "border-radius: 30px; margin-left: 10px;");
            divLabel.setAttribute("id", (i + "_" + n).toString());
            if (this.isPriceWithTax)
              divLabel.innerHTML = childModifiers[n].modifier_item_name + " <br> (" + this.currencySymbol + " " + parseFloat(childModifiers[n].display_inc_tax_price).toFixed(2) + ")";
            else
              divLabel.innerHTML = childModifiers[n].modifier_item_name + " <br> (" + this.currencySymbol + " " + parseFloat(childModifiers[n].price).toFixed(2) + ")";

            divLabel.addEventListener('click', (e) => {
              this.setRadioModifiersValue(divLabel.id);
            });
            this.renderer.appendChild(divButton, divLabel);

            const inputType: HTMLParagraphElement = this.renderer.createElement('input');
            inputType.setAttribute("type", "radio");
            inputType.setAttribute("name", "itemaddonid" + n.toString());
            inputType.setAttribute("data-val", "0.00");
            inputType.setAttribute("class", "form-check-input-addon");
            inputType.setAttribute("id", "itemaddonid" + n.toString());
            inputType.setAttribute("value", n.toString());
            this.renderer.appendChild(divLabel, inputType)
          }
        } else {
          const ul: HTMLParagraphElement = this.renderer.createElement('ul');
          this.renderer.appendChild(liMain, ul);

          for (var n = 0; n < childModifiers.length; n++) {
            const divFormRow: HTMLParagraphElement = this.renderer.createElement('div');
            divFormRow.setAttribute("class", "form-row");
            this.renderer.appendChild(ul, divFormRow);

            const div61: HTMLParagraphElement = this.renderer.createElement('div');
            div61.setAttribute("class", "col-md-6 form-group");
            this.renderer.appendChild(divFormRow, div61);

            const divInputGropu1: HTMLParagraphElement = this.renderer.createElement('div');
            divInputGropu1.setAttribute("class", "input-group");
            divInputGropu1.setAttribute("style", "height: 100%; line-height: 30px;");
            this.renderer.appendChild(div61, divInputGropu1);

            const span: HTMLParagraphElement = this.renderer.createElement('span');
            if (this.isPriceWithTax)
              span.innerHTML = childModifiers[n].modifier_item_name + " (" + this.currencySymbol + " " + parseFloat(childModifiers[n].display_inc_tax_price).toFixed(2) + ")";
            else
              span.innerHTML = childModifiers[n].modifier_item_name + " (" + this.currencySymbol + " " + parseFloat(childModifiers[n].price).toFixed(2) + ")";
            this.renderer.appendChild(divInputGropu1, span);

            const div62: HTMLParagraphElement = this.renderer.createElement('div');
            div62.setAttribute("class", "col-md-6 form-group");
            this.renderer.appendChild(divFormRow, div62);

            const divSpanMod: HTMLParagraphElement = this.renderer.createElement('span');
            divSpanMod.setAttribute("class", "count-number float-right");
            this.renderer.appendChild(div62, divSpanMod);

            const divInputGropu2: HTMLParagraphElement = this.renderer.createElement('div');
            divInputGropu2.setAttribute("class", "input-group");
            divInputGropu2.setAttribute("style", "text-align: right; display: block;");
            this.renderer.appendChild(divSpanMod, divInputGropu2);

            const buttonMinus: HTMLParagraphElement = this.renderer.createElement('bttoun');
            buttonMinus.setAttribute("type", "button");
            buttonMinus.setAttribute("class", "btn-sm left dec btn btnCart");
            buttonMinus.setAttribute("id", "decrease");
            buttonMinus.setAttribute("value", "Decrease Value");
            buttonMinus.addEventListener('click', (e) => {
              this.countChildModifierChange(0, inputNumber.id);
            });

            const iconMinus: HTMLParagraphElement = this.renderer.createElement('i');
            iconMinus.setAttribute("class", "feather-minus btnIcon");

            const inputNumber: HTMLParagraphElement = this.renderer.createElement('input');
            inputNumber.setAttribute("disabled", "");
            inputNumber.setAttribute("min", childModifiers[n].min_no);
            inputNumber.setAttribute("max", childModifiers[n].max_no);
            inputNumber.setAttribute("name", "quantity");
            inputNumber.setAttribute("value", "0");
            inputNumber.setAttribute("class", "count-number-input");
            inputNumber.setAttribute("id", (i + "_" + n).toString());
            inputNumber.setAttribute("type", "text");

            const buttonPlus: HTMLParagraphElement = this.renderer.createElement('bttoun');
            buttonPlus.setAttribute("type", "button");
            buttonPlus.setAttribute("class", "btn-sm right inc btn btnCart");
            buttonPlus.setAttribute("id", "increase");
            buttonPlus.setAttribute("value", "Increase Value");
            buttonPlus.addEventListener('click', (e) => {
              this.countChildModifierChange(1, inputNumber.id);
            });

            const iconPlus: HTMLParagraphElement = this.renderer.createElement('i');
            iconPlus.setAttribute("class", "feather-plus btnIcon");

            this.renderer.appendChild(divInputGropu2, buttonMinus);
            this.renderer.appendChild(buttonMinus, iconMinus);
            this.renderer.appendChild(divInputGropu2, inputNumber);
            this.renderer.appendChild(divInputGropu2, buttonPlus);
            this.renderer.appendChild(buttonPlus, iconPlus);
          }
        }
      } else {
        const divFormRow: HTMLParagraphElement = this.renderer.createElement('div');
        divFormRow.setAttribute("class", "form-row");
        this.renderer.appendChild(liMain, divFormRow);

        const div61: HTMLParagraphElement = this.renderer.createElement('div');
        div61.setAttribute("class", "col-md-6 form-group");
        this.renderer.appendChild(divFormRow, div61);

        const divInputGropu1: HTMLParagraphElement = this.renderer.createElement('div');
        divInputGropu1.setAttribute("class", "input-group");
        divInputGropu1.setAttribute("style", "height: 100%; line-height: 30px;");
        this.renderer.appendChild(div61, divInputGropu1);

        const span: HTMLParagraphElement = this.renderer.createElement('span');
        if (this.isPriceWithTax)
          span.innerHTML = "<b>" + modifiers[i].modifier_name + "</b> (" + this.currencySymbol + " " + parseFloat(modifiers[i].display_inc_tax_price).toFixed(2) + ")";
        else
          span.innerHTML = "<b>" + modifiers[i].modifier_name + "</b> (" + this.currencySymbol + " " + parseFloat(modifiers[i].price).toFixed(2) + ")";
        this.renderer.appendChild(divInputGropu1, span);

        const div62: HTMLParagraphElement = this.renderer.createElement('div');
        div62.setAttribute("class", "col-md-6 form-group");
        this.renderer.appendChild(divFormRow, div62);

        const divSpanModSub: HTMLParagraphElement = this.renderer.createElement('span');
        divSpanModSub.setAttribute("class", "count-number float-right");
        this.renderer.appendChild(div62, divSpanModSub);

        const divInputGropu2: HTMLParagraphElement = this.renderer.createElement('div');
        divInputGropu2.setAttribute("class", "input-group");
        divInputGropu2.setAttribute("style", "text-align: right; display: block;");
        this.renderer.appendChild(divSpanModSub, divInputGropu2);

        const buttonMinus: HTMLParagraphElement = this.renderer.createElement('bttoun');
        buttonMinus.setAttribute("type", "button");
        buttonMinus.setAttribute("class", "btn-sm left dec btn btnCart");
        buttonMinus.setAttribute("id", "decrease");
        buttonMinus.setAttribute("value", "Decrease Value");
        buttonMinus.addEventListener('click', (e) => {
          this.countModifierChange(0, inputNumber.id);
        });

        const iconMinus: HTMLParagraphElement = this.renderer.createElement('i');
        iconMinus.setAttribute("class", "feather-minus btnIcon");

        const inputNumber: HTMLParagraphElement = this.renderer.createElement('input');
        inputNumber.setAttribute("disabled", "");
        inputNumber.setAttribute("min", modifiers[i].min_item);
        inputNumber.setAttribute("max", modifiers[i].max_item);
        inputNumber.setAttribute("value", "0");
        inputNumber.setAttribute("class", "count-number-input");
        inputNumber.setAttribute("id", i.toString());
        inputNumber.setAttribute("type", "text");

        const buttonPlus: HTMLParagraphElement = this.renderer.createElement('bttoun');
        buttonPlus.setAttribute("type", "button");
        buttonPlus.setAttribute("class", "btn-sm right inc btn btnCart");
        buttonPlus.setAttribute("id", "increase");
        buttonPlus.setAttribute("value", "Increase Value");
        buttonPlus.addEventListener('click', (e) => {
          this.countModifierChange(1, inputNumber.id);
        });

        const iconPlus: HTMLParagraphElement = this.renderer.createElement('i');
        iconPlus.setAttribute("class", "feather-plus btnIcon");

        this.renderer.appendChild(divInputGropu2, buttonMinus);
        this.renderer.appendChild(buttonMinus, iconMinus);
        this.renderer.appendChild(divInputGropu2, inputNumber);
        this.renderer.appendChild(divInputGropu2, buttonPlus);
        this.renderer.appendChild(buttonPlus, iconPlus);
      }
    }
    this.spinner.hide();
  }

  countChildModifierChange(status, id) {
    this.spinner.show();
    var parentId = id.split("_")[0];
    var childId = id.split("_")[1];

    var value = parseInt($('#' + id).val());

    var objChildModifier = {
      "quantity": "0"
    }
    Object.assign(this.itemModifiers[parentId].items[childId], objChildModifier);

    if (status == 0) {
      if (value > 0) {
        value = value - 1;
        $('#' + id).val(value);

        this.itemModifiers[parentId].items[childId].quantity = value;

        if (this.isPriceWithTax)
          this.itemChildModifierTotal = (parseFloat(this.itemModifiers[parentId].items[childId].quantity) * parseFloat(this.itemModifiers[parentId].items[childId].display_inc_tax_price));
        else
          this.itemChildModifierTotal = (parseFloat(this.itemModifiers[parentId].items[childId].quantity) * parseFloat(this.itemModifiers[parentId].items[childId].price));
      }
    } else {
      var isMaxLimitReach = 0;

      if (value == this.itemModifiers[parentId].items[childId].max_item) {
        this.itemModifiers[parentId].items[childId].quantity = value;
        this.toastr.warning("Maximum Quantity For " + this.itemModifiers[parentId].items[childId].modifier_item_name + " Is " + this.itemModifiers[parentId].items[childId].max_item);
        isMaxLimitReach = 1;
      }

      if (isMaxLimitReach == 0) {
        if (value >= 0) {
          value = value + 1;
          $('#' + id).val(value);

          this.itemModifiers[parentId].items[childId].quantity = value;
          if (this.isPriceWithTax)
            this.itemChildModifierTotal = (parseFloat(this.itemModifiers[parentId].items[childId].quantity) * parseFloat(this.itemModifiers[parentId].items[childId].display_inc_tax_price));
          else
            this.itemChildModifierTotal = (parseFloat(this.itemModifiers[parentId].items[childId].quantity) * parseFloat(this.itemModifiers[parentId].items[childId].price));
        }
      }
    }

    var total = 0;
    for (var n = 0; n < this.itemModifiers.length; n++) {
      if (this.itemModifiers[n].items) {
        for (var i = 0; i < this.itemModifiers[n].items.length; i++) {
          var Qty = (this.itemModifiers[n].items[i].quantity ? this.itemModifiers[n].items[i].quantity : 0);
          if (this.isPriceWithTax)
            var price = this.itemModifiers[n].items[i].display_inc_tax_price;
          else
            var price = this.itemModifiers[n].items[i].price;
          var total = total + (parseFloat(Qty) * parseFloat(price));
        }
      }
    }

    this.itemChildModifierTotal = total;
    this.itemRadioModifierTotal = 0;
    this.totalPriceCalculation();
    this.spinner.hide();
  }

  countModifierChange(status, id) {
    this.spinner.show();
    var value = parseInt($('#' + id).val());

    var objModifier = {
      "quantity": "0"
    }
    Object.assign(this.itemModifiers[id], objModifier);

    if (status == 0) {
      if (value > 0) {
        value = value - 1;
        $('#' + id).val(value);

        this.itemModifiers[id].quantity = value;
        if (this.isPriceWithTax)
          this.itemModifierTotal = parseFloat(this.itemModifiers[id].quantity) * parseFloat(this.itemModifiers[id].display_inc_tax_price);
        else
          this.itemModifierTotal = parseFloat(this.itemModifiers[id].quantity) * parseFloat(this.itemModifiers[id].price);
      }
    } else {
      var isMaxLimitReach = 0;

      if (value == this.itemModifiers[id].max_item) {
        this.itemModifiers[id].quantity = value;
        this.toastr.warning("Maximum Quantity For " + this.itemModifiers[id].modifier_name + " Is " + this.itemModifiers[id].max_item);
        isMaxLimitReach = 1;
      }

      if (isMaxLimitReach == 0) {
        if (value >= 0) {
          value = value + 1;
          $('#' + id).val(value);

          this.itemModifiers[id].quantity = value;
          // this.itemModifierTotal = parseFloat(this.itemModifiers[id].quantity) * parseFloat(this.itemModifiers[id].price);
          if (this.isPriceWithTax)
            this.itemModifierTotal = parseFloat(this.itemModifiers[id].quantity) * parseFloat(this.itemModifiers[id].display_inc_tax_price);
          else
            this.itemModifierTotal = parseFloat(this.itemModifiers[id].quantity) * parseFloat(this.itemModifiers[id].price);
        }
      }
    }

    var total = 0;
    for (var i = 0; i < this.itemModifiers.length; i++) {
      if (this.itemModifiers[i].quantity) {
        var Qty = (this.itemModifiers[i].quantity ? this.itemModifiers[i].quantity : 0);
        if (this.isPriceWithTax)
          var price = this.itemModifiers[i].display_inc_tax_price;
        else
          var price = this.itemModifiers[i].price;

        var total = total + (parseFloat(Qty) * parseFloat(price));
      }
    }

    this.itemModifierTotal = total;
    this.totalPriceCalculation();
    this.spinner.hide();
  }

  setRadioModifiersValue(id) {
    this.spinner.show();
    var parentId = id.split("_")[0];
    var childId = id.split("_")[1];

    var objChildModifier = {
      "quantity": "0"
    }
    Object.assign(this.itemModifiers[parentId].items[childId], objChildModifier);

    for (var n = 0; n < this.itemModifiers.length; n++) {
      if (this.itemModifiers[n].items) {
        for (var i = 0; i < this.itemModifiers[n].items.length; i++) {
          this.itemModifiers[n].items[i].quantity = 0;
        }
      }
    }

    this.itemModifiers[parentId].items[childId].quantity = 1;
    if (this.isPriceWithTax)
      this.itemRadioModifierTotal = (parseFloat(this.itemModifiers[parentId].items[childId].quantity) * parseFloat(this.itemModifiers[parentId].items[childId].display_inc_tax_price));
    else
      this.itemRadioModifierTotal = (parseFloat(this.itemModifiers[parentId].items[childId].quantity) * parseFloat(this.itemModifiers[parentId].items[childId].price));

    this.totalPriceCalculation();
    this.spinner.hide();
  }

  totalPriceCalculation() {
    this.lblTotalAmount = (parseFloat(this.itemTotal) + ((parseFloat(this.itemModifierTotal) + parseFloat(this.itemChildModifierTotal) + parseFloat(this.itemRadioModifierTotal)) * parseFloat(this.itemDetails['product_quantity']))).toFixed(2);
  }

  resetAddToCart() {
    this.spinner.show();
    this.divFormRow.nativeElement.innerHTML = null;
    this.ModifierForm.resetForm();
    // this.ngOnInit();
    $("#ItemAddToCart").modal('hide');
    $('#ItemUnitModal').modal('hide');
    $("#addons-options-modal").modal("hide");
    $('#itemQuantity').val(1);

    this.lblTotalAmount = 0;
    this.itemTotal = 0;
    this.itemModifierTotal = 0;
    this.itemChildModifierTotal = 0;
    //this.ngOnInit();
    if (this.itemModifiers != undefined) {
      for (var n = 0; n < this.itemModifiers.length; n++) {
        if (this.itemModifiers[n].items) {
          for (var i = 0; i < this.itemModifiers[n].items.length; i++) {
            if (this.itemModifiers[n].items[i].hasOwnProperty("quantity") > 0)
              this.itemModifiers[n].items[i].quantity = 0;
          }
        }
      }
    }

    // this.getLocationDetail(this.locationId);
    window.location.reload(true);
    //this.sharedService.sendClickEvent();
    this.spinner.hide();
  }

  addToCart() {
    this.spinner.show();
    var formValues = this.ModifierForm.controls;

    if (this.ModifierForm.valid) {
      var itemModiferDetail = "";

      if (this.itemUnits) {
        for (var m = 0; m < this.itemUnits.length; m++) {
          if (this.itemUnits[m].modifiers) {
            for (var n = 0; n < this.itemUnits[m].modifiers.length; n++) {
              if (this.itemUnits[m].modifiers[n].items) {
                for (var p = 0; p < this.itemUnits[m].modifiers[n].items.length; p++) {
                  if (parseFloat(this.itemUnits[m].modifiers[n].items[p].quantity)) {
                    if (itemModiferDetail.length > 0) {
                      itemModiferDetail = itemModiferDetail + ", "
                    }

                    itemModiferDetail = itemModiferDetail + this.itemUnits[m].modifiers[n].items[p].modifier_item_name + "(" + this.itemUnits[m].modifiers[n].items[p].quantity + " X " + parseFloat(this.itemUnits[m].modifiers[n].items[p].price) + ")";
                  }
                }
              } else {
                if (parseFloat(this.itemUnits[m].modifiers[n].quantity)) {
                  if (itemModiferDetail.length > 0) {
                    itemModiferDetail = itemModiferDetail + ", "
                  }

                  itemModiferDetail = itemModiferDetail + this.itemUnits[m].modifiers[n].modifier_name + "(" + this.itemUnits[m].modifiers[n].quantity + " X " + parseFloat(this.itemUnits[m].modifiers[n].price) + ")";
                }
              }
            }
          }
        }
      }

      var objNote = {
        "addNote": formValues.addNote.value,
        "itemModifierDetails": itemModiferDetail,
        "subTotal": this.lblTotalAmount
      }
      Object.assign(this.itemDetails, objNote);

      var itemAddCart = [];
      if (localStorage.getItem(this.locationId + "_items")) {
        itemAddCart = (JSON.parse(localStorage.getItem(this.locationId + "_items")));
      }

      itemAddCart.push(this.itemDetails);

      localStorage.setItem(this.locationId + "_items", JSON.stringify(itemAddCart));
      this.sharedService.sendClickEvent();
      this.resetAddToCart();

      this.toastr.success("Item Added Successfully");
    } else {
      this.toastr.error("Invalid Form");
    }
    this.spinner.hide();
  }

  countItemChange(status) {
    this.spinner.show();
    var value = parseInt($('#itemQuantity').val());

    if (value > 0) {
      if (status == 0) {
        if (value == 1) {
          this.toastr.error("You have to select atleast 1 Qty.");
          this.spinner.hide();
          return;
        }

        value = value - 1;
        $('#itemQuantity').val(value);
        this.itemDetails['product_quantity'] = value;

        // this.itemTotal = parseFloat(this.itemDetails['product_quantity']) * parseFloat(this.itemDetails['price']);
        if (this.isPriceWithTax)
          this.itemTotal = parseFloat(this.itemDetails['product_quantity']) * parseFloat(this.itemDetails['priceWithTax']);
        else
          this.itemTotal = parseFloat(this.itemDetails['product_quantity']) * parseFloat(this.itemDetails['price']);
      } else {
        value = value + 1;
        $('#itemQuantity').val(value);

        this.itemDetails['product_quantity'] = value;
        // this.itemTotal = parseFloat(this.itemDetails['product_quantity']) * parseFloat(this.itemDetails['price']);
        if (this.isPriceWithTax)
          this.itemTotal = parseFloat(this.itemDetails['product_quantity']) * parseFloat(this.itemDetails['priceWithTax']);
        else
          this.itemTotal = parseFloat(this.itemDetails['product_quantity']) * parseFloat(this.itemDetails['price']);
      }

      this.totalPriceCalculation();
    }
    this.spinner.hide();
  }
}

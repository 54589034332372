<div class="osahan-home-page">
    <div class="bg-light">
        <div class="alert alert-info text-center" role="alert" *ngIf="isOrderAvailable == 0">
            <h4>We are accepting orders {{orderTime}}.</h4>
        </div>
        <div class="osahan-checkout mb-5 height-fill">
            <div class="px-3 pt-3 pb-1">
                <h2 class="font-weight-bold m-0 pt-1 cart-resize with-title">Checkout</h2>
            </div>
            <div class="p-3 osahan-cart-item cart-resize">
                <div class="bg-white rounded shadow mb-3 py-2">
                    <h6 class="mb-1 font-weight-bold p-3">Order Summary</h6>
                    <div id="cartdetails">
                        <div class="item-container-single d-flex gold-members align-items-center justify-content-between px-3 py-2 border-bottom row ml-0 mr-0 align-items-center"
                            *ngFor="let items of itemAddCart; let i = index">
                            <div class="media align-items-center addon-grp-checkout col-6 pl-0 pr-0">
                                <div class="media-body addon-option-name">
                                    <div class="d-flex">
                                        <p class="m-0"></p>
                                        <img src="{{ items.types[0].image }}" style="height: 20px; width: 20px;"
                                            class="veg-dot-ss">
                                        <div class="txt-sizing">{{items.name}}
                                            <span style="color: #d9b300;font-weight: 700;" *ngIf="items.SelectIsJain">
                                                (Jain) </span>
                                            <b>({{currencySymbol}} {{items.price}})</b>
                                        </div>
                                    </div>
                                    <!-- <p class="m-0 small addon-det"> Choice : <span class="addon-item-checkout small d-flex pr-2">Sprite, </span>
                                    </p> -->
                                    <p class="m-0 small addon-det">{{items.itemModifierDetails}}</p>
                                </div>
                            </div>
                            <div
                                class="d-flex justify-content-end align-items-center btns-grp-checkout col-6 pl-0 pr-0">
                                <div class="grp-it" style="display: inline-flex;">
                                    <span class="count-number float-left">
                                        <button type="button" class="btn-sm left dec btn btnCart"
                                            (click)="addMinusItem(0, items.item_id,i)"><i
                                                class="feather-minus btnIcon"></i>
                                        </button>
                                        <input class="count-number-input" type="text" name={{i}} style="width: 35px;"
                                            value="{{items.product_quantity}}" id={{i}} disabled>
                                        <button type="button" class="btn-sm right inc btn btnCart"
                                            (click)="addMinusItem(1, items.item_id,i)"><i
                                                class="feather-plus btnIcon"></i>
                                        </button>

                                        <!-- <button type="button" (click)="addMinusItem(1, items.item_id)" 
                                        style="width: 33px; height: 31px; display: flex; flex-direction: row; box-shadow: rgba(0, 0, 0, 0.12) 0px 4px 8px 0px; background-color: rgb(255, 255, 255);
                                        border: 1px solid rgb(255, 211, 68); outline: none; font-size: 1.6rem; border-radius: 4px; padding: 0px; -webkit-box-pack: center; justify-content: center; 
                                        -webkit-box-align: center; align-items: center; transform: rotateY(20deg);">
                                        </button> -->
                                    </span>
                                    <!-- <p class="text-gray mb-0 float-right ml-2 text-muted small price-pop"><b>{{currencySymbol}} {{items.product_quantity * items.price}}</b>
                                    </p> -->
                                    <p
                                        class="text-gray mb-0 float-right ml-2 pl-2 text-muted small price-pop text-right checkout-price">
                                        <b>{{currencySymbol}} {{items.subTotal}}</b>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div
                            class="bg-primary item-container-single gold-members d-flex align-items-center justify-content-between px-3 py-2 border-bottom">
                            <p class="w-100 text-gray mb-0 text-right ml-2 large text-success"><b>{{currencySymbol}} {{
                                    cartTotalAmount }}</b></p>
                        </div>
                        <div class="mb-0 input-group pl-3 pr-3 pt-4 pb-4">
                            <div class="input-group-prepend"><span class="input-group-text"><i
                                        class="feather-message-square"></i></span></div>
                            <textarea placeholder="Add any Special Instructions" aria-label="With textarea"
                                class="form-control" name="SpecialInstructions" id="SpecialInstructions"></textarea>
                            <!-- onblur="return SpecialInstructions();" -->
                        </div>
                        <div id="divAddOns" style="display: none;">
                            <div class="px-3 pt-3 title d-flex align-items-center pb-3">
                                <h5 class="m-0">Addons</h5>
                            </div>

                            <div class="Addons-slider">
                                <div class="osahan-slider-item py-1 px-1 slick-slide"
                                    *ngFor="let item of menuList;let i =index">
                                    <div
                                        class="list-card bg-white h-100 rounded overflow-hidden position-relative shadow-sm">
                                        <div class="list-card-image">
                                            <a href="javascript:void(0);" (click)="getItemDetails(item,i)">
                                                <!-- <img src="{{item.image_url['main_image']}}"
                                                    onerror="this.src='assets/img/logo/logo-jpg.jpg';"
                                                    style="height: 150px;object-fit: fill"
                                                    class="img-fluid item-img w-100"> -->
                                                <div class="fixed-bg"
                                                    [ngStyle]="{ 'background-image': 'url(\'' + item.image_url['main_image'] + '\')' }">
                                                </div>
                                            </a>
                                        </div>
                                        <div class="p-2 position-relative">
                                            <div class="list-card-body">
                                                <div class="dish-contents d-flex">
                                                    <h6 class="mb-1 col-12 pl-0 pr-0">
                                                        <div class="text-black item-nm small">{{item.name}}</div>
                                                        <!-- <div class="size-of-addon small">1 Pieces</div> -->
                                                    </h6>
                                                    <div class="col-12 pl-0 pr-0 pb-2">
                                                        <p class="text-gray mb-0 time">
                                                            <span class="float-left text-black-50 w-100 pb-2"
                                                                *ngIf="isPriceWithTax">
                                                                {{currencySymbol}} {{item.display_inc_tax_price}}/-
                                                            </span>
                                                            <span class="float-left text-black-50 w-100 pb-2"
                                                                *ngIf="!isPriceWithTax">
                                                                {{currencySymbol}} {{item.price}}/-
                                                            </span>
                                                        </p>
                                                    </div><button class="btn btn-primary btn-block btn-sm w-50 m-auto"
                                                        (click)="getItemDetails(item,i)">Add</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Start - Coupon -->
                <div class="mb-3 shadow bg-white rounded p-3 py-1 mt-3 clearfix" style="opacity: 0.3;"
                    data-toggle="modal" data-target="#LoginModal" id="divCouponDisable">
                    <div class="apply-coupon-div row mx-auto">
                        <div class="col-6"><svg width="25px" height="25px" viewBox="0 0 19 11">
                                <path fill="#094f8d"
                                    d="M.634 0C.284 0 0 .274 0 .611v9.778c0 .338.284.611.634.611h17.733c.35 0 .633-.273.633-.611V.611C19 .274 18.716 0 18.367 0H.634zm3.8 1.222c0-.337.284-.611.633-.611.35 0 .634.274.634.611v1.223c0 .337-.284.61-.634.61-.35 0-.633-.273-.633-.61V1.222zm0 3.667c0-.337.284-.61.633-.61.35 0 .634.273.634.61v1.223c0 .337-.284.61-.634.61-.35 0-.633-.273-.633-.61V4.889zm0 3.667c0-.337.284-.61.633-.61.35 0 .634.273.634.61v1.222c0 .338-.284.612-.634.612-.35 0-.633-.274-.633-.612V8.556zm9.5-2.444c.7 0 1.266.547 1.266 1.221 0 .676-.567 1.222-1.266 1.222-.7 0-1.267-.546-1.267-1.222 0-.674.567-1.221 1.267-1.221zm-3.8-3.667c.699 0 1.266.547 1.266 1.222 0 .674-.567 1.221-1.267 1.221-.699 0-1.266-.547-1.266-1.221 0-.675.567-1.222 1.266-1.222zm.504 5.865c-.209.269-.604.323-.883.122-.279-.202-.335-.584-.126-.853l3.8-4.889c.209-.269.604-.323.883-.121.279.201.335.583.126.852l-3.8 4.889z">
                                </path>
                            </svg><span class="ml-1">Apply Coupon</span></div>
                        <div class="col-6 pl-0 pr-0 float-right">
                            <button id="button-addon2" type="button" class="btn btn-primary float-right">View All
                                Coupons</button>
                        </div>
                    </div>
                </div>
                <div class="mb-3 shadow bg-white rounded p-3 py-1 mt-3 clearfix" id="divCouponEnable">
                    <div class="input-group-sm input-group">
                        <div class="apply-coupon-div row mx-auto">
                            <div class="col-6 pl-0 pr-0"><svg width="25px" height="25px" viewBox="0 0 19 11">
                                    <path fill="#094f8d"
                                        d="M.634 0C.284 0 0 .274 0 .611v9.778c0 .338.284.611.634.611h17.733c.35 0 .633-.273.633-.611V.611C19 .274 18.716 0 18.367 0H.634zm3.8 1.222c0-.337.284-.611.633-.611.35 0 .634.274.634.611v1.223c0 .337-.284.61-.634.61-.35 0-.633-.273-.633-.61V1.222zm0 3.667c0-.337.284-.61.633-.61.35 0 .634.273.634.61v1.223c0 .337-.284.61-.634.61-.35 0-.633-.273-.633-.61V4.889zm0 3.667c0-.337.284-.61.633-.61.35 0 .634.273.634.61v1.222c0 .338-.284.612-.634.612-.35 0-.633-.274-.633-.612V8.556zm9.5-2.444c.7 0 1.266.547 1.266 1.221 0 .676-.567 1.222-1.266 1.222-.7 0-1.267-.546-1.267-1.222 0-.674.567-1.221 1.267-1.221zm-3.8-3.667c.699 0 1.266.547 1.266 1.222 0 .674-.567 1.221-1.267 1.221-.699 0-1.266-.547-1.266-1.221 0-.675.567-1.222 1.266-1.222zm.504 5.865c-.209.269-.604.323-.883.122-.279-.202-.335-.584-.126-.853l3.8-4.889c.209-.269.604-.323.883-.121.279.201.335.583.126.852l-3.8 4.889z">
                                    </path>
                                </svg><span class="ml-1">Select offer / Apply coupon</span></div>
                            <div class="col-6 float-right text-right pl-0 pr-0"><button id="button-addon2" type="button"
                                    class="btn btn-primary" (click)="showCoupons()"> View All Coupons </button></div>
                        </div>
                    </div>
                </div>
                <div class="mb-3 shadow bg-white rounded p-3 py-1 mt-3 clearfix" id="divCouponRemove">
                    <div class="input-group-sm input-group">
                        <div class="apply-coupon-div row mx-auto">
                            <div class="col-6 pl-0 pr-0"><svg width="25px" height="25px" viewBox="0 0 19 11">
                                    <path fill="#094f8d"
                                        d="M.634 0C.284 0 0 .274 0 .611v9.778c0 .338.284.611.634.611h17.733c.35 0 .633-.273.633-.611V.611C19 .274 18.716 0 18.367 0H.634zm3.8 1.222c0-.337.284-.611.633-.611.35 0 .634.274.634.611v1.223c0 .337-.284.61-.634.61-.35 0-.633-.273-.633-.61V1.222zm0 3.667c0-.337.284-.61.633-.61.35 0 .634.273.634.61v1.223c0 .337-.284.61-.634.61-.35 0-.633-.273-.633-.61V4.889zm0 3.667c0-.337.284-.61.633-.61.35 0 .634.273.634.61v1.222c0 .338-.284.612-.634.612-.35 0-.633-.274-.633-.612V8.556zm9.5-2.444c.7 0 1.266.547 1.266 1.221 0 .676-.567 1.222-1.266 1.222-.7 0-1.267-.546-1.267-1.222 0-.674.567-1.221 1.267-1.221zm-3.8-3.667c.699 0 1.266.547 1.266 1.222 0 .674-.567 1.221-1.267 1.221-.699 0-1.266-.547-1.266-1.221 0-.675.567-1.222 1.266-1.222zm.504 5.865c-.209.269-.604.323-.883.122-.279-.202-.335-.584-.126-.853l3.8-4.889c.209-.269.604-.323.883-.121.279.201.335.583.126.852l-3.8 4.889z">
                                    </path>
                                </svg><span class="ml-1">Selected Offer / Applied Coupon</span></div>
                            <div class="col-12 coupon-list pt-4 pl-0 pr-0">
                                <div class="applied-coupon">
                                    <div class="coupon-head">
                                        <div class="coupon-code-box">{{promoCodeTitle}}</div>
                                        <a href="javascript:void(0);" class="coupon-apply-btn" (click)="removeCoupon()">
                                            Remove</a>
                                    </div>
                                    <div class="coupon-foot"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- End - Coupon -->
                <div class="shadow bg-white rounded p-3 clearfix mb-5" #divSubTotal>
                    <!-- <p class="mb-1">Discount (-) <span class="float-right text-dark">{{currencySymbol}} 35.00</span></p> -->
                    <!-- <hr> -->
                    <!-- <h6 class=" mb-0 pb-2" style="font-size: 10px;">Round Off <span class="float-right">{{currencySymbol}} 0.40</span></h6> -->
                </div>
                <div #divPlaceOrder></div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade ss-login popup-mini-ss" id="CouponCodesShow" tabindex="-1" role="dialog"
    aria-labelledby="exampleModalLabel" aria-modal="true" style="padding-right: 4px;" data-backdrop="static"
    data-keyboard="false">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Got an Offer / Coupon?</h5>

                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">×</span>
                </button>
            </div>
            <div class="modal-body">
                <!-- <div class="form-row ">
                    <div class="input-group-sm mb-2 input-group">
                        <input placeholder="Enter promo code" type="text" class="form-control" id="couponscode"
                            name="couponscode">
                        <div class="input-group-append">
                            <button id="button-addon2" type="button" class="btn btn-primary"
                                onclick="return couponscodevefiry();"> APPLY</button>
                        </div>
                    </div>
                </div> -->
                <div class="availabel-coupons" *ngIf="couponCount > 0">
                    <div class="pb-1 pt-2">Available Coupons</div>
                    <div class="coupon-list pl-0 pr-0 pt-2" id="couponlist" *ngFor="let promos of promoCodeList">
                        <div class="coupon-item offerCard">
                            <div class="coupon-head">
                                <div class="coupon-code-box">{{promos.title}}</div>
                                <div class="coupon-apply-btn">
                                    <a href="javascript:void(0);"
                                        (click)="applyCoupon(promos.promocode,promos.title,promos.promocode_id)">Apply</a>
                                </div>
                            </div>
                            <div class="coupon-foot"></div>
                        </div>
                    </div>
                </div>
                <div class="availabel-coupons" *ngIf="couponCount == 0">
                    <div class="pb-1 pt-2" style="text-align: center;">No Offers / Coupons Available</div>
                </div>
            </div>

        </div>
    </div>
</div>

<div class="modal fade login-component ss-addon-modal" id="addons-options-modal" role="dialog" tabindex="-1"
    aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog ">
        <div class="modal-content">
            <div class="modal-body">
                <div class="login-block">
                    <button type="button" class="close modal-close" data-dismiss="modal">&times;</button>
                    <div id="adons-options-block">
                        <form id="addtocartform" method="post" autocomplete="false">
                            <div class=""><input type="hidden" id="selected_item_id" value="11340063"><input
                                    type="hidden" id="selected_item_price" value="190"><input type="hidden"
                                    id="selected_menu_id" value="784056"><input type="hidden" id="itemFrom"
                                    value="items"></div>
                            <div class="mx-auto w-100">
                                <div class="bg-primary rounded pt-3 pb-3 shadow-sm mt-3 ml-0 mr-0 w-100">
                                    <!-- <img src="{{itemDetailImage}}" alt="{{itemDetailImage}}"
                                        onerror="this.src='assets/img/logo/logo-jpg.jpg';" style="height: 475px;"
                                        class="img-fluid w-100 p-3"> -->
                                    <div class="fixed-bg"
                                        [ngStyle]="{ 'background-image': 'url(\'' + itemDetailImage + '\')' }">
                                    </div>
                                </div>
                                <div class="bg-primary rounded col-12 pt-3 pb-3 shadow-sm mt-3 row ml-0 mr-0">
                                    <div class="col-6 pl-0 pr-0"><span class="badge badge-success"></span></div>
                                    <div class="col-6 text-right pl-0 pr-0">
                                        <div class="price-tag card-item-price">
                                            {{currencySymbol}}
                                            <span id="hp_final_cost">{{itemDetailPrice}}</span>/-
                                        </div>
                                    </div>
                                    <h6 class="mb-1 font-weight-bold pt-2">{{itemDetailName}}</h6><input type="hidden"
                                        id="totalcost11340063" value="190">
                                    <div class="col-12 pt-1 pb-1 pl-0 pr-0">
                                        <p [innerHTML]="itemDetailDescription"></p>
                                    </div>
                                </div>
                                <!-- *ngIf="itemDetailIsJain == 1" -->
                                <div #divJainDetail>
                                </div>
                            </div>
                            <div class="p-0 border-0 py-1">
                                <div class="col-12 m-0 p-0"><button type="button" class="btn btn-primary add-btn w-100"
                                        (click)="getItemDetails(itemInformations,itemDetailIndex)"
                                        [disabled]="isOrderAvailable == 0">Add to Cart</button></div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade ss-login" id="ItemUnitModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true" data-backdrop="static" data-keyboard="false"
    style="height: 400px; top: 100px; overflow-y: hidden;">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel"><b>Item Unit: </b>{{lblItemName}}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="pt-2">
                    <div class="form-row">
                        <div class="col-md-6 form-group" *ngFor="let units of itemUnits; let i = index">
                            <div class="input-group">
                                <button type="button" class="btn btn-primary btn-lg btn-block"
                                    (click)="getModifiers(i,units.modifiers)"
                                    [disabled]="isOrderAvailable == 0">{{units.unit_name}}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade ss-login" id="ItemAddToCart" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel"><b>{{lblItemName}}</b>
                </h5>
                <button type="button" class="close" (click)="resetAddToCart()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <form #ModifierForm="ngForm" (ngSubmit)="addToCart()">
                    <div id="adons-options-block">
                        <div class="mx-auto w-100">
                            <div class="bg-primary rounded pt-3 pb-3 shadow-sm mt-3 ml-0 mr-0 w-100">
                                <!-- <img src="{{itemDetailImage}}" alt="{{itemDetailImage}}"
                                    onerror="this.src='assets/img/logo/logo-jpg.jpg';" style="height: 475px;"
                                    class="img-fluid w-100 p-3"> -->
                                <div class="fixed-bg"
                                    [ngStyle]="{ 'background-image': 'url(\'' + itemDetailImage + '\')' }">
                                </div>
                            </div>
                            <div class="bg-primary rounded col-12 pt-3 pb-3 shadow-sm mt-3 row ml-0 mr-0">
                                <div class="col-6 pl-0 pr-0"><span
                                        class="badge badge-success">{{lblCategoryName}}</span></div>
                                <div class="col-6 text-right pl-0 pr-0">
                                    <div class="price-tag card-item-price">
                                        {{currencySymbol}}
                                        <span id="hp_final_cost" *ngIf="isPriceWithTax">{{lblItemRateWithTax}}</span>
                                        <span id="hp_final_cost" *ngIf="!isPriceWithTax">{{lblItemRate}}</span>/-
                                    </div>
                                </div>
                                <div class="col-12 pt-1 pl-0 pr-0">
                                    <p [innerHTML]="itemDetailDescription"></p>
                                </div>
                                <div class="col-12 pt-1 pb-1 pl-0 pr-0" *ngFor="let types of itemTypes">
                                    <span>
                                        <img src="{{ types.image }}" style="height: 20px; width: 20px;"
                                            class="veg-dot-ss"><b>{{ types.name}}</b>
                                    </span>
                                </div>
                            </div>
                            <div class="bg-primary rounded col-12 pt-3 pb-3 shadow-sm mt-3 row ml-0 mr-0"
                                *ngIf="itemUnits != undefined && itemUnits.length > 1">
                                <div class="mb-0 col-md-12 form-group mb-3">
                                    <div class="btn-group btn-group-toggle w-100" data-toggle="buttons">
                                        <a *ngFor="let units of itemUnits; let i = index"
                                            class="btn btn-outline-secondary btns-select "
                                            [ngClass]="{ 'active': i == 0 }"
                                            style="border-radius: 30px; margin-left: 10px;" href="javascript:void(0);"
                                            (click)="getModifiers(i,units.modifiers)">
                                            <input type="radio" name="itemunitid{{i}}" data-val="0.00"
                                                class="form-check-input-addon" id="itemunitid{{i}}" value="{{i}}">
                                            <span *ngIf="isPriceWithTax"> {{units.unit_name}} ({{currencySymbol}}
                                                {{units.display_inc_tax_price}})</span>
                                            <span *ngIf="!isPriceWithTax">{{units.unit_name}} ({{currencySymbol}}
                                                {{units.price}})</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div class="bg-primary rounded col-12 pt-3 pb-3 shadow-sm mt-3 row ml-0 mr-0">
                                <div class="col-md-12">
                                    <div class="form-row">
                                        <div class="col-md-8 form-group">
                                            <div class="input-group" style="height: 100%; line-height: 30px;">
                                                <span *ngIf="isPriceWithTax"><b>Item: </b>{{lblItemName}}
                                                    ({{currencySymbol}}
                                                    {{lblItemRateWithTax}})</span>
                                                <span *ngIf="!isPriceWithTax"><b>Item: </b>{{lblItemName}}
                                                    ({{currencySymbol}}
                                                    {{lblItemRate}})</span>
                                            </div>
                                        </div>
                                        <div class="col-md-4 form-group">
                                            <span class="count-number float-right">
                                                <div class="input-group" style="text-align: right; display: block;">
                                                    <button type="button" class="btn-sm left dec btn btnCart"
                                                        id="decrease" value="Decrease Value"
                                                        (click)="countItemChange(0)">
                                                        <i class="feather-minus btnIcon"></i>
                                                    </button>
                                                    <input disabled min="1" max="50" value="1"
                                                        class="count-number-input" id="itemQuantity" type="text">
                                                    <button type="button" class="btn-sm right inc btn btnCart"
                                                        id="increase" value="Increase Value"
                                                        (click)="countItemChange(1)">
                                                        <i class="feather-plus btnIcon"></i>
                                                    </button>
                                                </div>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div #divFormRow>
                                    </div>
                                </div>
                            </div>
                            <div #divJainAddToCart class="pt-3"></div>
                        </div>
                    </div>
                    <div class="form-row pt-3">
                        <div class="col-md-12 form-group">
                            <div class="input-group">
                                <div class="input-group-append">
                                    <span id="button-name" type="button" class="btn btn-outline-secondary"><i
                                            class="feather-edit"></i></span>
                                </div>
                                <textarea #addNote="ngModel" ngModel name="addNote" class="form-control" rows="3"
                                    placeholder="Add note here..."></textarea>
                            </div>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="col-md-12 form-group">
                            <div class="input-group">
                                <button type="submit" class="btn btn-primary btn-block"
                                    [disabled]="isOrderAvailable == 0">Add {{currencySymbol}}
                                    {{lblTotalAmount}}</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<ngx-spinner bdcolor="rgb(255 255 255 / 80%)" size="medium" type="square-jelly-box" template="&nbsp;">
    <div class="loader-box">
        <div class="icon">
            <i class="fas fa-utensils"></i>
        </div>
    </div>
    <!-- <p style="color: white;">Please Wait.</p> -->
</ngx-spinner>
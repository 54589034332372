<style>
    @media screen and (min-width:320px) and (max-width: 575px) {
        .day-detail{
            display: block !important;
            margin: 0 !important;
            margin-bottom: 10px !important;
        }
        .day-detail .title{
            width: 100% !important;
        }
        .day-detail .day-information{
            width: 100% !important;
        }
        .day-detail .day-items{
            justify-content: space-between;
        }
        .day-detail .day-items .day-content{
            width: unset !important;
        }
        pre{
            margin-bottom: 0 !important;
        }
        .panel-description{
            margin: 0 !important;
        }
    }
</style>
<div class="osahan-home-page">
    <div class="bg-light">
        <div class="alert alert-info text-center" role="alert" *ngIf="isOrderAvailable == 0">
            <h4>We are accepting orders {{orderTime}}.</h4>
        </div>
        <div class="osahan-manage-orders">
            <div class="px-3 pt-3 pb-5">
                <h2 class="font-weight-bold m-0 pt-1 cart-resize with-title">My Subscriptions</h2>
            </div>
            <div class="cart-resize">
                <div class="p-3 osahan-cart-item">
                    <div class="osahan-cart-item-profile bg-white rounded shadow p-3 mt-n5">
                        <div class="d-flex flex-column pt-2">
                            <div class="row ml-0 mr-0">
                                <div class="col-lg-12 col-md-12 col-xs-12 m-0" style="text-align: center;"
                                    id="divNoOrders">No Subscriptions Available</div>
                                <div class="col-lg-6 col-md-6 col-xs-12 m-0" *ngFor="let orders of orderList">
                                    <div class="card card-body" style="margin: 15px 0;"
                                        [ngStyle]="{ 'background-color': orders.tiffin_subscription_status == 0 ? '#fff3e2' : (orders.tiffin_subscription_status == 1 ? '#eeffee' : 
                                                (orders.tiffin_subscription_status == 2 ? '#e8faff' : (orders.tiffin_subscription_status == 3 ? '#ffeae9' : (orders.tiffin_subscription_status == 5 ? '#ffffe6' : 
                                                (orders.tiffin_subscription_status == 6 ? '#eeffee' : (orders.tiffin_subscription_status == 7 ? '#e8faff' : 'none')))))) }">
                                        <br>
                                        <!-- <h6 class="card-title"><b>Order No :</b><br> #{{orders.order_id}}_{{orders.external_id}}</h6> -->
                                        <h6 class="card-title"><b>Subscription Name :</b> &nbsp;
                                            {{orders.tiffin_package_name}}</h6>
                                        <h6 class="card-title"><b> Description:</b> &nbsp;
                                            {{orders.tiffin_package_description}}</h6>
                                        <h6 class="card-title"><b> Duration :</b> &nbsp; {{orders.tiffin_start_date}} To
                                            {{orders.tiffin_end_date}}</h6>
                                        <!-- <h6 class="card-title"><b> Subscription Duration :</b> &nbsp; {{orders.tiffin_duration}} {{orders.display_tiffin_duration_type}}</h6> -->
                                        <h6 class="card-title"><b> Grace Period :</b> &nbsp;
                                            {{orders.tiffin_grace_period}} {{orders.display_tiffin_grace_period_type}}
                                        </h6>
                                        <h6 class="card-title"><b> Tiffin Type :</b> &nbsp; {{orders.tiffinType}} </h6>
                                        <h6 class="card-title"><b> Tiffin Days :</b> &nbsp; {{orders.tiffinDays}} </h6>
                                        <hr>
                                        <!-- <p class="card-text"><b>Pay Mode: </b>
                                            <span *ngIf="orders.payment_note != ''"
                                                style="background: #51A351; border: 1px dashed white; padding: 5px; font-family: lato, ubuntu, helvetica, sans-serif; color: white;">
                                                {{orders.payment_note}}
                                            </span>
                                        </p> -->
                                        <!-- <p class="card-text"><b>No. of Items:</b> {{orders.order_items}}</p> -->
                                        <!-- <hr> -->
                                        <h6 class="card-subtitle mb-2 text-muted"><b>Total:</b> {{currencySymbol}}
                                            {{orders.tiffin_price}}</h6>

                                        <p class="card-text" *ngIf="orders.tiffin_subscription_status == 0"
                                            style="color: #ff9500; font-weight: bolder;">
                                            {{orders.display_tiffin_subscription_status}}</p>
                                        <p class="card-text" *ngIf="orders.tiffin_subscription_status == 1"
                                            style="color: #51A351; font-weight: bolder;">
                                            {{orders.display_tiffin_subscription_status}}</p>
                                        <p class="card-text" *ngIf="orders.tiffin_subscription_status == 2"
                                            style="color: #2F96B4; font-weight: bolder;">
                                            {{orders.display_tiffin_subscription_status}}</p>
                                        <p class="card-text" *ngIf="orders.tiffin_subscription_status == 3"
                                            style="color: #bd362f; font-weight: bolder;">
                                            {{orders.display_tiffin_subscription_status}}</p>
                                        <p class="card-text" *ngIf="orders.tiffin_subscription_status == 5"
                                            style="color: #A6A303; font-weight: bolder;">
                                            {{orders.display_tiffin_subscription_status}}</p>
                                        <p class="card-text" *ngIf="orders.tiffin_subscription_status == 6"
                                            style="color: #51A351; font-weight: bolder;">
                                            {{orders.display_tiffin_subscription_status}}</p>
                                        <p class="card-text" *ngIf="orders.tiffin_subscription_status == 7"
                                            style="color: #2F96B4; font-weight: bolder;">
                                            {{orders.display_tiffin_subscription_status}}</p>
                                        <div class="row">
                                        <div class="col-6">
                                        <a href="javascript:void(0);"
                                            class="card-link btn btn-primary btn-block button_slide slide_right"
                                            (click)="getTiffinSubscriptionOrderList(orders.user_subscription_hash);">Order Details</a>
                                        </div>
                                        <div class="col-6">
                                            <a href="javascript:void(0);"
                                            class="card-link btn btn-primary btn-block button_slide slide_right"
                                            (click)="getTiffinPackageList(orders.user_subscription_hash);">Package Detail</a>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade ss-orderlist" id="OrderList" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true" data-backdrop="static" data-keyboard="false" style="overflow-y: auto;">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel"><b>Order List</b>
                </h5>
                <button type="button" class="close" (click)="closeMainModal()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="row ml-0 mr-0">
                    <div class="col-lg-12 col-md-12 col-xs-12">
                        <div class="table-responsive">
                        <table class="table  table-striped table-hover table-bordered">
                            <thead>
                                <th>Order date</th>
                                <th>Order no.</th>
                                <th>Invoice no.</th>
                                <th>K.O.T. no.</th>
                                <th>Order type</th>
                                <th>Subscription status</th>
                                <th>Staff name</th>
                                <th>Order delivery date</th>
                                <th>Action</th>
                            </thead>
                            <tbody *ngIf="subscriptionOrderList.length > 0">
                                <tr *ngFor="let orderList of subscriptionOrderList">
                                    <td>{{orderList.order_serve_date}}</td>
                                    <td>{{orderList.order_id}}</td>
                                    <td>{{orderList.foliono}}</td>
                                    <td>{{orderList.kot_no}}</td>
                                    <td>{{orderList.display_tiffin_order_type}}</td>
                                    <td>{{orderList.display_foliostatus}}</td>
                                    <td>{{orderList.staff_name}}</td>
                                    <td>{{orderList.order_reschedule_date}}</td>
                                    <td>
                                        <span style="text-align: right; width: 50%;" *ngIf="orderList.is_reschedule == 1 && orderList.is_reschedule_order != 1 ">
                                            <a href="javascript:void(0)" (click)="reScheduleOrder(orderList)">Reschedule
                                                order</a>
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody *ngIf="subscriptionOrderList.length == 0">
                                <tr>
                                    <td colspan="9" style="text-align: center;"> No record found</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>



<div class="modal fade ss-login" id="reScheduleOrderModal" tabindex="-1" role="dialog" style="height: 250px;"
    aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog modal-sm">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title"><b>Reschedule Order</b>
                </h5>
                <button type="button" class="close" (click)="closeAllModal()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <form (submit)="reScheduleOrderSubmit()">
                    <div class="row ml-0 mr-0">
                        <div class="col-md-12 form-group">
                            <label class="form-label">Reschedule Date</label>
                            <input type="date" name="reScheduleDate" class="form-control" placeholder="Enter Date"
                                onkeydown="return false" id="reScheduleDate" autocomplete="off" required>
                        </div>
                        <button type="submit" class="btn btn-primary btn-block">Submit</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

<div class="modal fade ss-login" id="ItemAddToCart" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true" data-backdrop="static" data-keyboard="false" style="overflow-y: auto;">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel"><b>{{lblItemName}}</b>
                </h5>
                <button type="button" class="close" (click)="resetAddToCart()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <!-- <form #ModifierForm="ngForm" name="ModifierForm" (ngSubmit)="addToCart()"> -->
                    <div id="adons-options-block">
                        <div class="mx-auto w-100">
                            <div class="bg-primary rounded pt-3 pb-3 shadow-sm mt-3 ml-0 mr-0 w-100">
                                <img src="{{itemDetailImage}}" alt="{{itemDetailImage}}"
                                    onerror="this.src='assets/img/logo/logo1.jpg';" style="max-height: 475px;"
                                    class="img-fluid w-100 p-3">
                            </div>
                            <div class="bg-primary rounded col-12 pt-3 pb-3 shadow-sm mt-3 row ml-0 mr-0">
                                <div class="col-6 pl-0 pr-0"><span class="badge badge-success">Subscription</span></div>
                                <div class="col-6 text-right pl-0 pr-0">
                                    <div class="price-tag card-item-price">
                                        {{currencySymbol}}
                                        <span id="hp_final_cost">{{lblItemRate}}</span>/-
                                    </div>
                                </div>
                                <div class="col-12 pt-1 pl-0 pr-0">
                                    <p [innerHTML]="itemDetailDescription"></p>
                                </div>
                                <!-- <div class="col-12 pt-1 pb-1 pl-0 pr-0" *ngFor="let types of itemTypes">
                                    <span>
                                        <img src="{{ types.image }}" style="height: 20px; width: 20px;"
                                            class="veg-dot-ss"><b>{{ types.name}}</b>
                                    </span>
                                </div> -->
                            </div>
                            <!-- <div class="bg-primary rounded col-12 pt-3 pb-3 shadow-sm mt-3 row ml-0 mr-0"
                                *ngIf="itemUnits != undefined && itemUnits.length > 1">
                                <div class="mb-0 col-md-12 form-group mb-3">
                                    <div class="btn-group btn-group-toggle w-100" data-toggle="buttons">
                                        <a *ngFor="let units of itemUnits; let i = index" id="units{{i}}"
                                            class="btn btn-outline-secondary btns-select "
                                            [ngClass]="{ 'active': i == 0 }"
                                            style="border-radius: 30px; margin-left: 10px;" href="javascript:void(0);"
                                            (click)="getModifiers(i,units.modifiers)">
                                            <input type="radio" name="itemunitid{{i}}" data-val="0.00"
                                                class="form-check-input-addon" id="itemunitid{{i}}" value="{{i}}">
                                            {{units.unit_name}} ({{currencySymbol}} {{units.price}})
                                        </a>
                                    </div>
                                </div>
                            </div> -->
                            <div class="bg-primary rounded col-12 pt-3 pb-3 shadow-sm mt-3 row ml-0 mr-0">
                                <div class="col-md-12">
                                    <div class="form-row">
                                        <div class="col-md-8 form-group">
                                            <div class="input-group" style="line-height: 30px;">
                                                <span><b>Subscription: </b>{{lblItemName}} ({{currencySymbol}}
                                                    {{lblItemRate}})</span>
                                            </div>
                                            <div class="input-group" style="line-height: 30px;">
                                                <span><b>Duration: </b> {{itemDetails["tiffin_duration"]}} {{itemDetails["display_tiffin_duration_type"]}}
                                                </span>
                                            </div>
                                            <div class="input-group" style="line-height: 30px;">
                                                <span><b>Grace period: </b> {{itemDetails["tiffin_grace_period"]}}
                                                    {{itemDetails["display_tiffin_grace_period_type"]}} </span>
                                            </div>
                                            <div class="input-group" style="line-height: 30px;">
                                                <span><b>Tiffin type: </b> {{itemDetails["tiffinType"]}} </span>
                                                <!-- <span><b>Tiffin type: </b> {{itemDetails["tiffine_type"]}} </span> -->
                                            </div>
                                        </div>
                                        <!-- <div class="col-md-4 form-group">
                                            <span class="count-number float-right">
                                                <div class="input-group" style="text-align: right; display: block;">
                                                    <button type="button" class="btn-sm left dec btn btnCart"
                                                        id="decrease" value="Decrease Value"
                                                        (click)="countItemChange(0)">
                                                        <i class="feather-minus btnIcon"></i>
                                                    </button>
                                                    <input disabled min="1" max="50" value="1"
                                                        class="count-number-input" id="itemQuantity" type="text">
                                                    <button type="button" class="btn-sm right inc btn btnCart"
                                                        id="increase" value="Increase Value"
                                                        (click)="countItemChange(1)">
                                                        <i class="feather-plus btnIcon"></i>
                                                    </button>
                                                </div>
                                            </span>
                                        </div> -->
                                    </div>
                                </div>
                                <!-- <div class="col-md-12">
                                    <div #divFormRow>
                                    </div>
                                </div> -->
                            </div>
                            <div class="bg-primary rounded col-12 pt-3 pb-3 shadow-sm mt-3 row ml-0 mr-0">
                                <div class="col-md-12">
                                    <div class="form-row">
                                        <div class="col-md-12 form-group">
                                            <!-- itemDetails.week_item_data -->
                                            <div class="input-group" style="line-height: 30px;width: 100%;"
                                                *ngFor="let week of itemDetails['week_type_item_data']; let i = index">
                                                <span style="float: left;width: 50%;"><b>{{week.weekname}}: </b></span>
                                                <span style="text-align: right; width: 50%;">
                                                    <a href="javascript:void(0)"
                                                        (click)="viewWeeklyItemDetail(week)">View Details</a>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- <div #divJainAddToCart class="pt-3"></div> -->
                        </div>
                    </div>
                   
                <!-- </form> -->
            </div>
        </div>
    </div>
</div>

<div class="modal fade ss-addon-modal" id="weeklyItemDetail" role="dialog" tabindex="-1" style="min-height: 200px;"
    aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog ">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title"><b>Item Details - {{weekname}}</b></h5>
                <button type="button" class="close" (click)="weeklyItemDetailClose()">
                    <span aria-hidden="true">&times;</span>
                </button>
                <!-- <button type="button" class="close modal-close" data-dismiss="modal">&times;</button> -->

            </div>
            <div class="modal-body" style="max-height: 95vh !important;overflow-y: scroll !important;">
                <div class="bg-primary rounded col-12 pt-3 pb-3 shadow-sm mt-3 row ml-0 mr-0">
                    <div class="accordian-section" style="width:100%">
                        <div class="panel-group" id="accordion" role="tablist"
                            aria-multiselectable="true">
                    <div class="col-md-12">
                        <div class="form-row">
                            <div class="col-md-12 form-group " >
                                <!-- itemDetails.week_item_data -->
                                <div class="day-detail" style="line-height: 30px; border: thin solid black;padding: 10px;margin: 10px;display: flex;"
                                    *ngFor="let item of Weekly_item_detail; let i = index">
                                    <span style="float: left;width: 20%;" class="title"><b>{{item.type_name}} </b></span>
                                    <div style="width:80%" class="day-information">
                                    <ng-container *ngFor="let itemData of item['itemdata']">
                                        <!-- <pre> 
                                            <code>
                                               {{ itemData | json }}
                                            </code>
                                          </pre> -->
                                 
                                          <div style="padding:5px 5px 5px 5px;display:flex;" class="day-items">
                                            <div class="day-content"
                                                style="float: left;width:50%;">
                                                {{itemData.itemname}}
                                               
                                            </div>

                                            <div class="day-content"
                                                style="float:left;width:20%;">

                                                Qty:
                                                {{itemData.qty}}

                                            </div>
                                    <div class="day-content" style="float: right; width:30%;">
                                    <a role="button" class="btn btn-primary" data-toggle="collapse" data-parent="#accordion" href="#collapse_{{itemData.week_name}}_{{itemData.itemtype_name}}_{{itemData.itemid}}" aria-expanded="false" aria-controls="collapse_{{itemData.week_name}}_{{itemData.itemid}}"> View Details</a></div>
                                    </div>
                                    <div id="collapse_{{itemData.week_name}}_{{itemData.itemtype_name}}_{{itemData.itemid}}"
                                    class="panel-collapse collapse "
                                    role="tabpanel"
                                    >
                                    <div
                                        class="panel-body">
                                        <div class="panel-description"
                                            style="line-height: 30px; border: thin solid black;padding: 10px;margin: 0px !important;display: inline-flex;width:100%;">
                                            <pre
                                                *ngIf='itemData.item_description !=""'>{{itemData.item_description}}</pre>
                                            <pre
                                                *ngIf='itemData.item_description ==""'>No Description Found!</pre>
                                        </div>
                                    </div>
                                </div>
                                    </ng-container>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
            </div>
        </div>
    </div>
</div>
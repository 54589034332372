<style>
   @media screen and (min-width:320px) and (max-width: 575px) {
        .day-detail{
            display: block !important;
            margin: 0 !important;
            margin-bottom: 10px !important;
        }
        .day-detail .title{
            width: 100% !important;
        }
        .day-detail .day-information{
            width: 100% !important;
        }
        .day-detail .day-items{
            justify-content: space-between;
        }
        .day-detail .day-items .day-content{
            width: unset !important;
        }
        pre{
            margin-bottom: 0 !important;
        }
        .panel-description{
            margin: 0 !important;
        }
    }
</style>
<div class="osahan-home-page">
    <div class="bg-light" id="divMenuItem">
        <!-- <div class="alert alert-info text-center" role="alert" *ngIf="isOrderAvailable == 0">
            <h4>We are accepting orders {{orderTime}}.</h4>
        </div> -->
        <div class="cart-resize" style="width:100% !important;">
            <div class="px-3 pt-3 pb-1">
                <h2 class="font-weight-bold m-0 pt-1 with-title">Subscription Detail</h2>
            </div>
            <div class="bg-light mb-4 p-3 osahan-cart-item cart-resize mb-5 pb-3" style="width:100% !important;">
                <div class="bg-white rounded shadow mt-3 profile-details row ml-0 mr-0 p-3 mb-5">
                    <div style="width:100% !important">
                        <form #ModifierForm="ngForm" name="ModifierForm" (ngSubmit)="addToCart()">
                            <div id="adons-options-block row">
                                <!-- <div class="col-12">
                                <h4 class="font-weight-bold m-0 pt-1 with-title"> {{lblItemName}} Subscription</h4>
                            </div> -->
                                <div class="mx-auto w-100 col-md-12">
                                    <div class="row">
                                        <div
                                            class="bg-primary rounded pt-3 pb-3 shadow-sm mt-3 ml-0 mr-0 w-100 col-md-4">
                                            <img src="{{itemDetailImage}}" alt="{{itemDetailImage}}"
                                                onerror="this.src='assets/img/logo/logo1.jpg';"
                                                style="max-height: 475px;" class="img-fluid w-100 p-3">
                                        </div>
                                        <div class="col-md-8">

                                            <div
                                                class="bg-primary rounded col-12 pt-3 pb-3 shadow-sm mt-3 row ml-0 mr-0">
                                                <div class="col-6 pl-0 pr-0">
                                                    <!-- <span class="badge badge-success">Subscription</span> -->
                                                    <h4 class="font-weight-bold m-0 pt-1 with-title"> {{lblItemName}}
                                                        Subscription</h4>
                                                </div>
                                                <div class="col-6 text-right pl-0 pr-0">
                                                    <div class="price-tag card-item-price">
                                                        {{currencySymbol}}
                                                        <span id="hp_final_cost">{{lblItemRate}}</span>/-
                                                    </div>
                                                </div>
                                                <!-- <div class="col-12 pt-1 pl-0 pr-0">
                                                <p [innerHTML]="itemDetailDescription"></p>
                                            </div> -->
                                            </div>
                                            <ng-container *ngIf="itemDetailDescription">
                                                <div
                                                    class="bg-primary rounded col-12 pt-3 pb-3 shadow-sm mt-3 row ml-0 mr-0">
                                                    <h6 class="font-weight-bold m-0 pt-1 with-title"> Description </h6>
                                                    <div class="col-12 pt-1 pl-0 pr-0">
                                                        <p [innerHTML]="itemDetailDescription"></p>
                                                    </div>
                                                </div>
                                            </ng-container>
                                            <div
                                                class="bg-primary rounded col-12 pt-3 pb-3 shadow-sm mt-3 row ml-0 mr-0">
                                                <div class="col-md-12">
                                                    <div class="form-row">
                                                        <div class="col-md-12 form-group row">
                                                            <!-- <div class="input-group" style="line-height: 30px;">
                                                            <span><b>Subscription: </b>{{lblItemName}} ({{currencySymbol}}
                                                                {{lblItemRate}})</span>
                                                        </div> -->
                                                            <div class="input-group col-md-4 col-lg-4 col-xs-12"
                                                                style="line-height: 30px;">
                                                                <span><b>Duration: </b> {{itemDetails["duration"]}}
                                                                    {{itemDetails["text_duration_type"]}}
                                                                </span>
                                                            </div>
                                                            <div class="input-group col-md-4 col-lg-4 col-xs-12"
                                                                style="line-height: 30px;">
                                                                <span><b>Grace period: </b>
                                                                    {{itemDetails["grace_period"]}}
                                                                    {{itemDetails["text_grace_period_type"]}} </span>
                                                            </div>
                                                            <div class="input-group col-md-4 col-lg-4 col-xs-12"
                                                                style="line-height: 30px;">
                                                                <span><b>Tiffin type: </b>
                                                                    {{itemDetails["tiffine_type"]}} </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                    <div class="bg-primary rounded col-12 pt-3 pb-3 shadow-sm mt-3 row ml-0 mr-0">
                                        <div class="col-md-12">
                                            <div class="form-row row">
                                                <div class="col-md-12 form-group">
                                                    <h4 class="font-weight-bold m-0 pt-1 with-title">Menu
                                                        List</h4>
                                                </div>
                                                <div class="col-md-12 form-group">
                                                    <div class="accordian-section">
                                                        <div class="panel-group" id="accordion" role="tablist"
                                                            aria-multiselectable="true">
                                                            <div class="row">
                                                                <ng-container
                                                                    *ngFor="let week of itemdatas; let i = index">
                                                                    <!-- <pre> 
                                                                        <code>
                                                                           {{ week | json }}
                                                                        </code>
                                                                      </pre> -->
                                                                    <div class="col-lg-6 col-md-6">
                                                                        <div class="panel panel-default">
                                                                            <div class="panel-heading" role="tab"
                                                                                id="heading{{i}}">
                                                                                <h4 class="panel-title">
                                                                                    <a role="button"
                                                                                        data-toggle="collapse"
                                                                                        data-parent="#accordion"
                                                                                        href="#collapse{{i}}"
                                                                                        aria-expanded="false"
                                                                                        aria-controls="collapse{{i}}">
                                                                                        {{week.week_name}}
                                                                                    </a>
                                                                                </h4>
                                                                            </div>

                                                                            <div id="collapse{{i}}"
                                                                                class="panel-collapse collapse "
                                                                                role="tabpanel"
                                                                                aria-labelledby="heading{{i}}">
                                                                                <div class="panel-body">
                                                                                    <!-- <pre> 
                                                                                        <code>
                                                                                           {{ week.item_type | json }}
                                                                                        </code>
                                                                                      </pre> -->
                                                                                    <div class="day-detail"
                                                                                        style="line-height: 30px; border: thin solid black;padding: 10px;margin: 10px;"
                                                                                        *ngFor="let itemdata of week.item_type; let i = index">
                                                                                        <!-- <pre> 
                                                                                            <code>
                                                                                               {{ itemdata | json }}
                                                                                            </code>
                                                                                          </pre> -->
                                                                                          <div style="width:100% !important">
                                                                                        <span
                                                                                            style="float: left;width: 30%;" class="title"><b>{{itemdata.item_type_name}}
                                                                                            </b></span>
                                                                                        <div style="width:100%" class="day-information">
                                                                                            
                                                                                          
                                                                                            <ng-container
                                                                                                *ngFor="let item_data of itemdata.item_data;let j=index">
                                                                                                <div style="padding:5px 5px 5px 5px;display:flex;" class="day-items">
                                                                                                    <div class="day-content"
                                                                                                        style="float: left;width:60%;">
                                                                                                        {{item_data.itemname}}
                                                                                                       
                                                                                                    </div>

                                                                                                    <div class="day-content"
                                                                                                        style="float:left;width:20%;">

                                                                                                        Qty:
                                                                                                        {{item_data.qty}}

                                                                                                    </div>
                                                                                                   
                                                                                                    <!-- <div class="day-content"
                                                                                                        style="float: right; width:20%;">
                                                                                                        <a role="button"
                                                                                                            class="btn btn-primary "
                                                                                                            data-toggle="collapse"
                                                                                                            data-parent="#accordion"
                                                                                                            href="#collapse_{{item_data.text_week}}_{{item_data.display_item_type}}_{{item_data.itemid}}_{{j}}"
                                                                                                            aria-expanded="false"
                                                                                                            aria-controls="collapse_{{item_data.text_week}}_{{item_data.display_item_type}}_{{item_data.itemid}}_{{j}}">
                                                                                                            View Details
                                                                                                        </a>

                                                                                                    </div> -->
                                                                                                </div>
                                                                                               
                                                                                                    <!-- <div id="collapse_{{item_data.text_week}}_{{item_data.display_item_type}}_{{item_data.itemid}}_{{j}}"
                                                                                                        class="panel-collapse collapse "
                                                                                                        role="tabpanel"
                                                                                                        aria-labelledby="heading{{i}}">
                                                                                                        <div
                                                                                                            class="panel-body">
                                                                                                            <div class="panel-description"
                                                                                                                style="line-height: 30px; border: thin solid black;padding: 10px;margin: 0px !important;display: inline-flex;width:100%;">
                                                                                                                <pre
                                                                                                                    *ngIf='item_data.item_description !=""'>{{item_data.item_description}}</pre>
                                                                                                                <pre
                                                                                                                    *ngIf='item_data.item_description ==""'>No Description Found!</pre>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div> -->
                                                                                                
                                                                                            </ng-container>

                                                                                        </div>
                                                                                    </div>
                                                                                        <ng-container
                                                                                        *ngFor="let item_data of itemdata.item_data;let j=index">
                                                                                        
                                                                                        <div 
                                                                                        style="width:100%;">
                                                                                            <pre
                                                                                            *ngIf='item_data.item_description !=""'>{{item_data.item_description}}</pre>
                                                                                        <pre
                                                                                            *ngIf='item_data.item_description ==""'>No Description Found!</pre>
                                                                                    
                                                                                        </div>
                                                                                        </ng-container>
                                                                                        <!-- <table>
                                                                            <ng-container *ngFor ="let itemdata of itemdatas" >
                                                                            <tr>    
                                                                                <td>
                                                                                    <p class="title"><b> {{itemdata.item_type_name}} </b></p>
                                                                                </td>
                                                                                <td>
                                                                                    <div *ngFor="let item_data of itemdata.item_data" class='row'>
                                                                                        <div class="col-md-6">
                                                                                        <p class="title"> {{item_data.itemname}} </p>
                                                                                        </div>
                                                                                        <div class="col-md-6">
                                                                                        <p class="title"> QTY: <span class="value">{{item_data.qty}}</span> </p>
                                                                                        </div>
                                                                                    </div>
                                                                                    <!-- <p class="title"> {{itemdata.itemname}} </p> 
                                                                                </td>
                                                                                <!-- <td>
                                                                                    <p class="title"> QTY: <span class="value">{{itemdata.qty}}</span> </p>
                                                                                </td> 
                                                                            </tr>
                                                                        </ng-container>
                                                                        </table> -->
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </ng-container>
                                                                <!-- <div class="col-lg-6 col-md-6">
                                                                <div class="panel panel-default">
                                                                    <div class="panel-heading" role="tab" id="headingTwo">
                                                                      <h4 class="panel-title">
                                                                      <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                                        Friday
                                                                      </a>
                                                                    </h4>
                                                                    </div>
                                                                    <div id="collapseTwo" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingTwo">
                                                                      <div class="panel-body">
                                                                        <table>
                                                                            <tr>    
                                                                                <td>
                                                                                    <p class="title"><b> Rice </b></p>
                                                                                </td>
                                                                                <td>
                                                                                    <p class="title"> Breakfast </p>
                                                                                </td>
                                                                                <td>
                                                                                    <p class="title"> QTY: <span class="value">15</span> </p>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>    
                                                                                <td>
                                                                                    <p class="title"><b> Rice </b></p>
                                                                                </td>
                                                                                <td>
                                                                                    <p class="title"> Lunch </p>
                                                                                </td>
                                                                                <td>
                                                                                    <p class="title"> QTY: <span class="value">10</span> </p>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>    
                                                                                <td>
                                                                                    <p class="title"><b> paneer Tika with gravy </b></p>
                                                                                </td>
                                                                                <td>
                                                                                    <p class="title"> Dinner </p>
                                                                                </td>
                                                                                <td>
                                                                                    <p class="title"> QTY: <span class="value">1</span> </p>
                                                                                </td>
                                                                            </tr>
                                                                        </table>
                                                                      </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-6 col-md-6">
                                                                <div class="panel panel-default">
                                                                    <div class="panel-heading" role="tab" id="headingThree">
                                                                      <h4 class="panel-title">
                                                                      <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                                        Monday
                                                                      </a>
                                                                    </h4>
                                                                    </div>
                                                                    <div id="collapseThree" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingThree">
                                                                      <div class="panel-body">
                                                                        <table>
                                                                            <tr>    
                                                                                <td>
                                                                                    <p class="title"><b> Rice </b></p>
                                                                                </td>
                                                                                <td>
                                                                                    <p class="title"> Breakfast </p>
                                                                                </td>
                                                                                <td>
                                                                                    <p class="title"> QTY: <span class="value">15</span> </p>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>    
                                                                                <td>
                                                                                    <p class="title"><b> Rice </b></p>
                                                                                </td>
                                                                                <td>
                                                                                    <p class="title"> Lunch </p>
                                                                                </td>
                                                                                <td>
                                                                                    <p class="title"> QTY: <span class="value">10</span> </p>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>    
                                                                                <td>
                                                                                    <p class="title"><b> paneer Tika with gravy </b></p>
                                                                                </td>
                                                                                <td>
                                                                                    <p class="title"> Dinner </p>
                                                                                </td>
                                                                                <td>
                                                                                    <p class="title"> QTY: <span class="value">1</span> </p>
                                                                                </td>
                                                                            </tr>
                                                                        </table>
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                            </div> -->
                                                            </div>



                                                        </div>
                                                    </div>
                                                    <!-- itemDetails.week_item_data -->
                                                    <!-- <div class="input-group" style="line-height: 30px;width: 100%;"
                                                    *ngFor="let week of weeklyItemDetails; let i = index">
                                                    <div class="bg-primary rounded col-12 pt-3 pb-3 shadow-sm mt-3 row ml-0 mr-0">
                                                    <span style="float: left;width: 50%;"><b>{{week.week_name}}: </b></span>
                                                    <ng-container *ngFor ="let itemdata of week.item_detail" >
                                                    <div class="row col-md-8">
                                                    <div class="col-md-4">
                                                    
                                                        <b>{{itemdata.itemname}} </b> 
                                                    </div>
                                                    <div class="col-md-4">{{itemdata.display_item_type}}</div>
                                                    <div class="col-md-4">     
                                                        Qty :{{itemdata.qty}} 
                                                    </div>
                                                    
                                                    </div>
                                                    </ng-container>
                                                </div>
                                                </div> -->
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <div #divJainAddToCart class="pt-3"></div> -->
                                    <div class="form-detail-section bg-primary mt-3 shadow-sm">
                                        <div class="row">
                                            <div class="col-lg-4 col-md-4 ">
                                                <div class="form-group">
                                                    <div class="input-group">
                                                        <div class="input-group-append">
                                                            <span id="button-name" type="button"
                                                                class="btn btn-outline-secondary"><i
                                                                    class="feather-edit"></i></span>
                                                        </div>
                                                        <textarea #addNote="ngModel" ngModel name="addNote" id="addNote"
                                                            class="form-control" rows="3"
                                                            placeholder="Add note here..."></textarea>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-4 col-md-4">
                                                <div class="form-group">
                                                    <label> Subscription start date</label>
                                                    <div class="input-group">
                                                        <div class="input-group-append">
                                                            <span id="button-name" type="button"
                                                                class="btn btn-outline-secondary"><i
                                                                    class="feather-calendar"></i></span>
                                                        </div>
                                                        <input type="date" name="startDate" class="form-control"
                                                            placeholder="Enter Date" onkeydown="return false"
                                                            id="startDate" autocomplete="off" required>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-4 col-md-4">
                                                <div class="form-group">
                                                    <div class="input-group btn-section">
                                                        <button type="submit" class="btn btn-primary btn-block"
                                                            [disabled]="isOrderAvailable == 0">BUY {{currencySymbol}}
                                                            {{lblTotalAmount}}</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="form-row pt-3">
                            <div class="col-md-12 form-group">
                                <div class="input-group">
                                    <div class="input-group-append">
                                        <span id="button-name" type="button" class="btn btn-outline-secondary"><i
                                                class="feather-edit"></i></span>
                                    </div>
                                    <textarea #addNote="ngModel" ngModel name="addNote" id="addNote" class="form-control"
                                        rows="3" placeholder="Add note here..."></textarea>
                                </div>
                            </div>
                        </div>
                        <div class="form-row pt-3">
                            <div class="col-md-12 form-group">
                                <label> Subscription start date</label><br>
    
                                <div class="input-group">
                                    <div class="input-group-append">
                                        <span id="button-name" type="button" class="btn btn-outline-secondary"><i
                                                class="feather-calendar"></i></span>
                                    </div>
                                    <input type="date" name="startDate" class="form-control" placeholder="Enter Date"
                                        onkeydown="return false" id="startDate" autocomplete="off" required>
                                </div>
                            </div>
                        </div>
                        <div class="form-row pt-3">
                            <div class="col-md-12 form-group">
                                <div class="input-group">
                                    <button type="submit" class="btn btn-primary btn-block"
                                        [disabled]="isOrderAvailable == 0">BUY {{currencySymbol}}
                                        {{lblTotalAmount}}</button>
                                </div>
                            </div>
                        </div> -->
                        </form>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade ss-addon-modal" id="ItemDescription" role="dialog" tabindex="-1" style="min-height: 200px;"
    aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog ">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title"><b>Item Description - {{itemname}}</b></h5>
                <button type="button" class="close" (click)="ItemDescriptionClose()">
                    <span aria-hidden="true">&times;</span>
                </button>
                <!-- <button type="button" class="close modal-close" data-dismiss="modal">&times;</button> -->

            </div>
            <div class="modal-body">
                <div class="bg-primary rounded col-12 pt-3 pb-3 shadow-sm mt-3 row ml-0 mr-0">
                    <div class="col-md-12">
                        <div class="form-row">
                            <div class="col-md-12 form-group">
                                <!-- itemDetails.week_item_data -->
                                <div class="">
                                    <pre>{{itemdescription}}</pre>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ngx-spinner bdcolor="rgb(255 255 255 / 80%)" size="medium" type="square-jelly-box" template="&nbsp;">
    <div class="loader-box">
        <div class="icon">
            <i class="fas fa-utensils"></i>
        </div>
    </div>
    <!-- <p style="color: white;">Please Wait.</p> -->
</ngx-spinner>
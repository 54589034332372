import { Component, OnInit, resolveForwardRef, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { NgForm, Validators } from '@angular/forms';
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService, NgxSpinnerComponent } from 'ngx-spinner';
import { Router, ActivatedRoute } from "@angular/router";
import { CookieService } from 'ngx-cookie-service';
import { SubscriptionService } from '../manage-subscriptions/manage-subscriptions.service';
import { environment } from 'src/environments/environment';
import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
declare var $: any;
import * as moment from 'moment';
@Component({
  selector: 'app-manage-subscription-detail',
  templateUrl: './manage-subscription-detail.component.html',
  styleUrls: ['./manage-subscription-detail.component.css']
})
export class ManageSubscriptionDetailComponent implements OnInit {
  @ViewChild('ModifierForm') ModifierForm: NgForm;
  currencySymbol: string;
  cookieValue: string;
  locationId: string;
  tomorrow: string;
  locationData: any;
  isOrderAvailable: any;
  orderTime: string;
  SubscriptionPackageId: any;
  SubscriptionData: any;
  itemDetails: any;
  lblItemName: any;
  itemDetailPrice: any;
  itemDetailName: any;
  itemDetailDescription: any;
  itemDetailImage: any;
  itemUnits: any;
  lblItemRate: any;
  itemDetailTypeImage: any;
  lblTotalAmount: any;
  weeklyItemDetails: any;
  weekname: any;
  Weekly_item_detail: any;
  itemdatas: any;
  itemname: any;
  itemdescription: any;
  

  constructor(private toastr: ToastrService, private router: Router, private cookieService: CookieService, private spinner: NgxSpinnerService, private service: SubscriptionService,private rout: ActivatedRoute) { }

  ngOnInit(): void {
    this.currencySymbol = environment.currencySymbol;
    // this.SubscriptionPackageId= this.rout.snapshot.paramMap.get('id');
    this.SubscriptionData=JSON.parse(localStorage.getItem('itemDetail'));
    //console.log.log(this.SubscriptionPackageId);
    //console.log.log(this.SubscriptionData);
    // if(typeof this.SubscriptionPackageId=='undefined' && this.SubscriptionPackageId==null && this.SubscriptionPackageId == '')
    // {
    //   this.router.navigate(['/managesubscriptions']);
    // }
    if(typeof this.SubscriptionData=='undefined' && this.SubscriptionData==null && this.SubscriptionData == '')
    {
      this.router.navigate(['/managesubscriptions']);
    }
    this.cookieValue = this.cookieService.get("location");
    if (this.cookieValue)
      this.locationId = this.cookieValue;

    if (this.cookieService.get("location")) {
      this.getLocationDetail(this.cookieService.get("location"));
    }
    
    this.dataload();
    this.viewWeeklyItemDetail();
  }
  getMenu(obj) {
    return {
      display_item_type: obj.display_item_type,
      subMenu: [this.getSubMenu(obj)]
    };
  }

  getSubMenu(obj) {
    return {
      display_item_type: obj.display_item_type,
      itemid: obj.itemid,
      itemname: obj.itemname,
      itemtype: obj.itemtype,
      qty: obj.qty,
      text_week: obj.text_week,
      tiffinpackageid: obj.tiffinpackageid,
      weekid: obj.weekid
    };
  }
  dataload()
  {
    // alert('here');
    var itemDetail=this.SubscriptionData
    this.lblItemName = itemDetail.name;
    this.itemDetails = itemDetail;
    this.itemDetailPrice = this.itemDetails['price'];
    this.itemDetailName = this.itemDetails['name'];
    this.itemDetailDescription = this.itemDetails['description'];
    this.itemDetailImage = this.itemDetails['tiffinpackageimageurl'];
    this.itemUnits = itemDetail.units;
    this.lblItemName = itemDetail.name;
    this.lblItemRate = itemDetail.price;
    this.itemDetailTypeImage = this.itemDetailImage;
    this.lblTotalAmount = this.itemDetailPrice;
    this.tomorrow = moment(new Date())
    .add(1, 'days')
    .format('YYYY-MM-DD');

  $('#startDate').val(this.tomorrow);
  $('#startDate').attr('min', this.tomorrow);
  }
 
  viewWeeklyItemDetail() {
  // console.log(this.SubscriptionData['week_item_data']);
    var itemDetails=this.SubscriptionData['week_item_data'];
    var itemdatas = {};
    // console.log('itemDetails',itemDetails);
    itemDetails.forEach(function (value){
      // itemdatas = {};
      value['item_detail'].map(v=>{
        // console.log(',',v);
        // console.log('itemdatas',itemdatas);
        // console.log(itemdatas[value.week_name]);
        if(typeof itemdatas[value.week_name]== 'undefined'){
          itemdatas[value.week_name]=[];
          itemdatas[value.week_name]['week_name'] = value.week_name;
        }
        if(typeof itemdatas[value.week_name]['item_type']== 'undefined'){
          itemdatas[value.week_name]['item_type']={};
          // itemdatas[value.week_name]['week_name'] = value.week_name;
        }
        if(typeof itemdatas[value.week_name]['item_type'][v.itemtype]== 'undefined'){
          itemdatas[value.week_name]['item_type'][v.itemtype]={};
          itemdatas[value.week_name]['item_type'][v.itemtype]['item_type_name'] = v.display_item_type;
          itemdatas[value.week_name]['item_type'][v.itemtype]['item_data']=[];
        }
        itemdatas[value.week_name]['item_type'][v.itemtype]['item_data'].push(v);
      });
      // console.log('here');
      itemdatas[value.week_name]['item_type'] = Object.values(itemdatas[value.week_name]['item_type']);
      
    });
    // console.log('enter',itemdatas);
    this.itemdatas=Object.values(itemdatas);
    const map = {
      'Monday': 2,'Tuesday': 3,'Wednesday': 4,'Thursday': 5,'Friday': 6,'Saturday': 7,
      'Sunday': 1
   };
   this.itemdatas.sort((a, b) => {
      return map[a.week_name] - map[b.week_name];
   });
    // console.log('this.itemdatas',this.itemdatas);
    // $('#weeklyItemDetail').modal('show');
    // $('#ItemAddToCart').modal('hide');
    this.weeklyItemDetails = itemDetails;
    this.weekname = this.weeklyItemDetails["week_name"];
    this.Weekly_item_detail = this.weeklyItemDetails["item_detail"];
  }
  
  getLocationDetail(locationId) {
    var obj = {
      "location_id": locationId
    };

    this.spinner.show();
    this.service.getLocationDetail(obj).subscribe(response => {
      if (response.status == "ok") {
        this.locationData = response.payload;
        //console.log.log('location',this.locationData);
        this.isOrderAvailable = this.locationData['is_ordering'];
        this.orderTime = "between " + this.locationData['restaurant_opening_time'] + " to " + this.locationData['restaurant_closing_time'];
      } else {
        this.toastr.error(response.message);
      }
      this.spinner.hide();
    }, (err: HttpErrorResponse) => {
      this.spinner.hide();
    });
  }

  addToCart() {
    this.cookieValue = this.cookieService.get("user");
    if (this.cookieValue == undefined || this.cookieValue == "") {
      $("#LoginModal").modal("show");
      $('#ItemAddToCart').modal('hide');
      return
    }
    var startDate = $("#startDate").val();
    if(startDate == undefined || startDate == ''){
      this.toastr.error("Select subscription start date");
      return;
    }

    if (confirm("Are you sure you want to buy this subcription ?")) {
      $('#ItemAddToCart').modal('hide');

      var objItems = {
        "cart_total": this.SubscriptionData.price,
        "cart_items": 1,
        "sub_total": this.SubscriptionData.price,
        "discount": 0,
        "promocode": "",
        "promocode_id": "",
        "items": this.SubscriptionData,
        "startDate": startDate
      }
      var objCart = {
        "note": $("#addNote").val(),
        "location_id": this.locationId,
        "order_serve_date": "",
        "payment_method": "",
        "cart": objItems
      }
      var datePipe = new DatePipe("en-US");
      var startdateformate=datePipe.transform(startDate, 'dd/MM/yyyy');
      localStorage.setItem("startDate",startdateformate);
      localStorage.setItem(this.locationId + "_subscriptionDetail", JSON.stringify(objCart));
      this.router.navigate(['/placeordersubscription']);

    }
  }
  getItemDetail(data)
  {
    $('#ItemDescription').modal('show');
    this.itemname=data.itemname;
    this.itemdescription=data.item_description?data.item_description:'No Description Found';
    // console.log(data);
  }
  ItemDescriptionClose()
  {
    $('#ItemDescription').modal('hide');
  }
}

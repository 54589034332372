<div class="osahan-home-page">
    <div class="bg-light">
        <div class="alert alert-info text-center" role="alert" *ngIf="isOrderAvailable == 0">
            <h4>We are accepting orders {{orderTime}}.</h4> 
        </div>
        <div class="osahan-manage-addresses">
            <div class="px-3 pt-3 pb-5">
                <h2 class="font-weight-bold m-0 pt-1 cart-resize with-title">Manage Addresses</h2>
            </div>
            <div class="cart-resize">
                <div class="p-3 osahan-cart-item">
                    <div class="osahan-cart-item-profile bg-white rounded shadow p-3 mt-n5" id="divListAddress" style="display: none;">
                        <div class="d-flex flex-column pt-2">
                            <div class="custom-control custom-radio mb-2 px-0" *ngFor="let addresses of addressList">
                                <input type="radio" id="customRadio2055" name="customRadio" class="custom-control-input">
                                <label class="row ml-0 mr-0 border osahan-check p-3 w-100 rounded align-items-center" for="customRadio2055">
                                    <div class="col-lg-10 col-md-8 col-sm-8 col-xs-12 pl-0 pr-0"><b><i class="feather-home mr-2"></i> {{addresses.address_type_label}}</b> <br>
                                        <p class="small mb-0 pl-4">{{addresses.deliveryarea}}, {{addresses.address}}, {{addresses.city}},
                                            {{addresses.pincode}}</p>
                                    </div>
                                    <div class="col-lg-2 col-md-4 col-sm-4 col-xs-12 pl-0 pr-0" style="text-align: right;">
                                            <a href="javascript:void(0);" class="btn btn-primary mr-1"
                                                (click)="editUserAddress(addresses.useraddress_id)">Edit</a>
                                            <a href="javascript:void(0);" class="btn btn-primary"
                                                (click)="openDeleteAddress(addresses.useraddress_id)">Delete</a>
                                    </div>
                                </label>
                            </div>
                            <a class="btn btn-primary btn-lg" href="javascript:void(0);" style="border-radius: 30px;" (click)="showAddAddress()"> ADD NEW ADDRESS </a>
                        </div>
                    </div>
                    <div class="osahan-cart-item-profile bg-white rounded shadow p-3 mt-n5" id="divAddAddress" style="display: none;">
                        <div class="d-flex flex-column pt-2">
                            <div style="text-align: right;" id="divBackToList">
                                <a href="javascript:void(0);" class="text-primary font-weight-bold" (click)="resetAddressForm()"><i
                                        class="feather-chevron-left"></i>
                                    Back To List</a>
                            </div>
                            <form #ManageAddressForm="ngForm" (ngSubmit)="saveAddress(data)">
                                <div class="modal-body">
                                    <div class="form-row">
                                        <div class="col-md-12 form-group">
                                            <label class="form-label">Delivery Area <span
                                                    class="text-danger">*</span></label>
                                            <div class="input-group">
                                                <input type="text" class="form-control" #deliveryarea="ngModel" ngModel name="deliveryarea" id="deliveryarea"
                                                    placeholder="Delivery Area" autocomplete="off" [(ngModel)]="data.deliveryarea" required>
                                            </div>
                                            <span *ngIf="ManageAddressForm.submitted && deliveryarea.invalid">
                                                <p *ngIf="deliveryarea.errors.required" class="error_message"
                                                    style="color: red;">
                                                    Delivery area is required
                                                </p>
                                            </span>
                                        </div>
                                        <div class="col-md-12 form-group">
                                            <label class="form-label">Complete Address <span
                                                    class="text-danger">*</span></label>
                                            <div class="input-group">
                                                <input name="address" placeholder="Complete Address e.g. house number, street name, landmark" type="text"
                                                 (change)="locateAddress(0,0)"   class="form-control" #address="ngModel" ngModel id="address" autocomplete="off" [(ngModel)]="data.address" required>
                                            </div>
                                            <span *ngIf="ManageAddressForm.submitted && address.invalid">
                                                <p *ngIf="address.errors.required" class="error_message"
                                                    style="color: red;">
                                                    Address is required
                                                </p>
                                            </span>
                                        </div>
                                        <div class="col-md-6 form-group">
                                            <label class="form-label">City - State  <span
                                                    class="text-danger">*</span></label>
                                            <div class="input-group">
                                                <input name="city" placeholder="Enter City Name" type="text" class="form-control" #city="ngModel" ngModel
                                                    id="city" autocomplete="off" [(ngModel)]="data.city" (change)="locateAddress(0,0)" required>
                                            </div>
                                            <span *ngIf="ManageAddressForm.submitted && city.invalid">
                                                <p *ngIf="city.errors.required" class="error_message"
                                                    style="color: red;">
                                                    City is required
                                                </p>
                                            </span>
                                        </div>
                                        <div class="col-md-6 form-group">
                                            <label class="form-label">Pin Code <span
                                                    class="text-danger">*</span></label>
                                            <div class="input-group">
                                                <input name="pincode" placeholder="Enter Pin Code" type="text" class="form-control" #pincode="ngModel" ngModel
                                                    id="pincode" autocomplete="off" [(ngModel)]="data.pincode" (change)="locateAddress(0,0)" required numbersOnly>
                                            </div>
                                            <span *ngIf="ManageAddressForm.submitted && pincode.invalid">
                                                <p *ngIf="pincode.errors.required" class="error_message"
                                                    style="color: red;">
                                                    Pincode is required
                                                </p>
                                            </span>
                                        </div>
                                        <div class="mb-0 col-md-12 form-group">
                                            <label class="form-label">Address Type</label>
                                            <div class="btn-group btn-group-toggle w-100" data-toggle="buttons">
                                                <label class="btn btn-outline-secondary" style="border-radius: 30px; margin-left: 10px;" id="lblOption0">
                                                    <input type="radio" name="address_type" id="option0" value="0"> 
                                                    <i class="feather-map-pin mr-2"></i>Home
                                                </label>
                                                <label class="btn btn-outline-secondary" style="border-radius: 30px; margin-left: 10px;" id="lblOption1">
                                                    <input type="radio" name="address_type" id="option1" value="1">
                                                    <i class="feather-map-pin mr-2"></i>Work
                                                </label>
                                                <label class="btn btn-outline-secondary" style="border-radius: 30px; margin-left: 10px;" id="lblOption2">
                                                    <input type="radio" name="address_type" id="option2" value="2">
                                                    <i class="feather-map-pin mr-2"></i>Other
                                                </label>
                                            </div>
                                        </div>

                                        <input type="hidden" class="searched_longi" id="searched_longi" name="searched_longi">
                                        <input type="hidden" class="searched_lati" id="searched_lati" name="searched_lati">

                                        <div class="col-md-12 form-group" style="margin-top:  20px;">
                                            <div #map id="map" style="width:100%;height:400px" >
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="modal-footer pt-3 border-0">
                                    <div class="col-12 m-0 p-0" *ngIf="isUpdate == 0">
                                        <button type="submit" class="btn btn-primary btn-lg btn-block" style="border-radius: 30px;">Save
                                            Changes</button>
                                    </div>
                                    <div class="col-12 m-0 p-0" *ngIf="isUpdate == 1">
                                        <button type="submit" class="btn btn-primary btn-lg btn-block" style="border-radius: 30px;">Update
                                            Changes</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div class="bg-white rounded shadow mt-3 profile-details row ml-0 mr-0 p-3 mb-5">
                        <a href="javascript:void(0);"
                            class="d-flex w-100 align-items-center border-bottom p-3 col-lg-4 col-xs-12 col-md-6"
                            routerLink="/orderhistory">
                            <div class="left mr-3">
                                <h6 class="font-weight-bold m-0 text-dark"><i
                                        class="feather-truck bg-danger text-white p-2 rounded-circle mr-2"></i> Manage
                                    Orders </h6>
                            </div>
                            <div class="right ml-auto">
                                <h6 class="font-weight-bold m-0"><i class="feather-chevron-right"></i></h6>
                            </div>
                        </a>
                        <a href="javascript:void(0);"
                            class="d-flex w-100 align-items-center border-bottom p-3 col-lg-4 col-xs-12 col-md-6"
                            routerLink="/profile">
                            <div class="left mr-3">
                                <h6 class="font-weight-bold m-0 text-dark"><i
                                        class="feather-map-pin bg-danger text-white p-2 rounded-circle mr-2"></i> Manage
                                    Profile </h6>
                            </div>
                            <div class="right ml-auto">
                                <h6 class="font-weight-bold m-0"><i class="feather-chevron-right"></i></h6>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade ss-login" id="DeleteModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true" data-backdrop="static" data-keyboard="false"
    style="height: 200px; top: 100px; overflow-y: hidden;">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Confirmation</h5>
            </div>
            <div class="modal-body">
                <h6 class="pt-2">Are you sure you want to delete ?</h6>
                <div class="modal-footer p-0 border-0">
                    <div class="col-6 m-0 p-1">
                        <button type="button" class="btn btn-primary btn-block" style="border-radius: 20px;"
                            (click)="deleteAddress()">Yes</button>
                    </div>
                    <div class="col-6 m-0 p-0">
                        <button type="button" class="btn btn-primary btn-block" style="border-radius: 20px;"
                            (click)="resetAddressForm()">No</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ngx-spinner bdcolor="rgb(255 255 255 / 80%)" size="medium" type="square-jelly-box" template="&nbsp;">
    <div class="loader-box">
        <div class="icon">
            <i class="fas fa-utensils"></i>
        </div>
    </div>
    <!-- <p style="color: white;">Please Wait.</p> -->
</ngx-spinner>
<!-- <div id="loader" class="center" style="visibility: visible; display: none;"></div>  -->
<div class="osahan-home-page">
    <div class="bg-light" id="divPlaceOrder">
        <div class="alert alert-info text-center" role="alert" *ngIf="isOrderAvailable == 0">
            <h4>We are accepting orders {{orderTime}}.</h4>
        </div>
        <div class="osahan-placeorder height-fill">
            <div class="px-3 pt-3 pb-2">
                <h2 class="font-weight-bold m-0 pt-1 cart-resize with-title">Confirm Order</h2>
            </div>
            <div class="bg-light mb-4 pt-2 osahan-cart-item cart-resize">
                <div class="row ml-0 mr-0">
                    <div class="col-lg-8 col-md-8 col-xs-12">
                        <div class="d-flex flex-column">
                            <div class="row ml-0 mr-0">
                                <!-- LOGIN BLOCK START -->
                                <div class="osahan-cart-item-profile bg-white rounded shadow p-3 BG-WHITE col-12 mb-3">
                                    <h6 class="mb-0 font-weight-bold">
                                        <div class="icon-round"><i class="feather-check-circle mr-2"></i>Logged in</div>
                                    </h6>
                                    <div class="mt-2 pl-4 large">{{data.firstname}} {{data.lastname}} | {{data.phoneno}}
                                    </div>
                                </div>
                                <!-- LOGIN BLOCK END -->

                                <!-- ADDRESS BLOCK START -->
                                <!-- <div class="osahan-cart-item-profile bg-white rounded shadow p-3 BG-WHITE col-12 mb-3 ordertype"
                                    id="ordertypeH">
                                    <div class="osahan-cart-item-profile bg-white rounded shadow p-3 BG-WHITE col-12 mb-3 ordertype"
                                        id="ordertypeH" *ngIf="orderType == 'H'">
                                        <h6 class="mb-0 font-weight-bold">
                                            <div class="icon-round"><i class="feather-check-circle mr-2"></i>Delivery
                                                Address</div>
                                        </h6>
                                        <div class="row d-flex p-3 address-boxes" *ngIf="ConfirmAddress.length == 0">
                                            <div class="card col-6 pl-0 pr-0 mb-3 align-items-center">
                                                <a class="text-center w-100 d-flex align-items-center h-100"
                                                    href="javascript:void(0);" (click)="addAddress()"
                                                    style="color: #0056b3;">
                                                    <div class="card-body">
                                                        <h5 class="card-title mb-0">ADD NEW ADDRESS</h5>
                                                        <p class="card-text small">&nbsp;</p>
                                                    </div>
                                                </a>
                                            </div>
                                            <div class="card col-6 pl-0 pr-0 mb-3" *ngFor="let a of addressList">
                                                <div class="card-body">
                                                    <h5 class="card-title">{{a.address_type_label}}</h5>
                                                    <h6 class="card-subtitle mb-2 text-muted">{{a.address}}</h6>
                                                    <p class="card-text small">{{a.deliveryarea}}, {{a.city}},
                                                        {{a.pincode}}</p>
                                                    <a href="javascript:void(0);" (click)="setaddress(a)"
                                                        class="card-link btn btn-primary w-100">Deliver Here</a>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="card col-12 pl-0 pr-0 mb-3 mt-3" *ngIf="ConfirmAddress.length > 0">
                                            <div class="card-body" *ngFor="let c of ConfirmAddress">
                                                <h5 class="card-title">
                                                    <i class="feather-home mr-2"></i>{{c.address_type_label}}
                                                </h5>
                                                <h6 class="card-subtitle mb-2 text-muted">
                                                    {{c.address}}
                                                </h6>
                                                <p class="card-text small">
                                                    {{c.deliveryarea}}, {{c.city}},
                                                    {{c.pincode}}
                                                </p>
                                                <a class="card-link btn btn-primary text-white"
                                                    (click)="RemoveAddress();">
                                                    Change
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="osahan-cart-item-profile bg-white rounded shadow p-3 BG-WHITE col-12 mb-3 ordertype"
                                        id="ordertypeP" *ngIf="orderType == 'P'">
                                        <h6 class="mb-0 font-weight-bold">
                                            <div class="icon-round">
                                                <i class="feather-check-circle mr-2"></i>Pickup Address
                                            </div>
                                        </h6>
                                        <div class="row d-flex p-3 address-boxes">
                                            <div class="card col-12 pl-0 pr-0">
                                                <div class="card-body">
                                                    <h5 class="card-title">Pickup Point</h5>
                                                    <h6 class="card-subtitle mb-2 text-muted">{{locationData.address1}}
                                                    </h6>
                                                    <p class="card-text">{{locationData.address2}},<br>
                                                        {{locationData.city}}, {{locationData.state}}
                                                        {{locationData.zipcode}}</p>
                                                    <strong>Call on: <a href="tel:{{locationData.phoneno}}"
                                                            class="text-black">{{locationData.phoneno}}</a></strong>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> -->
                                <!-- ADDRESS BLOCK END -->

                                <!-- PAYMENT BLOCK START -->
                                <div class="osahan-cart-item-profile bg-white rounded shadow p-3 BG-WHITE col-12 mb-5 opacity-1"
                                    *ngIf="isOrderAvailable == 1">
                                    <h6 class="font-weight-bold mb-0">
                                        <div class=""><i class="feather-check-circle mr-2"></i>Payment</div>
                                    </h6>
                                    <div class="row ml-0 mr-0">

                                        <div class="col-lg-6 col-md-6 col-xs-12 mb-3"
                                            *ngIf="paymentgateway_value.length > 0">
                                            <span>
                                                <p class="card-text mb-2">Pay bill online securely through UPI,
                                                    Credit-Debit cards or
                                                    Net-Banking.</p>
                                                <button class="btn btn-success btn-block btn-sm w-100 p-2"
                                                    style="border-radius: 20px;" (click)="placeOrder('ONLINE');"
                                                    id="btnPlaceOrderOnline">Pay {{currencySymbol}}
                                                    <!-- onlinePay(undefined); ">Pay {{currencySymbol}} -->
                                                    {{grandTotalAmount}}<i
                                                        class="icofont-long-arrow-right"></i></button>
                                            </span>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-xs-12 mb-3">
                                            <span>
                                                <p class="card-text mb-2">Online payment recommended for better hand
                                                    hygiene
                                                </p>
                                                <button class="btn btn-primary btn-block btn-sm w-100 p-2"
                                                    (click)="placeOrder('CASH')" id="btnPlaceOrder">
                                                    Cash On Delivery {{currencySymbol}} {{grandTotalAmount}}<i
                                                        class="icofont-long-arrow-right"></i>
                                                </button>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <!-- PAYMENT BLOCK END -->
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-xs-12">
                        <div class="bg-white rounded shadow mb-3 py-2">
                            <h6 class="mb-1 font-weight-bold pl-3 pt-2">Order Summary</h6>
                            <div id="cartdetails col-5">
                                <div
                                    class="gold-members d-flex align-items-center justify-content-between px-3 py-2">
                                    <div class="media align-items-center">
                                        <div class="media-body">
                                            <p class="m-0">Extra quantities total</p>
                                        </div>
                                    </div>
                                    <div class="d-flex align-items-center">
                                        <p class="text-gray mb-0 float-right ml-2 text-muted small">
                                            {{currencySymbol}}
                                            {{orderSubTotal}}</p>
                                    </div>
                                </div>
                                <!-- <div class="bg-white rounded p-3 pb-4">Sub Total : <p
                                        class="text-gray mb-0 float-right ml-2 large bold">{{currencySymbol}}
                                        {{orderSubTotal}}</p>
                                </div> -->
                            </div>
                        </div>
                        <div class="shadow bg-white rounded p-3 clearfix mb-5" #divSubTotal>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="bg-light" id="divOrderSuccess">
        <div class="osahan-vieworder height-fill">
            <div class="p-3">
                <!-- <div class="alert alert-info" role="alert">
                    <button type="button" class="close" data-dismiss="alert"><span aria-hidden="true">×</span><span
                            class="sr-only">Close</span></button>
                    Your order is saved.
                </div> -->
                <!-- <h2 class="font-weight-bold m-0 cart-resize with-title">#{{orderNo}}</h2> -->
            </div>
            <div class="bg-light mb-4 p-3 osahan-cart-item cart-resize">
                <div class="row ml-0 mr-0">
                    <div class="col-lg-8 col-md-8 col-xs-12">
                        <div class="row ml-0 mr-0">
                            <div class="osahan-cart-item-profile bg-white rounded shadow BG-WHITE col-12 mb-3">
                                <div class="flex-column">
                                    <div class="row p-3 d-flex">
                                        <div class="col-12 img-icon-size pl-0 pr-0">
                                            <div
                                                class="order-status bg-white z-depth-1 pt-4 pb-4 mt-3 mb-3 text-center bdrradius col-12 pl-0 pr-0">
                                                <div class="order-progress">
                                                    <div class="steps-of-order-progress active">
                                                        <div
                                                            class="pt-3 step-container row align-items-top ml-1 mr-1 border-bottom">
                                                            <div class="col-2 p-0 mb-2">
                                                                <img src="https://raivatkitchen.com/assets/img/ordermessageicon/Received.png"
                                                                    class="order-status-icon" id="orderstatusicon">
                                                                <div class="badge status-badge mt-2 pl-3 pr-3 pb-1 pt-1"
                                                                    id="orderstatustag">Done</div>
                                                            </div>
                                                            <div class="col-10 text-left">
                                                                <h6 class="status-msg" id="orderstatusmessage">Extra quantities added successfully in your
                                                                    subscription !</h6>
                                                                <!-- <p class="small mb-1" id="orderstatusdescription">
                                                                    Waiting for Food Ready.</p> -->
                                                                <span id="orderstatusbtn"><a
                                                                        href="tel:{{locationData.phoneno}}"
                                                                        class="mb-2 small btn btn-primary btn-sm d-none w-auto">
                                                                        Call Kitchen</a></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-xs-12">
                        <div class="osahan-cart-item-profile bg-white rounded shadow p-3 BG-WHITE col-12">
                            <div class="flex-column">
                                <div #divOrderSummary>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row ml-0 mr-0 mt-2">
                    <div class="col-lg-8 col-md-8 col-xs-12"></div>
                    <div class="col-lg-4 col-md-4 col-xs-12">
                        <div class="flex-column">
                            <button type="submit" class="btn btn-primary btn-lg btn-block" routerLink="/home"
                                (click)="backToHome()">Back To Home</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ngx-spinner bdcolor="rgb(255 255 255 / 80%)" size="medium" type="square-jelly-box" template="&nbsp;">
    <div class="loader-box">
        <div class="icon">
            <i class="fas fa-utensils"></i>
        </div>
    </div>
    <!-- <p style="color: white;">Please Wait.</p> -->
</ngx-spinner>
import { Component, OnInit, resolveForwardRef, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { NgForm, Validators } from '@angular/forms';
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService, NgxSpinnerComponent } from 'ngx-spinner';
import { Router, ActivatedRoute } from "@angular/router";
import { CookieService } from 'ngx-cookie-service';
import { HomeService } from '../home/home.service';
import { CheckoutService } from '../checkout/checkout.service';
declare var $: any;

@Component({
  selector: 'app-read-feedback',
  templateUrl: './read-feedback.component.html',
  styleUrls: ['./read-feedback.component.css']
})
export class ReadFeedbackComponent implements OnInit {

  @ViewChild('divReadFeedback', {static: true}) divReadFeedback: ElementRef;

  constructor(private toastr: ToastrService, private router: Router, private cookieService: CookieService, private spinner: NgxSpinnerService, private renderer: Renderer2,
    private service: HomeService, private checkoutService: CheckoutService) { }

  feedbackList: any;

  locationData: any;
  isOrderAvailable: any;
  orderTime: any;

  ngOnInit() {
    this.getFeedbackList();

    if (this.cookieService.get("location")) {
      this.getLocationDetail(this.cookieService.get("location"));
    }
  }

  getFeedbackList() {
    var obj = {
      "offset": "0",
      "limit": "0",
      "location_id": this.cookieService.get("location")
    };

    this.spinner.show();
    this.service.getAllReview(obj).subscribe(response => {
      if (response.status == "ok") {
        this.feedbackList = response.payload;
        this.setFeedbackUI();
      } else {
        this.toastr.error(response.message);
      }
      this.spinner.hide();
    }, (err: HttpErrorResponse) => {
      this.spinner.hide();
    });
  }

  setFeedbackUI() {
    this.spinner.show();
    for (var n = 0; n < this.feedbackList.length; n++) {
      const divMain: HTMLParagraphElement = this.renderer.createElement('div');
      divMain.setAttribute("class", "feedback-item shadow bg-white p-3 mb-2 col-lg-4 col-md-6 col-xs-12 align-items-center");
      this.renderer.appendChild(this.divReadFeedback.nativeElement, divMain);

      // // For Rating Detail
      const divRate: HTMLParagraphElement = this.renderer.createElement('div');
      divRate.setAttribute("class", "review-text");

      const divVoting: HTMLParagraphElement = this.renderer.createElement('div');
      divVoting.setAttribute("class", "voting-count");
      this.renderer.appendChild(divRate, divVoting);

      const divStar: HTMLParagraphElement = this.renderer.createElement('div');
      divStar.setAttribute("class", "rating ratings-highlight single-person-rating");
      this.renderer.appendChild(divVoting, divStar);

      // for (var p = 0; p < 5; p++) {
      //   const label: HTMLParagraphElement = this.renderer.createElement('label');
      //   label.setAttribute("class", "fillstar");
      //   label.innerHTML = "☆";
      //   this.renderer.appendChild(divStar, label);
      // }
      const divStarDynamic: HTMLParagraphElement = this.renderer.createElement('div');
      divStarDynamic.setAttribute("id", "review" + n);
      this.renderer.appendChild(divStar, divStarDynamic);

      const labelText: HTMLParagraphElement = this.renderer.createElement('label');
      labelText.innerHTML = (this.feedbackList[n].description != "" ? this.feedbackList[n].description : this.feedbackList[n].title);
      this.renderer.appendChild(divRate, labelText);

      this.renderer.appendChild(divMain, divRate);

      // // For User Detail
      const divUser: HTMLParagraphElement = this.renderer.createElement('div');
      divUser.setAttribute("class", "row pl-0 pr-0");
      this.renderer.appendChild(divMain, divUser);

      const divDate: HTMLParagraphElement = this.renderer.createElement('div');
      divDate.setAttribute("class", "reviwer-name pt-3 col-6");
      divDate.setAttribute("style", "font-size: 10px;");

      divDate.innerHTML = "<i class='feather-clock'></i> " + this.feedbackList[n].added_on;
      this.renderer.appendChild(divUser, divDate);

      const divName: HTMLParagraphElement = this.renderer.createElement('div');
      divName.setAttribute("class", "reviwer-name small text-right pt-3 col-6");
      this.renderer.appendChild(divUser, divName);

      const strongName: HTMLParagraphElement = this.renderer.createElement('strong');
      strongName.innerHTML = "- " + this.feedbackList[n].name ;
      this.renderer.appendChild(divName, strongName);

      $("#review" + n).rating({
        "stars": 5,
        "half":true,
        "value": this.feedbackList[n].rating,
        "readonly": true,
        "click": function (e) {
        }
      });
    }
    this.spinner.hide();
  }

  getLocationDetail(locationId) {
    var obj = {
      "location_id": locationId
    };

    this.spinner.show();
    this.checkoutService.getLocationDetail(obj).subscribe(response => {
      if (response.status == "ok") {
        this.locationData = response.payload;
        this.isOrderAvailable = this.locationData['is_ordering'];
        this.orderTime = "between " + this.locationData['restaurant_opening_time'] + " to " + this.locationData['restaurant_closing_time'];
      } else {
        this.toastr.error(response.message);
      }
      this.spinner.hide();
    }, (err: HttpErrorResponse) => {
      this.spinner.hide();
    });
  }
}

<div class="osahan-home-page">
    <div class="bg-light">
        <div class="alert alert-info text-center" role="alert" *ngIf="isOrderAvailable == 0">
            <h4>We are accepting orders {{orderTime}}.</h4> 
        </div>
        <div class="px-3 pt-3 pb-5">
            <h2 class="font-weight-bold m-0 pt-1 cart-resize with-title">Welcome to Opus Infiniti</h2>
        </div>
        <div class="bg-light mb-4 p-3 osahan-faq-item cart-resize">
            <div class="osahan-privacy bg-white rounded shadow p-4 mt-n5">
                <div id="intro" class="mb-4">
                    <div class="mb-3">
                        <h2 class="h5"> opus infiniti</h2>
                    </div>
                    <p>
                        opus infiniti is an old name of one of the oldest mountain of India, Girnar. Located in the foothills
                        of Girnar,  opus infiniti is a Cloud Kitchen (delivery based restaurant) that serves the
                        traditional but rare to find dishes in perfect quality of platter boxes.
                    </p>
                    <p>Lack of variety in the market for traditional Indian cuisine inspired us with the idea of Raivat.
                        Especially Marathi and Rajasthani food dishes are unheard of in the city. Each recipe listed in
                        the menu of  opus infiniti is unique yet familiar in its way. </p>
                </div>
                <div id="services" class="mb-4">
                    <div class="mb-3">
                        <h3 class="h5">Our Food</h3>
                    </div>
                    <p>Made from the traditional recipe of the various cultures, we very well know the essence of the
                        originality of dishes. Our specialities are Gujarati, Marathi, Rajasthani and Punjabi cuisines.
                    </p>
                    <p> With our perfect quantity of combo platter boxes, we are suitable for large as well as
                        individual orders. Days of confusion are gone, folks. Just count heads and get perfect quantity
                        platters delivered from  opus infiniti.</p>
                    <hr>
                    <div class="mb-4">
                        <h3 class="h5">Before getting started give our FAQ a read.</h3>
                    </div>
                    <h6>1. What is the meaning of Raivat?</h6>
                    <p>- Raivat is one of the old names of Girnar.</p>
                    <h6>2. What is Cloud Kitchen? </h6>
                    <p>- Cloud Kitchen is a delivery-only restaurant without any physical dining space. One can order
                        food online form the official site www.raivatkitchen.com</p>
                    <h6>3. What is a platter box? </h6>
                    <p>- Platter box is a perfectly calculated meal for one with food combinations which complement each
                        other.</p>
                    <h6>4. How do I order food? </h6>
                    <p>- One can order food online on www.raivatkitchen.com </p>
                    <h6>5. What about bulk orders? </h6>
                    <p>- We accept bulk orders before 4 hours. And the best part is that each one gets their platter
                        boxes. So no need to serve them differently.</p>
                    <h6>6. What are the cuisines? </h6>
                    <p>- We serve food combo in platter boxes. The cuisines are Gujarati, Punjabi, Rajasthani, Marathi
                        and other Raivat’s Specials.</p>
                    <h6>7. Is the dine-in facility available? </h6>
                    <p>- We are only accepting delivery orders for now. No dine-in for now.</p>
                </div>
            </div>
        </div>
    </div>
</div>
<ngx-spinner bdcolor="rgb(255 255 255 / 80%)" size="medium" type="square-jelly-box" template="&nbsp;">
    <div class="loader-box">
        <div class="icon">
            <i class="fas fa-utensils"></i>
        </div>
    </div>
    <!-- <p style="color: white;">Please Wait.</p> -->
</ngx-spinner>
<div class="osahan-home-page">
    <div class="bg-light">
        <div class="alert alert-info text-center" role="alert" *ngIf="isOrderAvailable == 0">
            <h4>We are accepting orders {{orderTime}}.</h4>
        </div>
        <div class="cat-slider">
            <div class="cat-item px-1 py-3 wave" *ngFor="let menus of homePagetTopCategories">
                <a class="bg-white rounded d-block p-2 text-center shadow  overflow-hidden" href="javascript:void(0);"
                    (click)="getMenuItem(menus.category_id, menus.name)">
                    <img src="{{menus.image}}" class="img-fluid mb-2"
                        onerror="this.src='assets/img/logo/logo1.jpg';">
                    <!-- <div class="fixed-bg"
                            [ngStyle]="{ 'background-image': 'url(' + menus.image + ')'  }">
                        </div> -->
                    <p class="m-0 small"><b>{{menus.name}}&nbsp;</b></p>
                </a>
            </div>
        </div>

        <div class="offer-slider bg-white border-bottom shadow-sm">
            <div class="cat-item px-1 py-2" *ngFor="let o of offerList">
                <span *ngIf="o.offer_url == ''">
                    <a class="bg-white d-block text-center shadow" href="javascript:void(0);">
                        <!-- <img src="{{o.image_url['main_image']}}" style="height:374px;width: 604px;"
                            class="img-fluid rounded" atl="{{o.title}}"> -->
                        <div class="fixed-bg offer-fixed-bg"
                            [ngStyle]="{ 'background-image': 'url(\'' + o.image_url['main_image'] + '\')'  }">
                        </div>
                    </a>
                </span>
                <span *ngIf="o.offer_url != ''">
                    <a class="bg-white d-block text-center shadow" href="{{o.offer_url}}" target="blank">
                        <!-- <img src="{{o.image_url['main_image']}}" style="height:374px;width: 604px;"
                            class="img-fluid rounded" atl="{{o.title}}"> -->
                        <div class="fixed-bg offer-fixed-bg"
                            [ngStyle]="{ 'background-image': 'url(\'' + o.image_url['main_image'] + '\')'  }">
                        </div>
                    </a>
                </span>
            </div>
        </div>

        <div class="container-for-lg pt-3 pb-5">
            <div class="p-2 title d-flex align-items-center sticky-temp-head">
                <h5 class="m-0 pt-2 pb-2">New Arrivals</h5>
            </div>

            <div class="trending-slider">
                <div class="osahan-slider-item py-2 px-2 " *ngFor="let item of newArrivalItemList;let i =index">
                    <div class="list-card bg-white  rounded overflow-hidden position-relative shadow-sm">
                        <div class="list-card-image">
                            <a href="javascript:void(0);" class="star position-absolute" *ngIf="item.avg_rating != ''"
                                (click)="getReviews(item)">
                                <span class="badge badge-success"><i class="feather-star"></i>
                                    {{item.avg_rating}} ( {{item.total_review}} Reviews )
                                </span>
                            </a>
                            <div class="member-plan position-absolute">
                                <span class="badge badge-dark">{{item.categoryname}}</span>
                            </div>
                            <div class="favourite-heart text-danger position-absolute"><img
                                    src="{{item.types[0].image}}" style="height: 15px; width: 15px;" class="veg-dot-ss"
                                    style="background-color: white;">
                            </div>
                            <a href="javascript:void(0)" (click)="getItemDetails(item,'NewArrivals',i,0)">
                                <!-- <img src="{{item.image_url['100x100']}}"
                                    style="max-height: 260px;width:100%!important;object-fit: fill"
                                    class="img-fluid item-img w-100"> -->
                                <div class="fixed-bg"
                                    [ngStyle]="{ 'background-image': 'url(\'' + item.image_url['100x100'] + '\')' }">
                                </div>
                            </a>
                        </div>
                        <div class="p-3 position-relative">
                            <div class="list-card-body">
                                <div class="dish-contents d-flex">
                                    <div class="col-8 col-lg-8 col-md-8 col-xs-8 col-sm-8 pl-0 pr-0 pb-2">
                                        <h6 class="mb-1 mt-1">
                                            <div class="text-black item-nm"
                                                style="text-overflow: ellipsis; overflow: hidden; white-space: nowrap;"
                                                (click)="getItemDetails(item,'NewArrivals',i,0)">
                                                {{item.name}}</div>
                                        </h6>
                                    </div>
                                    <div class="col-4 col-lg-4 col-md-4 col-xs-4 col-sm-4 pl-0 pr-0 pb-2">
                                        <p class="text-gray mb-0 time">
                                            <span
                                                class="price-container-ss bg-light text-dark rounded-sm pl-2 pb-1 pt-1 pr-2 price-ss small"
                                                *ngIf="isPriceWithTax">
                                                {{currencySymbol}} {{item.display_inc_tax_price}}/-
                                            </span>
                                            <span
                                                class="price-container-ss bg-light text-dark rounded-sm pl-2 pb-1 pt-1 pr-2 price-ss small"
                                                *ngIf="!isPriceWithTax">
                                                {{currencySymbol}} {{item.price}}/-
                                            </span>
                                        </p>
                                    </div>
                                    <div class="col-8 col-lg-8 col-md-8 col-xs-8 col-sm-8 pl-0 pr-0">
                                        <p class="small mb-3" [innerHTML]="item.description">
                                        </p>
                                    </div>
                                    <div class="col-6 form-group mb-0 pl-0">
                                        <div class="custom-control custom-checkbox" *ngIf="item.is_jain == 1">
                                            <input type="checkbox" id="jainItemNewArrivals_{{item.item_id}}"
                                                name="jainItemNewArrivals_{{item.item_id}}" class="custom-control-input"
                                                [checked]="item.SelectIsJain"
                                                (change)="OnSelectJain(i,'NewArrivals',0)">
                                            <label for="jainItemNewArrivals_{{item.item_id}}"
                                                class="custom-control-label pt-1"><strong>Jain</strong>
                                            </label>
                                        </div>
                                    </div>
                                    <div class="col-6 pl-0 pr-0 float-right">
                                        <button class="btn btn-primary btn-block add-btn btn-small btn-sm w-50"
                                            (click)="getItemDetails(item,'NewArrivals',i,0)"
                                            [disabled]="isOrderAvailable == 0">Add</button>
                                    </div>
                                    <span class="small float-right pt-1 pr-0 col-12 text-right">
                                        <span *ngIf="item.isDisplayCustomizable == 1" class="txtCustomizable">
                                            Customizable </span>&nbsp;
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div>
                <ng-container  *ngIf="subscriptionshow == '1'">
                <div class="p-2 title d-flex align-items-center sticky-temp-head">
                    <h5 class="m-0 pt-2 pb-2">Subscription List</h5>
                    <a class="pt-2 pb-2 font-weight-bold ml-auto" href="javascript:void(0);"
                    routerLink="/managesubscriptions">View all
                        <i class="feather-chevrons-right"></i></a>
                </div>
               
                <div class="trending-slider">

                    <div class="osahan-slider-item py-2 px-2 container" *ngFor="let items of SubscriptionList;let i = index">
                        <!-- <div class=" list-card rounded overflow-hidden position-relative "> -->
                            <!-- <div class="" > -->
                                <div class="pricing-card" [ngClass]="{'basic': i%3 == 0, 'eco' : i%3 == 1, 'pro': i%3 == 2}" >
                                    <div class="pricing-header">
                                        <span class="plan-title"> {{items.name}} </span>
                                        <div class="price-circle">
                                            <span class="price-title">
                                                <small>  {{currencySymbol}} </small><span>{{items.price}}</span>
                                            </span>
                                            <!-- <span class="info">/ Month</span> -->
                                        </div>
                                    </div>
                                    <div class="badge-box">
                                        <!-- <span>{{items.description}}</span> -->
                                    </div>
                                    <ul>
                                        <li>
                                            <strong>Duration: </strong> 
                                            {{items.duration}} {{items.text_duration_type}}
                                        </li>
                                        <li>
                                            <strong>Grace period:</strong> 
                                            {{items.grace_period}} {{items.text_grace_period_type}}
                                        </li>
                                        <li>
                                            <strong>Tiffin type: </strong> 
                                             {{items.tiffine_type}}
                                        </li>
                                    </ul>
                                    <div class="buy-button-box">
                                        <a href="javascript:void(0)" class="buy-now" (click)="getItemDetailsforsubscription(items,i)" >BUY NOW</a>
                                        &nbsp;&nbsp;&nbsp;<a
                                        href="javascript:void(0)"
                                        class="buy-now"
                                        (click)="getViewDetailsforweeks(items, i)"
                                        >VIEW MENU</a
                                      >
                                    </div>
                                </div>
                            <!-- </div> -->
                        
                    <!-- </div> -->
                </div>
            </div>
        </ng-container>
            <div class="p-2 title d-flex align-items-center sticky-temp-head">
                <h5 class="m-0 pt-2 pb-2">Trending This Week</h5>
            </div>
            <div class="trending-slider" id="trending-slider">
                <div class="osahan-slider-item py-2 px-2 " *ngFor="let item of trendingItemList;let i= index">
                    <div class="list-card bg-white  rounded overflow-hidden position-relative shadow-sm">
                        <div class="list-card-image">
                            <a href="javascript:void(0);" class="star position-absolute" *ngIf="item.avg_rating != ''"
                                (click)="getReviews(item)">
                                <span class="badge badge-success"><i class="feather-star"></i>
                                    {{item.avg_rating}} ( {{item.total_review}} Reviews )
                                </span>
                            </a>
                            <div class="member-plan position-absolute">
                                <span class="badge badge-dark">{{item.categoryname}}</span>
                            </div>
                            <div class="favourite-heart text-danger position-absolute"><img
                                    src="{{ item.types[0].image }}" style="height: 15px; width: 15px;"
                                    class="veg-dot-ss" style="background-color: white;">
                            </div>
                            <a href="javascript:void(0)" (click)="getItemDetails(item,'Trending',i,0)">
                                <!-- <img src="{{item.image_url['100x100']}}" style="height: 260px;object-fit: fill"
                                    class="img-fluid item-img w-100"> -->
                                <div class="fixed-bg"
                                    [ngStyle]="{ 'background-image': 'url(\'' + item.image_url['100x100'] + '\')' }">
                                </div>
                            </a>
                        </div>
                        <div class="p-3 position-relative">
                            <div class="list-card-body">
                                <div class="dish-contents d-flex">
                                    <div class="col-8 col-lg-8 col-md-8 col-xs-8 col-sm-8 pl-0 pr-0 pb-2">
                                        <h6 class="mb-1 mt-1">
                                            <div class="text-black item-nm"
                                                style="text-overflow: ellipsis; overflow: hidden; white-space: nowrap;"
                                                (click)="getItemDetails(item,'Trending',i,0)">
                                                {{item.name}}</div>
                                        </h6>
                                    </div>
                                    <div class="col-4 col-lg-4 col-md-4 col-xs-4 col-sm-4 pl-0 pr-0 pb-2">
                                        <p class="text-gray mb-0 time">
                                            <span
                                                class="price-container-ss bg-light text-dark rounded-sm pl-2 pb-1 pt-1 pr-2 price-ss small"
                                                *ngIf="isPriceWithTax">
                                                {{currencySymbol}} {{item.display_inc_tax_price}}/-</span>
                                            <span
                                                class="price-container-ss bg-light text-dark rounded-sm pl-2 pb-1 pt-1 pr-2 price-ss small"
                                                *ngIf="!isPriceWithTax">
                                                {{currencySymbol}} {{item.price}}/-
                                            </span>
                                        </p>
                                    </div>
                                    <div class="col-12 pl-0 pr-0">
                                        <p class="small mb-3" [innerHTML]="item.description">
                                        </p>
                                    </div>
                                    <div class="col-6 form-group mb-0 pl-0">
                                        <div class="custom-control custom-checkbox" *ngIf="item.is_jain == 1">
                                            <input type="checkbox" id="jainItemTrending_{{item.item_id}}"
                                                name="jainItemTrending_{{item.item_id}}" class="custom-control-input"
                                                [checked]="item.SelectIsJain" (change)="OnSelectJain(i,'Trending',0)">
                                            <label for="jainItemTrending_{{item.item_id}}"
                                                class="custom-control-label pt-1"><strong>Jain</strong>
                                            </label>
                                        </div>
                                    </div>
                                    <div class="col-6 pl-0 pr-0 float-right">
                                        <button class="btn btn-primary btn-block add-btn btn-small btn-sm w-50"
                                            (click)="getItemDetails(item,'Trending',i,0)"
                                            [disabled]="isOrderAvailable == 0">Add</button>
                                    </div>
                                    <span class="small float-right pt-1 pr-0 col-12 text-right">
                                        <span *ngIf="item.isDisplayCustomizable == 1" class="txtCustomizable">
                                            Customizable </span>&nbsp;
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngFor="let menus of menuCategoryList;let j = index">
                
                <div class="p-2 title d-flex align-items-center sticky-temp-head">
                    <h5 class="m-0 pt-2 pb-2">{{menus.name}}</h5>
                    <a class="pt-2 pb-2 font-weight-bold ml-auto" href="javascript:void(0);"
                        (click)="getMenuItem(menus.category_id, menus.name)">View all
                        <i class="feather-chevrons-right"></i></a>
                </div>
                <div class="trending-slider">
                    <div class="osahan-slider-item py-2 px-2" *ngFor="let item of menus.items;let i= index">
                        <div class="list-card bg-white  rounded overflow-hidden position-relative shadow-sm">
                            <div class="list-card-image">
                                <a href="javascript:void(0);" class="star position-absolute" (click)="getReviews(item)"
                                    *ngIf="item.avg_rating != ''">
                                    <span class="badge badge-success"><i class="feather-star">
                                        </i> {{item.avg_rating}} ( {{item.total_review}} Reviews )</span>
                                </a>
                                <div class="member-plan position-absolute">
                                    <span class="badge badge-dark">{{menus.name}}</span>
                                </div>
                                <div class="favourite-heart text-danger position-absolute">
                                    <img src="{{ item.types[0].image }}" style="height: 15px; width: 15px;"
                                        class="veg-dot-ss" style="background-color: white;">
                                </div>
                                <a href="javascript:void(0)" (click)="getItemDetails(item,'CategoryWise',i,j)">
                                    <!-- <img src="{{item.image_url['100x100']}}" class="img-fluid item-img w-100"
                                        style="height: 260px;width:100%!important;object-fit: fill"> -->
                                    <div class="fixed-bg" id="{{item.image_url['100x100']}}"
                                        [ngStyle]="{ 'background-image': 'url(\''+ item.image_url['100x100'] +'\')' }">
                                    </div>
                                </a>
                            </div>
                            <div class="p-2 position-relative">
                                <div class="list-card-body">
                                    <div class="dish-contents d-flex">
                                        <div class="col-8 col-lg-8 col-md-8 col-xs-8 col-sm-8 pl-0 pr-0 pb-2">
                                            <h6 class="mb-1 mt-1">
                                                <div class="text-black item-nm"
                                                    style="text-overflow: ellipsis; overflow: hidden; white-space: nowrap;"
                                                    (click)="getItemDetails(item,'CategoryWise',i,j)">
                                                    {{item.name}} </div>
                                            </h6>
                                        </div>
                                        <div class="col-4 col-lg-4 col-md-4 col-xs-4 col-sm-4 pl-0 pr-0 pb-2">
                                            <p class="text-gray mb-0 time">
                                                <span
                                                    class="price-container-ss bg-light text-dark rounded-sm pl-2 pb-1 pt-1 pr-2 price-ss small"
                                                    *ngIf="isPriceWithTax">
                                                    {{currencySymbol}} {{item.display_inc_tax_price}}/-
                                                </span>
                                                <span
                                                    class="price-container-ss bg-light text-dark rounded-sm pl-2 pb-1 pt-1 pr-2 price-ss small"
                                                    *ngIf="!isPriceWithTax">
                                                    {{currencySymbol}} {{item.price}}/-
                                                </span>
                                            </p>
                                        </div>
                                        <div class="col-12 form-group mb-0 pl-0">
                                            <p class="small mb-3" [innerHTML]="item.description">
                                            </p>
                                        </div>
                                        <div class="col-6 form-group mb-0 pl-0">
                                            <div class="custom-control custom-checkbox" *ngIf="item.is_jain == 1">
                                                <input type="checkbox" id="jainItem_{{item.item_id}}"
                                                    name="jainItem_{{item.item_id}}" class="custom-control-input"
                                                    [checked]="item.SelectIsJain"
                                                    (change)="OnSelectJain(i,'CategoryWise',j)">
                                                <label for="jainItem_{{item.item_id}}"
                                                    class="custom-control-label pt-1"><strong>Jain</strong>
                                                </label>
                                            </div>
                                        </div>
                                        <div class="col-6 pl-0 pr-0 float-right">
                                            <button class="btn btn-primary btn-sm w-50 btn-block btn-small float-right"
                                                [disabled]="isOrderAvailable == 0"
                                                (click)="getItemDetails(item,'CategoryWise',i,j)">Add</button>
                                        </div>
                                        <span class="small float-right pt-1 pr-0 col-12 text-right">
                                            <span *ngIf="item.isDisplayCustomizable == 1" class="txtCustomizable">
                                                Customizable </span>&nbsp;
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
           

        </div>
    </div>
</div>

<div class="modal fade ss-login popup-mini-ss rating-popup" id="ItemUnitModal" tabindex="-1" role="dialog"
    aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false"
    style="height: 400px; top: 100px; overflow-y: hidden;">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel"><b>Item Unit: </b>{{lblItemName}}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="pt-2">
                    <div class="form-row">
                        <div class="col-md-6 form-group" *ngFor="let units of itemUnits; let i = index">
                            <div class="input-group">
                                <button type="button" class="btn btn-primary btn-lg btn-block"
                                    (click)="getModifiers(i,units.modifiers)">{{units.unit_name}}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade ss-login" id="ItemAddToCart" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel"><b>{{lblItemName}}</b>
                </h5>
                <button type="button" class="close" (click)="resetAddToCart()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <form #ModifierForm="ngForm" (ngSubmit)="addToCart()">
                    <div id="adons-options-block">
                        <div class="mx-auto w-100">
                            <div class="bg-primary rounded pt-3 pb-3 shadow-sm mt-3 ml-0 mr-0 w-100">
                                <!-- <img src="{{itemDetailImage}}" alt="{{itemDetailImage}}"
                                    onerror="this.src='assets/img/logo/logo-jpg.jpg';" style="max-height: 475px;"
                                    class="img-fluid w-100 p-3"> -->
                                <div class="fixed-bg"
                                    [ngStyle]="{ 'background-image': 'url(\'' + itemDetailImage + '\')' }">
                                </div>
                            </div>
                            <div class="bg-primary rounded col-12 pt-3 pb-3 shadow-sm mt-3 row ml-0 mr-0">
                                <div class="col-6 pl-0 pr-0"><span
                                        class="badge badge-success">{{lblCategoryName}}</span></div>
                                <div class="col-6 text-right pl-0 pr-0">
                                    <div class="price-tag card-item-price">
                                        {{currencySymbol}}
                                        <span id="hp_final_cost" *ngIf="isPriceWithTax">{{lblItemRateWithTax}}</span>
                                        <span id="hp_final_cost" *ngIf="!isPriceWithTax">{{lblItemRate}}</span>/-
                                    </div>
                                </div>
                                <div class="col-12 pt-1 pl-0 pr-0">
                                    <p [innerHTML]="itemDetailDescription"></p>
                                </div>
                                <div class="col-12 pt-1 pb-1 pl-0 pr-0" *ngFor="let types of itemTypes">
                                    <span>
                                        <img src="{{ types.image }}" style="height: 20px; width: 20px;"
                                            class="veg-dot-ss"><b>{{ types.name}}</b>
                                    </span>
                                </div>
                            </div>
                            <div class="bg-primary rounded col-12 pt-3 pb-3 shadow-sm mt-3 row ml-0 mr-0"
                                *ngIf="itemUnits != undefined && itemUnits.length > 1">
                                <div class="mb-0 col-md-12 form-group mb-3">
                                    <div class="btn-group btn-group-toggle w-100" data-toggle="buttons">
                                        <a *ngFor="let units of itemUnits; let i = index" id="units{{i}}"
                                            class="btn btn-outline-secondary btns-select "
                                            [ngClass]="{ 'active': i == 0 }"
                                            style="border-radius: 30px; margin-left: 10px;" href="javascript:void(0);"
                                            (click)="getModifiers(i,units.modifiers,'CategoryWise',temp_i,temp_j)">
                                            <input type="radio" name="itemunitid{{i}}" data-val="0.00"
                                                class="form-check-input-addon" id="itemunitid{{i}}" value="{{i}}">
                                            <span *ngIf="isPriceWithTax"> {{units.unit_name}} ({{currencySymbol}}
                                                {{units.display_inc_tax_price}})</span>
                                            <span *ngIf="!isPriceWithTax">{{units.unit_name}} ({{currencySymbol}}
                                                {{units.price}})</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div class="bg-primary rounded col-12 pt-3 pb-3 shadow-sm mt-3 row ml-0 mr-0">
                                <div class="col-md-12">
                                    <div class="form-row">
                                        <div class="col-md-8 form-group">
                                            <div class="input-group" style="height: 100%; line-height: 30px;">
                                                <span *ngIf="isPriceWithTax"><b>Item: </b>{{lblItemName}}
                                                    ({{currencySymbol}}
                                                    {{lblItemRateWithTax}})</span>
                                                <span *ngIf="!isPriceWithTax"><b>Item: </b>{{lblItemName}}
                                                    ({{currencySymbol}}
                                                    {{lblItemRate}})</span>
                                            </div>
                                        </div>
                                        <div class="col-md-4 form-group">
                                            <span class="count-number float-right">
                                                <div class="input-group" style="text-align: right; display: block;">
                                                    <button type="button" class="btn-sm left dec btn btnCart"
                                                        id="decrease" value="Decrease Value"
                                                        (click)="countItemChange(0)">
                                                        <i class="feather-minus btnIcon"></i>
                                                    </button>
                                                    <input disabled min="1" max="50" value="1"
                                                        class="count-number-input" id="itemQuantity" type="text">
                                                    <button type="button" class="btn-sm right inc btn btnCart"
                                                        id="increase" value="Increase Value"
                                                        (click)="countItemChange(1)">
                                                        <i class="feather-plus btnIcon"></i>
                                                    </button>
                                                </div>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div #divFormRow>
                                    </div>
                                </div>
                            </div>
                            <div #divJainAddToCart class="pt-3"></div>
                        </div>
                    </div>
                    <div class="form-row pt-3">
                        <div class="col-md-12 form-group">
                            <div class="input-group">
                                <div class="input-group-append">
                                    <span id="button-name" type="button" class="btn btn-outline-secondary"><i
                                            class="feather-edit"></i></span>
                                </div>
                                <textarea #addNote="ngModel" ngModel name="addNote" class="form-control" rows="3"
                                    placeholder="Add note here..."></textarea>
                            </div>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="col-md-12 form-group">
                            <div class="input-group">
                                <button type="submit" class="btn btn-primary btn-block">Add
                                    {{currencySymbol}}
                                    {{lblTotalAmount}}</button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

<div class="modal fade login-component ss-addon-modal" id="addons-options-modal" role="dialog" tabindex="-1"
    aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog ">
        <div class="modal-content">
            <div class="modal-body">
                <div class="login-block">
                    <button type="button" class="close modal-close" data-dismiss="modal">&times;</button>
                    <div id="adons-options-block">
                        <form id="addtocartform" method="post" autocomplete="false">
                            <div class=""><input type="hidden" id="selected_item_id" value="11340063"><input
                                    type="hidden" id="selected_item_price" value="190"><input type="hidden"
                                    id="selected_menu_id" value="784056"><input type="hidden" id="itemFrom"
                                    value="items"></div>
                            <div class="mx-auto w-100">
                                <div class="bg-primary rounded pt-3 pb-3 shadow-sm mt-3 ml-0 mr-0 w-100">
                                    <!-- <img src="{{itemDetailImage}}" onerror="this.src='assets/img/logo/logo-jpg.jpg';"
                                        class="img-fluid w-100 p-3"> -->
                                    <div class="fixed-bg"
                                        [ngStyle]="{ 'background-image': 'url(\'' + itemDetailImage + '\')' }">
                                    </div>
                                </div>
                                <div class="bg-primary rounded col-12 pt-3 pb-3 shadow-sm mt-3 row ml-0 mr-0">
                                    <div class="col-6 pl-0 pr-0"><span
                                            class="badge badge-success">{{lblCategoryName}}</span></div>
                                    <div class="col-6 text-right pl-0 pr-0">
                                        <div class="price-tag card-item-price">{{currencySymbol}} <span
                                                id="hp_final_cost">{{itemDetailPrice}}</span>/-
                                        </div>
                                    </div>
                                    <h6 class="mb-1 font-weight-bold pt-2">{{itemDetailName}}</h6><input type="hidden"
                                        id="totalcost11340063" value="190">
                                    <div class="col-12 pt-1 pb-1 pl-0 pr-0">
                                        <p [innerHTML]="itemDetailDescription"></p>
                                    </div>
                                </div>
                                <div class="bg-white rounded shadow mb-3 py-2 col-12 mt-2 pl-0 pr-0">
                                    <h6 class="modal-title p-3" id="exampleModalLabel">Choice Of Bread</h6>
                                    <div class="mb-0 col-md-12 form-group mb-3">
                                        <div class="btn-group btn-group-toggle w-100" data-toggle="buttons">
                                            <label class="btn btn-outline-secondary btns-select active"
                                                style="border-radius: 30px; margin-left: 10px;">
                                                <input type="radio" name="itemaddonid192362" data-val="0.00"
                                                    class="form-check-input-addon" id="itemaddonid1191514682523385"
                                                    value="2523385" checked=""> Fulka Roti
                                            </label>
                                            <label class="btn btn-outline-secondary btns-select "
                                                style="border-radius: 30px; margin-left: 10px;">
                                                <input type="radio" name="itemaddonid192362" data-val="0.00"
                                                    class="form-check-input-addon" id="itemaddonid1191514682523387"
                                                    value="2523387"> Tandoori Butter Roti
                                            </label>
                                            <label class="btn btn-outline-secondary btns-select "
                                                style="border-radius: 30px; margin-left: 10px;">
                                                <input type="radio" name="itemaddonid192362" data-val="0.00"
                                                    class="form-check-input-addon" id="itemaddonid1191514682523389"
                                                    value="2523389"> Butter Paratha
                                            </label>
                                            <label class="btn btn-outline-secondary btns-select "
                                                style="border-radius: 30px; margin-left: 10px;">
                                                <input type="radio" name="itemaddonid192362" data-val="0.00"
                                                    class="form-check-input-addon" id="itemaddonid1191514682917786"
                                                    value="2917786"> Tawa Paratha
                                            </label>
                                        </div>
                                    </div>
                                    <h6 class="modal-title p-3" id="exampleModalLabel">Choice Of Drinks</h6>
                                    <div class="mb-0 col-md-12 form-group mb-3">
                                        <div class="btn-group btn-group-toggle w-100" data-toggle="buttons">
                                            <label class="btn btn-outline-secondary btns-select active"
                                                style="border-radius: 30px; margin-left: 10px;">
                                                <input type="radio" name="itemaddonid192363" data-val="0.00"
                                                    class="form-check-input-addon" id="itemaddonid1191514682523391"
                                                    value="2523391" checked=""> Butter Milk
                                            </label>
                                            <label class="btn btn-outline-secondary btns-select "
                                                style="border-radius: 30px; margin-left: 10px;">
                                                <input type="radio" name="itemaddonid192363" data-val="0.00"
                                                    class="form-check-input-addon" id="itemaddonid1191514682523393"
                                                    value="2523393"> Sprite
                                            </label>
                                            <label class="btn btn-outline-secondary btns-select "
                                                style="border-radius: 30px; margin-left: 10px;">
                                                <input type="radio" name="itemaddonid192363" data-val="0.00"
                                                    class="form-check-input-addon" id="itemaddonid1191514682523395"
                                                    value="2523395"> Thumbs Up
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div #divJainDetail>
                                </div>
                            </div>
                            <div class="p-0 border-0 py-3">
                                <div class="col-12 m-0 p-0"><button type="button" class="btn btn-primary add-btn w-100"
                                        (click)="getItemDetails(itemInformations,temp_Type,temp_i,temp_j)">Add to
                                        Cart</button></div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Showcase Popup code -->
<div class="modal fade ss-addon-modal" id="HomeBannerModal" tabindex="-1" role="dialog"
    aria-labelledby="exampleModalLabel" aria-hidden="true">
    <!-- data-backdrop="static" data-keyboard="false" -->
    <div class="modal-dialog">
        <div class="modal-content" style="background: transparent;">
            <div class="modal-header" style="border-bottom: none;">
                <button type="button" class="close text-white" data-dismiss="modal" aria-label="Close"
                    style="opacity: 1;">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div>
                    <div class="popup-single">
                        <a href="javascript:void(0);" data-dismiss="modal" aria-label="Close" style="opacity: 1;"
                            (click)="getHomeBannerMenuItem(homeBannerCategoryId, homeBannerCategoryName, homeBannerItemId)">
                            <img src="{{homeBannerImage}}" alt="{{homeBannerImage}}" class="img-fluid mx-auto" />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Showcase Popup code -->
<div
  class="modal fade ss-login"
  id="ItemAddToCart1"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
  data-backdrop="static"
  data-keyboard="false"
  style="overflow-y: auto"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">
          <b>{{ lblItemName }}</b>
        </h5>
        <button type="button" class="close" (click)="resetAddToCart()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <!-- <form #ModifierForm="ngForm" name="ModifierForm" (ngSubmit)="addToCart()"> -->
        <div id="adons-options-block">
          <div class="mx-auto w-100">
            <!-- <div class="bg-primary rounded pt-3 pb-3 shadow-sm mt-3 ml-0 mr-0 w-100">
                                <img src="{{itemDetailImage}}" alt="{{itemDetailImage}}"
                                    onerror="this.src='assets/img/logo/logo-jpg.jpg';" style="max-height: 475px;"
                                    class="img-fluid w-100 p-3">
                            </div> -->
            <div
              class="bg-primary rounded col-12 pt-3 pb-3 shadow-sm mt-3 row ml-0 mr-0"
            >
              <div class="col-6 pl-0 pr-0">
                <span class="badge badge-success">Subscription</span>
              </div>
              <div class="col-6 text-right pl-0 pr-0">
                <div class="price-tag card-item-price">
                  {{ currencySymbol }}
                  <span id="hp_final_cost">{{ lblItemRate }}</span
                  >/-
                </div>
              </div>
              <div class="col-12 pt-1 pl-0 pr-0">
                <p [innerHTML]="itemDetailDescription"></p>
              </div>
            </div>
            <div
              class="bg-primary rounded col-12 pt-3 pb-3 shadow-sm mt-3 row ml-0 mr-0"
            >
              <div class="col-md-12">
                <div class="form-row">
                  <div class="col-md-8 form-group">
                    <div class="input-group" style="line-height: 30px">
                      <span
                        ><b>Subscription: </b>{{ lblItemName }} ({{
                          currencySymbol
                        }}
                        {{ lblItemRate }})</span
                      >
                    </div>
                    <div class="input-group" style="line-height: 30px">
                      <span
                        ><b>Duration: </b> {{ itemDetails["duration"] }}
                        {{ itemDetails["text_duration_type"] }}
                      </span>
                    </div>
                    <div class="input-group" style="line-height: 30px">
                      <span
                        ><b>Grace period: </b>
                        {{ itemDetails["grace_period"] }}
                        {{ itemDetails["text_grace_period_type"] }}
                      </span>
                    </div>
                    <div class="input-group" style="line-height: 30px">
                      <span
                        ><b>Tiffin type: </b> {{ itemDetails["tiffine_type"] }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="bg-primary rounded col-12 pt-3 pb-3 shadow-sm mt-3 row ml-0 mr-0"
            >
              <div class="col-md-12">
                <div class="form-row">
                  <div class="col-md-12 form-group">
                    <!-- itemDetails.week_item_data -->
                    <div
                      class="input-group week-items"
                      style="line-height: 30px; width: 100%"
                      *ngFor="
                        let week of itemDetails['week_item_data'];
                        let i = index
                      "
                    >
                      <span style="float: left; width: 20%" class="week-inline"
                        ><b>{{ week.week_name }}: </b>
                        <div></div
                      ></span>
                      <div class="week-parent">
                        <div
                          *ngFor="let week_item_details of week['item_detail']"
                        >
                          <div class="category-items">
                            <div class="category-flex">
                              <div>
                                <b
                                  >{{ week_item_details.display_item_type }}
                                </b>
                              </div>
                              <div>
                                <b>{{ week_item_details.itemname }} </b>
                              </div>
                              <div>
                                <b>Qty:{{ week_item_details.qty }} </b>
                              </div>
                            </div>
                            <div>
                              <span>
                                <pre>{{
                                  week_item_details.item_description
                                }}</pre>
                              </span>
                              <div></div>
                            </div>
                          </div>

                          <!-- <span style="text-align: right; width: 50%;">
                                                    <a href="javascript:void(0)"
                                                        (click)="viewWeeklyItemDetail(week)">View Details</a>
                                                </span> -->
                          <!-- <pre> 
                                                                                <code>
                                                                                {{ week | json }}
                                                                                </code>
                                                                            </pre> -->
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- <div #divJainAddToCart class="pt-3"></div> -->
              </div>
            </div>
            <!-- <div class="form-row pt-3">
                        <div class="col-md-12 form-group">
                            <div class="input-group">
                                <div class="input-group-append">
                                    <span id="button-name" type="button" class="btn btn-outline-secondary"><i
                                            class="feather-edit"></i></span>
                                </div>
                                <textarea #addNote="ngModel" ngModel name="addNote" id="addNote" class="form-control"
                                    rows="3" placeholder="Add note here..."></textarea>
                            </div>
                        </div>
                    </div>
                    <div class="form-row pt-3">
                        <div class="col-md-12 form-group">
                            <label> Subscription start date</label><br>

                            <div class="input-group">
                                <div class="input-group-append">
                                    <span id="button-name" type="button" class="btn btn-outline-secondary"><i
                                            class="feather-calendar"></i></span>
                                </div>
                                <input type="date" name="startDate" class="form-control" placeholder="Enter Date"
                                    onkeydown="return false" id="startDate" autocomplete="off" required>
                            </div>
                        </div>
                    </div>-->
            <div class="form-row pt-3">
              <div class="col-md-12 form-group">
                <div class="input-group">
                  <button
                    class="btn btn-danger btn-block"
                    (click)="resetAddToCart()"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
            <!-- </form> -->
          </div>
        </div>
      </div>
    </div>
</div>
</div>
<ngx-spinner bdcolor="rgb(255 255 255 / 80%)"  size="medium" type="square-jelly-box" template="&nbsp;">
    <div class="loader-box">
        <div class="icon">
            <i class="fas fa-utensils"></i>
        </div>
    </div>
    <!-- <p style="color: white;">Please Wait.</p> -->
</ngx-spinner>
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  constructor(private http: HttpClient) { }

  getProfile(data) {
    return this.http.post<any>(`${environment.apiBaseUrl}/api/app/front/api/user/getdetail?${environment.queryStringData}`, data);
  }

  updateProfile(data) {
    return this.http.post<any>(`${environment.apiBaseUrl}/api/app/front/api/user/update?${environment.queryStringData}`, data);
  }
}

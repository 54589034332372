import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { NgxSpinnerService, NgxSpinnerComponent } from 'ngx-spinner';

@Component({
  selector: 'app-menu-sidebar',
  templateUrl: './menu-sidebar.component.html',
  styleUrls: ['./menu-sidebar.component.css']
})
export class MenuSidebarComponent implements OnInit {

  cookieValue: any;
  constructor(private cookieService: CookieService, private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.cookieValue = this.cookieService.get("user");
    this.spinner.show();
    if (this.cookieValue) {
      document.getElementById("login").style.display = "none";

      document.getElementById("offers").style.display = "block";
      document.getElementById("manageOrders").style.display = "block";
      // document.getElementById("manageAddresses").style.display = "block";
      document.getElementById("logout").style.display = "block";
      // document.getElementById("changePassword").style.display = "block";
      document.getElementById("usersubscriptions").style.display = "block";
      document.getElementById("usersubscriptionorders").style.display = "block";
    } else {
      document.getElementById("login").style.display = "block";

      document.getElementById("offers").style.display = "none";
      document.getElementById("manageOrders").style.display = "none";
      // document.getElementById("manageAddresses").style.display = "none";
      document.getElementById("logout").style.display = "none";
      // document.getElementById("changePassword").style.display = "none";
      document.getElementById("usersubscriptions").style.display = "none";
      document.getElementById("usersubscriptionorders").style.display = "none";
    }
    if(this.cookieService.get("subscription") != "1"){
      document.getElementById("usersubscriptions").style.display = "none";
      document.getElementById("usersubscriptionorders").style.display = "none";
      document.getElementById("managesubscriptions").style.display = "none";
    }
    this.spinner.hide();
  }
}

<div class="osahan-home-page"></div>
<div class="bg-light">
    <div class="alert alert-info text-center" role="alert" *ngIf="isOrderAvailable == 0">
        <h4>We are accepting orders {{orderTime}}.</h4> 
    </div>
    <div class="osahan-contact height-fill">
        <div class="px-3 pt-3 pb-5">
            <h2 class="font-weight-bold m-0 pt-1 cart-resize with-title">Customer Feedbacks</h2>
        </div>
        <div class="bg-light mb-4 p-3 osahan-cart-item cart-resize">
            <div class="osahan-cart-item-profile bg-white rounded shadow p-3 mt-n5 BG-WHITE">
                <div class="flex-column">
                    <h6 class="font-weight-bold">Your valuable feedback will help us improve our services. </h6>
                    <hr>
                    <p class="text-muted"></p>
                    <div class="feedback-list row gutters ml-0 mr-0 pt-3" #divReadFeedback>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ngx-spinner bdcolor="rgb(255 255 255 / 80%)" size="medium" type="square-jelly-box" template="&nbsp;">
    <div class="loader-box">
        <div class="icon">
            <i class="fas fa-utensils"></i>
        </div>
    </div>
    <!-- <p style="color: white;">Please Wait.</p> -->
</ngx-spinner>
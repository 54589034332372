// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // apiBaseUrl: 'http://192.168.11.33/pos/tushal',
  // apiBaseUrl: 'http://192.168.11.33/pos/aashutosh',
  // apiBaseUrl: 'http://api.myciti.in:5034/pos-qa',
  apiBaseUrl: 'https://pos.netehost.com',
  queryStringData: 'app_type=web&app_version=5&device_name=' + window.navigator.appCodeName + '&system_version=' + window.navigator.platform,
  currencySymbol: '₹',
  pgateway_types: [],
  roundoff: null,
};

// appType: '',
//   appVersion: '',
//   deviceName: 'Mozilla',
//   systemVersion: 'Linux+x86_64',

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
